import React      from 'react';
import PropTypes  from 'prop-types';

import * as S              from './bm-advert.module.scss';
import { setCtxNestedObj } from '../../../../context/ctx-actions';
import { useGlobalCtx }    from '../../../../context/ctx-hook';
import { MODALS }          from '../../../../helpers/constants/state-constants';
import classNames from 'classnames';

const PRODUCT = 'billmanager';

const BmAdvert = ({listPosition}) => {
  const {globalDispatch} = useGlobalCtx();
  const hangLengthClass = ( listPosition > 0 ?  (listPosition > 1 ? S.extraHanging : S.longHanging) : S.shortHanging);

  const handleChangePlan = React.useCallback(
    () => globalDispatch(setCtxNestedObj('modal', {
      type: MODALS.CHANGE_PLAN,
      ctx:  {product: PRODUCT},
      isLoading: true,
      hasError: false,
      isDisabled: false,
    })),
    [globalDispatch]
  );

  return (
    <div className={S.bmTile}>
      <span className={classNames(`${S.plusSymbol} ${hangLengthClass}`)}>+</span>
      <h3 className={S.productName}>Quicken Bill Manager</h3>

      <ul className={S.featureList}>
        <li>Track and pay your bills right inside Quicken</li>
        <li>Make same-day electronic payments to over 11,500 online billers using Quick Pay</li>
        <li>Pay anyone in the U.S. via checks using Check Pay</li>
      </ul>

      <span className={S.dash}/>

      <button
        type="button"
        onClick={handleChangePlan}
        className={S.contentCTABtn}
        data-testid="BTN_BM_PURCHASE"
      >Purchase Bill Pay</button>
    </div>
  );
};

BmAdvert.displayName  = 'BmAdvert';
BmAdvert.propTypes    = {isModal: PropTypes.bool, listPosition: PropTypes.number};
BmAdvert.defaultProps = {isModal: false, listPosition: 0};

export { BmAdvert };
