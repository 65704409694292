import PropTypes      from 'prop-types';
import React          from 'react';
import { useHistory } from 'react-router';
import classNames     from 'classnames';


import { ModalBtnRow }          from '../modal-btn-row';
import { ModalWrapper }         from '../modal-wrapper';
import { TextField }            from '../../common/inputs/text-field';
import { SelectField }          from '../../common/inputs/select-field';
import { Message }              from '../../common/message';
import * as Actions             from '../../../context/ctx-actions';
import { useGlobalCtx }         from '../../../context/ctx-hook';
import { ROUTES }               from '../../../helpers/constants/constants';
import * as MixPanel            from '../../../helpers/mixpanel';
import { checkForAnyErrors }    from '../../../helpers/pure-functions';
import { validateNewPassword }  from '../../../helpers/validations';
import * as Options             from '../../../helpers/constants/options-constants';
import * as Validations         from '../../../helpers/validations';


import { callUpdatePasswordV3 } from '../../../network/quicken-id-calls';


import * as S from './password-modal.module.scss';

export const QKN_PASS_VALIDATIONS = { // word current prevents usage of sharded pass validation
  currentPassword: text => text === '' ? 'Please enter your current password' : '',
  newPassword:     validateNewPassword
};

export const FORM_MFA_VALIDATIONS = {
  mfa: Validations.validateMFA,
  mfaCode: Validations.validateMFACode,
  password: Validations.validateUserPassword
};

const errorChecker = (code, errs, errObj) => {
  switch (code.toUpperCase()) {
    case 'QCS-0400-8':
      if (errs?.extData?.parameterName === 'mfaCode') {
        return { ...errObj, mfaCode: 'Please enter a valid 6 digit code' };
      }
      break;
    case 'QCS-0400':
      if (errs?.title) {
        return { ...errObj, form: errs.title };
      }
      break;
    default:
      return { ...errObj, form: 'GENERIC_ERROR' };
  }
};


const PasswordModal = ({onClose}) => {
  const { globalDispatch, state: { 
    isDisabled,
    security: { 
      phone,
      mfa,
      mfaCode,
      mfaSentChannel, 
      mfaSentTo
      // quickenId
    }}} = useGlobalCtx();

  const {push}                                = useHistory();
  const [showField, setShowField]             = React.useState(false);
  let resendInFlight = false;

  const [form, setForm] = React.useState({
    values: {newPassword: '', currentPassword: '', mfa, mfaCode},
    errors: {newPassword: '', currentPassword: '', mfa: '', mfaCode: '', form: ''}
  });

  const handleChange = React.useCallback(({ type, target: { name, value } }) => {
    const IS_BLUR = type === 'blur';
      
    if (IS_BLUR) value = value.trim();
    setForm(({ values, errors }) => ({
      values: { ...values, [name]: value },
      errors: { ...errors, [name]: QKN_PASS_VALIDATIONS[name](value, values.currentPassword) }
    }));
  }, []);
  
  const handleMfaCodeChange = React.useCallback(({type, target: {name, value}}) => {
    const IS_BLUR = type === 'blur';
    if (IS_BLUR) value = value.trim();
    
    // Had to add this as paste wasn't clearing form errors
    const submitBtn = document.querySelector('[data-testid="PASSWORD_FORM_SUBMIT"]');
    submitBtn.disabled = false;

    setForm(({values, errors}) => ({
      values: {...values, [name]: value},
      errors: {...errors, [name]: FORM_MFA_VALIDATIONS.mfaCode(value)}
    }));
  }, []);

  const handleSetField = React.useCallback(
    () => setShowField(!showField),
    [showField]
  );

  const handleSelection = React.useCallback(({name, value}) =>
    setForm(({values, errors}) => ({
      values: {...values, [name]: value},
      errors: {...errors, [name]: FORM_MFA_VALIDATIONS.mfa(value)}
    })),
  []
  );

  const clearFormError = React.useCallback(() => {
    setForm(({values, errors}) => ({
      values: {...values},
      errors: {...errors, form: ''}
    }));
  }, []);

  const handleResendCode = React.useCallback(async () => {
    if (resendInFlight) { return; }
    const formData         = document.querySelector('form');
    const currentPassword = formData.elements.currentPassword.value.trim();
    const newPassword     = formData.elements.newPassword.value.trim();
    const mfaChannel      = formData.elements.mfa.value.trim();
    const mfaCode         = '';

    const ERROR_OBJ = {
      currentPassword: QKN_PASS_VALIDATIONS.currentPassword(currentPassword),
      newPassword:     QKN_PASS_VALIDATIONS.newPassword(newPassword),
      mfaChannel:      FORM_MFA_VALIDATIONS.mfa(mfaChannel),
      mfaCode:         FORM_MFA_VALIDATIONS.mfaCode(mfaCode),
      form:            ''
    };

    try {
      globalDispatch(Actions.setCtxField('isDisabled', true));
      resendInFlight = true;
      await callUpdatePasswordV3(currentPassword, newPassword, mfaChannel, mfaCode);
      resendInFlight = false;
      globalDispatch(Actions.setCtxField('isDisabled', false));
    } catch (err) {
      MixPanel.error(err, MixPanel.MIX_PANEL_IDS.PASSWORD);
      setForm(({ values }) => ({
        values: { ...values },
        errors: { ...ERROR_OBJ, form: 'GENERIC_ERROR' }
      }));
      resendInFlight = false;
      globalDispatch(Actions.setCtxField('isDisabled', false));
    }
  });

  const OPTIONS = React.useMemo(() => phone ? Options.MFA_OPTIONS : Options.MFA_DISABLED, [phone]);

  const setMfaFields = (action) => {
    const mfaCodeLabel = document.querySelector('[data-testid="MFA_CODE_LABEL"]');
    const mfaCodeField = document.querySelector('[data-testid="MFA_CODE_INPUT"]');
    const mfaResendMsg = document.querySelector('[data-testid="MFA_RESEND_MSG"]');
    const submitBtn = document.querySelector('[data-testid="PASSWORD_FORM_SUBMIT"]');

    if (action === 'enable') {
      mfaCodeLabel.style.display = 'initial';
      mfaCodeField.style.display = 'initial';
      mfaResendMsg.style.display = 'block';
      globalDispatch(Actions.setCtxField('isDisabled', false));
      submitBtn.innerHTML = 'Submit';
      submitBtn.disabled = true;
    }

    if (action === 'disable') {
      mfaCodeLabel.style.display = 'hidden';
      mfaCodeField.style.display = 'hidden';
      mfaResendMsg.style.display = 'hidden';
      globalDispatch(Actions.setCtxField('isDisabled', false));
      submitBtn.innerHTML = 'Continue';
      submitBtn.disabled = false;
    }
  };

  const handleSubmit = React.useCallback(async e => {
    e.preventDefault();
    const currentPassword = e.target.elements.currentPassword.value.trim();
    const newPassword     = e.target.elements.newPassword.value.trim();
    const mfaChannel      = e.target.elements.mfa.value.trim();
    const mfaCode         = e.target.elements.mfaCode.value.trim();

    const ERROR_OBJ = {
      currentPassword: QKN_PASS_VALIDATIONS.currentPassword(currentPassword),
      newPassword:     QKN_PASS_VALIDATIONS.newPassword(newPassword),
      mfaChannel:      FORM_MFA_VALIDATIONS.mfa(mfaChannel),
      mfaCode:         FORM_MFA_VALIDATIONS.mfaCode(mfaCode),
      form:            ''
    };

    setForm({
      values: {currentPassword, newPassword, mfa, mfaCode},
      errors: ERROR_OBJ
    });

    if (!checkForAnyErrors(Object.values(ERROR_OBJ))) {
      try {
        globalDispatch(Actions.setCtxField('isDisabled', true));

        const apiResp = await callUpdatePasswordV3(currentPassword, newPassword, mfaChannel, mfaCode);
        const data = apiResp.data;

        //Check api response for MFA first request response
        if (apiResp.status == '202') {
          setMfaFields('enable');
          if (data.mfaChannel === 'EMAIL') {
            globalDispatch(Actions.mergeMainStateObj({
              security: {
                mfaSentChannel: data.mfaChannel,
                mfaSentTo: data.email
              }
            }));
          } else if (data.mfaChannel === 'SMS' || data.mfaChannel === 'VOICE') {
            globalDispatch(Actions.mergeMainStateObj({
              security: {
                mfaSentChannel: data.mfaChannel,
                mfaSentTo: data.phone
              }
            }));
          }
        } else {
          MixPanel.track(MixPanel.MIX_PANEL_IDS.PASSWORD);
          globalDispatch(Actions.mergeMainStateObj({
            isDisabled: false,
            security: {
              mfaSentChannel: '',
              mfaSentTo: ''
            },
            alert: {
              type: 'SUCCESS',
              messages: [
                'Your Password has changed successfully.',
                'Redirecting to login page'
              ]
            }
          }));

          setTimeout(() => push(ROUTES.LOGOUT), 2000);

          onClose();
        }

      } catch (err) {
        MixPanel.error(err, MixPanel.MIX_PANEL_IDS.PASSWORD);
        const CODE = err?.response?.data?.errors?.[0]?.code ?? '';
        const errs = err?.response?.data?.errors?.[0] ?? false;

        setForm(({values}) => ({
          values: {...values},
          errors: errorChecker(CODE.toUpperCase(), errs, ERROR_OBJ)
          // errors: {...ERROR_OBJ, form: 'GENERIC_ERROR'}
        }));

        setMfaFields('disable');

        globalDispatch(Actions.setCtxField('isDisabled', false));
      }
    }
  }, [globalDispatch, onClose, push]);

  const HAS_ERROR = React.useMemo(
    () => Object.values(form.errors).some(v => v !== ''),
    [form.errors]
  );

  const IS_GENERIC_ERROR = form.errors.form === 'GENERIC_ERROR';

  return (
    <ModalWrapper heading="Change Password" onClose={onClose}>
      <div className={S.modalBody}>
        {form.errors.form && (
          <Message
            messages={IS_GENERIC_ERROR ? [] : [form.errors.form]}
            type={IS_GENERIC_ERROR ? 'GENERIC_ERROR' : 'ERROR'}
          />
        )}

        <p className={S.warning}>You will be required to re-login upon completion</p>
        <p className={classNames(S.mfaMsgContain, S.mfaSecMsg)}>To help keep your account secure, we ask for your current password when you make any changes to your account.</p>
        <form onSubmit={handleSubmit}>
          <div className={S.passwordForm}>

            <div className={S.newPasswordField}>
              <TextField
                label="New Password"
                name="newPassword"
                type={showField ? 'text' : 'password'}
                id="PASSWORD_FORM_NEW"
                placeholder="Enter New Password"
                error={form.errors.newPassword}
                value={form.values.newPassword}
                disabled={isDisabled}
                onChange={handleChange}
                onBlur={handleChange}
                onFocus={clearFormError}
              />
              <div className={S.checkboxField}>
                <input
                  id="PASSWORD_FORM_TOGGLE"
                  type="checkbox"
                  name="showPassword"
                  checked={showField}
                  disabled={isDisabled}
                  onChange={handleSetField}
                  onFocus={clearFormError}
                />
                <label htmlFor="FORM_PASSWORD_TOGGLE">Show new password</label>
              </div>
            </div>


            <TextField
              label="Current Password"
              name="currentPassword"
              type="password"
              id="PASSWORD_FORM_CURRENT"
              placeholder="Enter Current Password"
              className={S.currentPasswordInput}
              error={form.errors.currentPassword}
              value={form.values.currentPassword}
              disabled={isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              onFocus={clearFormError}
            />
          </div>

          <div className={S.pwMfaContainer}>
            <p className={S.mfaCodeMsg}>To help keep your account secure, we need to verify your identity with a two-factor authentication code. </p>
            
            
            <p data-testid="MFA_RESEND_MSG" className={S.warning}>We sent a code to
              {' '+mfaSentTo+' '}
              <span className={S.resendText}>Didn’t receive it?
                <span
                  className={S.resendLink}
                  onClick={handleResendCode}
                  onKeyUp={handleResendCode}
                  role="button"
                  disabled={isDisabled}
                  tabIndex={0}
                > Resend code </span>
                or try another delivery method </span>
            </p>


            <div className={S.mfaInputContain}>
              <SelectField
                label="How would you like to recieve your code?"
                name="mfa"
                id="MFA_FORM_SELECTION"
                className={S.mfaMethodSelect}
                error={form.errors.mfa}
                value={mfaSentChannel ? mfaSentChannel : form.values.mfa}
                disabled={isDisabled}
                onChange={handleSelection}
                optionList={OPTIONS}
                onFocus={clearFormError}
              />
              
              <TextField
                label="Enter the 6-digit code we sent you"
                name="mfaCode"
                type="number"
                maxLength="6"
                id="MFA_CODE"
                className={S.mfaCodeInputHidden}
                error={form.errors.mfaCode}
                value={form.values.mfaCode}
                disabled={isDisabled}
                onChange={handleMfaCodeChange}
                onInput={handleMfaCodeChange}
                onBlur={handleMfaCodeChange}
                onFocus={clearFormError}
              />
            </div>
          </div>

          <ModalBtnRow
            form="PASSWORD_FORM"
            isDisabled={isDisabled}
            hasError={HAS_ERROR}
            onCancel={onClose}
            submitText="Continue"
          />
        </form>
      </div>
    </ModalWrapper>
  );
};

PasswordModal.displayName = 'PasswordModal';
PasswordModal.propTypes   = {
  onClose: PropTypes.func.isRequired
};

export { PasswordModal };
