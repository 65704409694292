import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import { Message }         from './message';
import { setCtxNestedObj } from '../../context/ctx-actions';
import { useGlobalCtx }    from '../../context/ctx-hook';
import * as S              from '../../sass/layouts/main.module.scss';

const ViewWrapper = ({testId, title, children, showSupport, className}) => {
  const {globalDispatch, state: {alert, ui: {supportPhone}}} = useGlobalCtx();

  React.useEffect(() => {
    if (alert?.messages?.length > 0) {
      setTimeout(
        () => globalDispatch(setCtxNestedObj('alert', {
          messages: [],
          type:     null
        })),
        5000
      );
    }
  }, [globalDispatch, alert.messages]);

  return (
    <section className={ClassNames(S.rightSide, className)}>
      <div className={S.heading}>
        <h2 className={S.headingText} data-testid={testId}>{title}</h2>
        {showSupport && (
          <div className={S.support}>
            <p className={S.title}>Quicken Support</p>
            <p>{supportPhone}</p>
          </div>
        )}
      </div>
      {alert.messages.length > 0 && (<Message {...alert} />)}
      {children}
    </section>
  );
};

ViewWrapper.displayName = 'ViewWrapper';

ViewWrapper.propTypes = {
  testId:      PropTypes.string.isRequired,
  title:       PropTypes.string.isRequired,
  showSupport: PropTypes.bool,
  className:   PropTypes.string,
  children:    PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.array]),
};

ViewWrapper.defaultProps = {
  className:   null,
  showSupport: false
};

export { ViewWrapper };
