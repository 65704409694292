import React from 'react';

import * as S       from './subscription-view.module.scss';
import { TileQkn }  from './tile-qkn/tile-qkn';
import { TileQem }  from './tile-qem/tile-qem';
import { SimpTile } from './tile-simp/simp-tile';
import { Message }  from '../../common/message';
import { ViewWrapper }       from '../../common/view-wrapper';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import { getAccountMessage } from '../../../context/selectors/product-selectors';
import { useOnPageChange }   from '../../../helpers/hooks/use-on-page-change';


const SubscriptionView = () => {
  const {state}   = useGlobalCtx();
  const MESSAGE   = getAccountMessage(state);
  const IS_USA    = state.country === 'us';
  const HAS_QKN   = !!state.subscriptions?.[IS_USA ? 'quicken-us' : 'quicken-ca'];
  const HAS_QEM   = !!state.subscriptions?.['quicken-estate-manager'];
  const SIMP_SUBS = Object.keys(state.subscriptions).filter(sub => sub.includes('acme'));

  useOnPageChange();

  return (
    <ViewWrapper testId="SUBSCRIPTION_AREA" title="Subscriptions">
      {MESSAGE.messages.length > 0 && (<Message {...MESSAGE} />)}

      <div className={S.boxArea}>
        {HAS_QKN && <TileQkn/>}
        {IS_USA && SIMP_SUBS.length > 0 && (
          <div className={S.simpArea}>
            {SIMP_SUBS.map(sub => <SimpTile key={sub} product={sub}/>)}
          </div>
        )}
        {HAS_QEM && <TileQem/>}
      </div>
    </ViewWrapper>
  );
};

SubscriptionView.displayName = 'SubscriptionView';

export { SubscriptionView };
