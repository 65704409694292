import React             from 'react';

import * as S              from './security-info.module.scss';
import { ViewWrapper }     from '../../common/view-wrapper';
import { setCtxNestedObj } from '../../../context/ctx-actions';
import { useGlobalCtx }    from '../../../context/ctx-hook';
import { MODALS }          from '../../../helpers/constants/state-constants';
import { useOnPageChange } from '../../../helpers/hooks/use-on-page-change';
import { maskEmail }       from '../../../helpers/masks';


const SecurityView = () => {
  const {globalDispatch, state: {security: {quickenId, phone, mfa}}} = useGlobalCtx();

  const handleClick = React.useCallback(
    type => globalDispatch(setCtxNestedObj('modal', {
      type,
      isLoading: true,
      hasError: false,
      isDisabled: false,
    })),
    [globalDispatch]
  );

  const EMAIL_MASK = React.useMemo(() => maskEmail(quickenId), [quickenId]);

  useOnPageChange();

  return (
    <ViewWrapper testId="SECURITY_AREA" title="Sign In & Security">

      <div className={S.securityFormWrapper}>
        <div>
          <h3 className={S.contentLabel}>Quicken ID</h3>
          <p className={S.contentText}>{EMAIL_MASK}</p>
          <button
            type="button"
            className={S.contentAction}
            onClick={() => handleClick(MODALS.QUICKEN_ID)}
            data-testid="BTN_CHANGE_QKN_ID"
          >Change</button>
        </div>

        <div>
          <h3 className={S.contentLabel}>Phone Number</h3>
          <p className={S.contentText}>{phone ? `***-***-${phone?.slice('-4')}` : 'N/A'}</p>
          <button
            type="button"
            className={S.contentAction}
            onClick={() => handleClick(MODALS.PHONE)}
            data-testid="BTN_CHANGE_PHONE"
          >Change</button>
        </div>

        <div>
          <h3 className={S.contentLabel}>Password</h3>
          <p className={S.contentText}>***********</p>
          <button
            type="button"
            className={S.contentAction}
            onClick={() => handleClick(MODALS.PASSWORD)}
            data-testid="BTN_CHANGE_PASSWORD"
          >Change</button>
        </div>

        <div>
          <h3 className={S.contentLabel}>Security codes</h3>
          <p className={S.contentText}>Via {mfa}</p>
          <button
            type="button"
            className={S.contentAction}
            onClick={() => handleClick(MODALS.MFA)}
            data-testid="BTN_CHANGE_MFA"
          >Change</button>
        </div>

        <button
          type="button"
          className={S.signOutBtn}
          onClick={() => handleClick(MODALS.SIGN_OUT)}
          data-testid="BTN_SIGN_OUT"
        >Sign Out of all Devices</button>
      </div>
    </ViewWrapper>
  );
};

SecurityView.displayName = 'SecurityView';

export { SecurityView };
