import React     from 'react';
import PropTypes from 'prop-types';


const ForceClose = ({onClose}) => {
  React.useEffect(() => {
    onClose();
  }, [onClose]);

  return null;
};

ForceClose.displayName = 'ForceClose';

ForceClose.propTypes = {
  onClose: PropTypes.func.isRequired
};

export { ForceClose };
