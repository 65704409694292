import client         from 'braintree-web/client';
import usBankAccount  from 'braintree-web/us-bank-account';
import PropTypes      from 'prop-types';
import ClassNames     from 'classnames';
import React          from 'react';

import * as S                 from '../ach-modal/ach-modal.module.scss';
import { ModalBtnRow }        from '../../modal-btn-row';
// import { ModalWrapper }    from '../../modal-wrapper';
import { SelectField }        from '../../../common/inputs/select-field';
import { TextField }          from '../../../common/inputs/text-field';
import { Message }            from '../../../common/message';
import * as Actions           from '../../../../context/ctx-actions';
import { useGlobalCtx }       from '../../../../context/ctx-hook';
import * as ProductSelectors  from '../../../../context/selectors/product-selectors';
import {
  getUserId,
  getProductsPaymentMethod,
  getUserAchIsDeclined,
  getUserHasBtError,
  getUserHasBtNetErr,
  getUserHasBtErrorMsg,
  filterPaymentMethods
}                                 from '../../../../context/selectors/user-selectors';
import * as Options               from '../../../../helpers/constants/options-constants';
import { BM_ACTIONS }             from '../../../../helpers/constants/constants';
import { MIX_PANEL_IDS, track }   from '../../../../helpers/mixpanel';
import * as Tealium               from '../../../../helpers/tealium';
import { checkForAnyErrors }      from '../../../../helpers/pure-functions';
import { checkRebrandName }       from '../../../../helpers/pure-functions';
import { getShortDate }           from '../../../../helpers/time-helpers';
import * as Validations           from '../../../../helpers/validations';
import * as TimeHelpers           from '../../../../helpers/time-helpers';

import * as NetworkCalls from '../../../../network/network-calls';

const BM_PLANS = ['lite', 'basic', 'plus', 'volume50', 'volume100', 'volume200'];

const VALIDATIONS = {
  accountNumber: Validations.validateAchAccount,
  routingNumber: Validations.validateAchRouting,
  accountType: Validations.validateSelection.bind(null, Options.ACH_TYPE.map(({ value }) => value)),
  ownershipType: Validations.validateSelection.bind(null, Options.ACH_OWNER_TYPE.map(({ value }) => value)),
  businessName: Validations.validateBusinessName,
  firstName: Validations.validateAchName,
  lastName: Validations.validateAchName,
  line1: Validations.validateAddressLine1,
  line2: Validations.validateAddressLine2,
  city: Validations.validateCity,
  state: Validations.validateSelection.bind(null, Options.US_STATES_TERRITORY.map(({ value }) => value)),
  zipCode: Validations.validateUsPostal
};

const AchForm = ({ isVisible, onClose, ctx: { product, plan, paymentOnly } }) => {
  const { globalDispatch, state: globalState } = useGlobalCtx();

  const PRODUCT_PAYMENT = getProductsPaymentMethod(product, globalState);
  const HAS_ACH = PRODUCT_PAYMENT ? PRODUCT_PAYMENT?.paymentMethodType === 'ACH' : false;
  const ACH_DECLINED = (HAS_ACH ? getUserAchIsDeclined(product, globalState) : false);
  const ACCOUNT_NUM = (ACH_DECLINED ? globalState.payment.ach.achLast4 : '');
  const ROUTE_NUM = (ACH_DECLINED ? globalState.payment.ach.achRoutingNumber : '');
  const DECLINE_ERR_MSG = (ACH_DECLINED ? `The payment account ending in: ${ACCOUNT_NUM}, with routing number: ${ROUTE_NUM} has been rejected by your bank. Please add another to continue using Quicken Bill Manager.` : null);
  const TOP_UP_TXT = {
    header: 'Automatic Add-On Payments',
    message: 'Once enabled, after using all your available payments, Quicken will automatically add on 3 Quick Pay and 3 Check Pay payments for $3.'
  };
  const TOP_UP_MSG = (plan == 'topup') ? TOP_UP_TXT : false;

  const ACH_BRAINTREE_ERROR = (HAS_ACH ? getUserHasBtError(globalState) : false);
  const ACH_BRAINTREE_NET_ERR = (getUserHasBtNetErr(globalState));
  const ACH_BRAINTREE_ERRMSG = (ACH_BRAINTREE_NET_ERR ? getUserHasBtErrorMsg(globalState) : false);

  const [token, _setToken] = React.useState(null);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [form, setForm] = React.useState({
    errors: {
      accountNumber: '',
      routingNumber: '',
      accountType: '', //(checking or savings)
      ownershipType: '', // (personal or business)
      businessName: '', // (if business)
      firstName: '', // (if personal)
      lastName: '', // (if personal)
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
      form: ''
    },
    values: {
      accountNumber: '',
      routingNumber: '',
      businessName: '',
      accountType: 'CHECKING',
      ownershipType: 'personal',
      firstName: globalState.personalInfo.firstName || '',
      lastName: globalState.personalInfo.lastName || '',
      line1: globalState.personalInfo.line1 || '',
      line2: globalState.personalInfo.line2 || '',
      city: globalState.personalInfo.city || '',
      state: globalState.personalInfo.state || '',
      zipCode: globalState.personalInfo.zipCode || ''
    }
  });

  const { active, autoRenew, tierUriName, expireOn } = ProductSelectors.getSubscription(globalState, product) ?? {};
  const IS_30_AFTER_EXP = TimeHelpers.is30DaysAfter(expireOn);
  const [wantsAR] = React.useState(false);


  React.useEffect(() => {
    client.create({ authorization: process.env.REACT_APP_BRAINTREE_TOKEN })
      .then(clientInstance => usBankAccount.create({ client: clientInstance }))
      .then(usBankAccountInstance => _setToken(usBankAccountInstance));
  }, []);

  const submitHandler = async e => {
    e.preventDefault();


    const ERROR_OBJ = Object.keys(VALIDATIONS).reduce((acm, cur) => {
      if (
        (cur === 'businessName' && form.values.ownershipType === 'personal') ||
        (['firstName', 'lastName'].includes(cur) && form.values.ownershipType === 'business')
      ) {
        return acm;
      }


      return ({
        ...acm, [cur]: VALIDATIONS[cur](form.values[cur])
      });
    }, {});

    if (checkForAnyErrors(Object.values(ERROR_OBJ))) {
      setForm(({ values }) => ({ values, errors: ERROR_OBJ }));
      return;
    }

    setIsDisabled(true);

    token.tokenize({
      bankDetails: {
        accountNumber: form.values.accountNumber,
        routingNumber: form.values.routingNumber,
        accountType: form.values.accountType,
        ownershipType: form.values.ownershipType,
        firstName: form.values.firstName,
        lastName: form.values.lastName,
        businessName: form.values.businessName,
        billingAddress: {
          streetAddress: form.values.line1,
          extendedAddress: form.values.line2,
          locality: form.values.city,
          region: form.values.state,
          postalCode: form.values.zipCode
        }
      },
      mandateText: 'By clicking ["Checkout"], I authorize Braintree, a service of PayPal, on behalf of [your business name here] (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.'
    }).then(async ({ nonce }) => {
      const WANTS_AR  = wantsAR || (!active && !IS_30_AFTER_EXP && autoRenew);

      const { data } = await NetworkCalls.callUpdatePaymentNonce(nonce, {
        autorenewAgreed:      true,
        productLineUriName:   product,
        considerForAutorenew: WANTS_AR
      });

      const PAYMENT_METHOD = data.paymentMethod;

      if (paymentOnly || (active && autoRenew && !plan)) {
        globalDispatch(Actions.mergeMainStateObj({
          payment: filterPaymentMethods(product, PAYMENT_METHOD, globalState),
          alert: {
            type: 'SUCCESS',
            messages: ['Bank Account updated successfully']
          }
        }));

        onClose();
      } else if ((!plan || plan === tierUriName) && active && !autoRenew) { //renew
        const { data } = await NetworkCalls.callUpdateAutoRenew({
          userId: getUserId(globalState),
          product,
          autoRenew: true
        });
        track(MIX_PANEL_IDS.AUTO_RENEW);
        const NAME = data.postState.productName;

        globalDispatch(Actions.mergeMainStateObj({
          subscriptions: ProductSelectors.mergeSubscription(globalState, product, data.postState),
          payment: filterPaymentMethods(product, PAYMENT_METHOD, globalState),
          alert: {
            type: 'SUCCESS',
            messages: autoRenew
              ? [
                'Your membership is now set up!',
                `Your ${checkRebrandName(checkRebrandName(NAME))} renewal date is ${getShortDate(data.postState.expireOn)}`
              ] : [
                `You can continue using ${checkRebrandName(NAME)} until your subscription expires.`,
                'You can turn auto-renew on by clicking \'Set to Renew Automatically\' next to the product information.'
              ]
          }
        }));
        onClose();
      } else if (plan === 'topup') {
        await NetworkCalls.callUpdateBillPayTopUp(!globalState.billManager.autoTopUp);
        const topUpRes = await NetworkCalls.callGetBillPayTopUp(!globalState.billManager.autoTopUp);

        globalDispatch(Actions.mergeMainStateObj({
          isDisabled: false,
          payment: filterPaymentMethods(product, PAYMENT_METHOD, globalState),
          alert: {
            type: 'SUCCESS',
            messages: ['Bank Account added successfully and additional payments activated.']
          },
          billManager: {
            autoTopUp: topUpRes.data.autoTopUp
          }
        }));
        onClose();
      } else { // CREATE|UPGRADE|DOWNGRADE
        const INDEX_OF_CURRENT = BM_PLANS.indexOf(tierUriName);
        const INDEX_OF_PLAN = BM_PLANS.indexOf(plan);
        const ACTION = !active
          ? BM_ACTIONS.CREATE
          : INDEX_OF_CURRENT < INDEX_OF_PLAN ? BM_ACTIONS.UPGRADE : BM_ACTIONS.DOWNGRADE;

        const { data } = await NetworkCalls.callPostChangeTier(plan, ACTION);
        if ([BM_ACTIONS.CREATE, BM_ACTIONS.UPGRADE].includes(ACTION)) {
          Tealium.gtmLink(Tealium.BMTealiumEvent(globalState, data));
        }

        globalDispatch(Actions.mergeMainStateObj({
          subscriptions: ProductSelectors.mergeSubscription(globalState, product, data.postState),
          payment: filterPaymentMethods(product, PAYMENT_METHOD, globalState),
          alert: {
            type: 'SUCCESS',
            messages: ['Bank Account updated successfully']
          }
        }));

        onClose();
      }
    }).catch((err) => {
      console.log('ach token error', err);
      //Checks ACH network errors
      const checkBtNetErr = err.response?.data?.errors[0]?.extData?.achVerificationProcessorResponseCode;
      if (checkBtNetErr) {
        const btNetMsg = err.response?.data?.errors[0]?.detail;
        globalDispatch(Actions.mergeMainStateObj({
          payment: {
            hasBraintreeNetError: true,
            btErrMsg: btNetMsg
          }
        }));
      // Otherwise check for general braintree errrors
      } else if (err?.name === 'BraintreeError') {
        globalDispatch(Actions.mergeMainStateObj({
          payment: {
            hasBraintreeError: true
          }
        }));
      }

      setIsDisabled(false);
    });
  };

  const handleChange = React.useCallback(({ type, target: { name, value } }) => {
    if (type === 'blur') value = value.trim();
    setForm(({ values, errors }) => ({
      values: { ...values, [name]: value },
      errors: { ...errors, [name]: VALIDATIONS[name](value) }
    }));
  }, []);

  const handleAccountChange = React.useCallback(
    ({ type, target: { name, value } }) => {
      if (type === 'blur') value = value.trim();

      setForm(({ values, errors }) => ({
        values: { ...values, [name]: value.replace(/\D/, '') },
        errors: { ...errors, [name]: VALIDATIONS[name](value, type) }
      }));
    },
    []
  );

  const handleSelection = React.useCallback(
    ({ name, value }) => {
      setForm(({ values, errors }) => ({
        values: { ...values, [name]: value },
        errors: { ...errors, [name]: VALIDATIONS[name](value) }
      }));
    }, []);

  const HAS_ERROR = React.useMemo(
    () => checkForAnyErrors(Object.values(form.errors)),
    [form.errors]
  );

  return (
    <div className={ClassNames(S.modalBody, S.formOnly, { [S.show]: isVisible })}>
      {(ACH_BRAINTREE_ERROR || ACH_BRAINTREE_NET_ERR) &&
        <Message
          type='ERROR'
          align='LEFT'
          messages={[ACH_BRAINTREE_ERRMSG]}
          title={ACH_BRAINTREE_NET_ERR ? 'Network Error' : null}
        />
      }

      {ACH_BRAINTREE_ERROR &&
        <Message
          type='GENERIC_ERROR'
          align='CENTER'
        />
      }

      {ACH_DECLINED &&
        <Message
          type='ERROR'
          align='CENTER'
          messages={[DECLINE_ERR_MSG]}
        />
      }

      {TOP_UP_MSG &&
        <Message
          type='INFO'
          align='LEFT'
          messages={[TOP_UP_TXT.message]}
          title={TOP_UP_TXT.header}
        />
      }

      <form onSubmit={submitHandler} className={S.achForm}>
        <div className={S.achRow}>
          <TextField
            id="ach_routingNumber"
            name="routingNumber"
            label="Routing Number"
            placeholder="Enter Routing Number"
            disabled={isDisabled}
            onBlur={handleAccountChange}
            onChange={handleAccountChange}
            value={form.values.routingNumber}
            error={form.errors.routingNumber}
          />

          <TextField
            id="ach_accountNumber"
            name="accountNumber"
            label="Account Number"
            placeholder="Enter Account Number"
            disabled={isDisabled}
            onChange={handleAccountChange}
            onBlur={handleAccountChange}
            value={form.values.accountNumber}
            error={form.errors.accountNumber}
          />
        </div>

        <div className={S.achRow}>
          <SelectField
            label="Account Type"
            id="ach_accountType"
            name="accountType"
            disabled={isDisabled}
            optionList={Options.ACH_TYPE}
            onChange={handleSelection}
            value={form.values.accountType}
            error={form.errors.accountType}
          />

          <SelectField
            label="Ownership"
            id="ach_ownershipType"
            name="ownershipType"
            disabled={isDisabled}
            optionList={Options.ACH_OWNER_TYPE}
            onChange={handleSelection}
            value={form.values.ownershipType}
            error={form.errors.ownershipType}
          />
        </div>

        {form.values.ownershipType === 'business' && (
          <TextField
            name="businessName"
            label="Business Name"
            id="ach_businessName"
            placeholder="Business Name on account"
            disabled={isDisabled}
            onChange={handleChange}
            onBlur={handleChange}
            value={form.values.businessName}
            error={form.errors.businessName}
          />
        )}

        {form.values.ownershipType === 'personal' && (
          <div className={S.achRow}>
            <TextField
              name="firstName"
              label="First Name"
              placeholder="First Name on account"
              id="ach_firstName"
              disabled={isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              value={form.values.firstName}
              error={form.errors.firstName}
            />

            <TextField
              name="lastName"
              label="Last Name"
              id="ach_lastName"
              placeholder="Last Name on account"
              disabled={isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              value={form.values.lastName}
              error={form.errors.lastName}
            />
          </div>
        )}

        <h4>Billing Address</h4>

        <div className={S.achRow}>
          <TextField
            name="line1"
            label="Street"
            id="ach_line1"
            placeholder="Street address"
            disabled={isDisabled}
            onBlur={handleChange}
            onChange={handleChange}
            value={form.values.line1}
            error={form.errors.line1}
          />

          <TextField
            name="line2"
            label="Street 2"
            id="ach_line2"
            placeholder="Building/apt/suite"
            disabled={isDisabled}
            onBlur={handleChange}
            onChange={handleChange}
            value={form.values.line2}
            error={form.errors.line2}
          />
        </div>

        <div className={S.achRow}>
          <TextField
            name="city"
            label="City"
            id="ach_city"
            placeholder="City"
            disabled={isDisabled}
            onBlur={handleChange}
            onChange={handleChange}
            value={form.values.city}
            error={form.errors.city}
          />

          <SelectField
            label="State"
            id="ach_state"
            name="state"
            disabled={isDisabled}
            optionList={Options.US_STATES_TERRITORY}
            onChange={handleSelection}
            value={form.values.state}
            error={form.errors.state}
          />
        </div>

        <div className={S.achRow}>
          <TextField
            name="zipCode"
            label="Zip Code"
            id="ach_zip"
            placeholder="Zip code"
            disabled={isDisabled}
            onBlur={handleChange}
            onChange={handleChange}
            value={form.values.zipCode}
            error={form.errors.zipCode}
          />
          <span className={S.extra} />
        </div>

        <ModalBtnRow
          isDisabled={isDisabled}
          hasError={HAS_ERROR}
          onCancel={onClose}
          submitText="Save Billing"
        />
      </form>
    </div>
    // </ModalWrapper>
  );
};

AchForm.displayName = 'AchForm';
AchForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ctx: PropTypes.shape({
    product: PropTypes.string,
    plan: PropTypes.string,
    paymentOnly: PropTypes.bool.isRequired
  }).isRequired
};

export { AchForm };
