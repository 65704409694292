import React            from 'react';
import PropTypes        from 'prop-types';
import ClassNames       from 'classnames';

import * as S             from './discount.module.scss';

const Discount = ({ className, discountPrice, displayDiscount}) => {
  return (
    <div className={ClassNames(S.priceArea, className)} data-testid="SUB_DISCOUNT">
      <div className={S.price}><p className={S.text}>&nbsp;{discountPrice}&nbsp;</p></div>
      <p className={S.discount}>{displayDiscount} off</p>
    </div>
  );
};

Discount.displayName = 'Discount';
Discount.propTypes   = {
  className:        PropTypes.string,
  discountPrice:    PropTypes.string,
  displayDiscount:  PropTypes.string
};

Discount.defaultProps = {
  className: null
};

export { Discount };
