import * as ReducersFuncs from './reducer-functions';
import * as CTX_CONSTANTS from './ctx-constants';

export function ctxReducers(state, {type, field, payload}) {
  switch (type) {
    case CTX_CONSTANTS.SET_CTX_FIELD:
      return ReducersFuncs.reduceUpdateField(state, field, payload);

    case CTX_CONSTANTS.SET_CTX_FIELDS:
      return ReducersFuncs.reduceUpdateFields(state, payload);

    case CTX_CONSTANTS.SET_CTX_NESTED_OBJECT:
      return ReducersFuncs.reduceUpdateNestedField(state, field, payload);

    case CTX_CONSTANTS.MERGE_GLOBAL_SUBSCRIPTION:
      return ReducersFuncs.reduceMergeSubscriptionData(state, field, payload);

    case CTX_CONSTANTS.UPDATE_PAGE_FIELDS:
      return ReducersFuncs.reduceUpdateNestedField(state, 'page', payload);

    case CTX_CONSTANTS.SET_MAIN_STATE:
      return ReducersFuncs.reduceRootObj(state, payload);

    default:
      throw Error(`Unsupported action type: ${type}`);
  }
}
