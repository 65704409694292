import React            from 'react';
import PropTypes        from 'prop-types';
import { ModalWrapper } from '../modal-wrapper';

import * as S from './dropbox-modal.module.scss';

const OfferExpired = ({onClose}) => {
  return (
    <ModalWrapper heading="Dropbox Offer" onClose={onClose}>
      <div className={S.modalBody}>
        <h3 className={S.noOffer}>No offers are available at this time</h3>
        <button
          type="button"
          onClick={onClose}
          className={S.dbCloseBtn}
        >Close Window</button>
      </div>
    </ModalWrapper>
  );
};

OfferExpired.displayName = 'OfferExpired';
OfferExpired.propTypes   = {
  onClose: PropTypes.func.isRequired
};

export { OfferExpired };
