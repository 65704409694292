export const YEARLY_REGEX         = /Y$/i;

export const EMAIL_REGEX          = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const NUMBERS_ONLY_REGEX   = /^\d+$/;
export const ACCOUNT_NUMBER_REGEX = /^\d{1,17}$/;
export const ROUTING_NUMBER_REGEX = /^\d{8,9}$/;
export const US_POSTAL_REGEX      = /^[0-9]{5}(?:-[0-9]{4})?$/; //(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)
export const CA_POSTAL_REGEX      = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ -]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/i;
export const PHONE_REGEX          = /^1?[2-9]{1}[0-9]{9}$/;
//pw validation regex helpers
export const HAS_UPPER_CASE_CHAR  = /[A-Z]/;
export const HAS_LOWER_CASE_CHAR  = /[A-Z]/;
export const HAS_A_NUMBER         = /\d/;
export const HAS_NO_SPACES        = /^\S*$/;