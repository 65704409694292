import React               from 'react';

import { mergeMainStateObj } from '../../context/ctx-actions';
import { useGlobalCtx }      from '../../context/ctx-hook';

export const useOnPageChange = () => {
  const {globalDispatch} = useGlobalCtx();

  React.useEffect(() => {
    globalDispatch(mergeMainStateObj({
      alert:   {type: null, messages: []},
      message: {type: null, messages: []}
    }));

    return () => {
      globalDispatch(mergeMainStateObj({
        alert:   {type: null, messages: []},
        message: {type: null, messages: []}
      }));
    };
  }, [globalDispatch]);
};
