import React          from 'react';
import { useHistory } from 'react-router';

import { Spinner }           from '../../common/spinner/spinner';
import { setCtxFields }      from '../../../context/ctx-actions';
import { GLOBAL_INIT_STATE } from '../../../context/ctx-constants';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import { ROUTES }            from '../../../helpers/constants/constants';
import { callQAMSignOut }    from '../../../network/network-calls';
import { deleteCookie }      from '../../../helpers/pure-functions';

export const nukeLoginCookies = () => {
  const targetCookies = [
    'qkn_bearer',
    'qkn_signout',
    'Drupal.visitor.user_id',
    'Drupal.visitor.firstname',
    'Drupal.visitor.lastname',
    'Drupal.session_cache.sid'
  ];

  targetCookies.forEach(cookie => deleteCookie(cookie));
};

function LogOut() {
  const {push}                  = useHistory();
  const {globalDispatch, state} = useGlobalCtx();
  const IS_CANADA               = state.country === 'ca';

  React.useEffect(() => {
    const SIGN_OUT_TOKEN = localStorage.getItem('signout');

    if (SIGN_OUT_TOKEN) {
      callQAMSignOut(SIGN_OUT_TOKEN, IS_CANADA).finally(() => {
        nukeLoginCookies();

        const retainPromo = localStorage.getItem('bmPromos');
        localStorage.clear();
        if (retainPromo) {
          localStorage.setItem('bmPromos', retainPromo);
        }

        globalDispatch(setCtxFields(GLOBAL_INIT_STATE));
        push(ROUTES.LOGIN);
      });
    } else {
      nukeLoginCookies();

      const retainPromo = localStorage.getItem('bmPromos');
      localStorage.clear();
      if (retainPromo) {
        localStorage.setItem('bmPromos', retainPromo);
      }

      globalDispatch(setCtxFields(GLOBAL_INIT_STATE));
      push(ROUTES.LOGIN);
    }
  }, [globalDispatch, push, IS_CANADA]);


  // For some reason this was firing immediatley on logon:
  // React.useEffect(() => {
  //   callQAMSignOut().finally(() => {
  //     nukeLoginCookies();

  //     const retainPromo = localStorage.getItem('bmPromos');
  //     localStorage.clear();
  //     if (retainPromo) {
  //       localStorage.setItem('bmPromos', retainPromo);
  //     }

  //     globalDispatch(setCtxFields(GLOBAL_INIT_STATE));
  //     push(ROUTES.LOGIN);
  //   });
  // }, [globalDispatch, push, IS_CANADA]);

  return <Spinner/>;
}

LogOut.displayName = 'LogOut';
export { LogOut };
