import React     from 'react';
import PropTypes from 'prop-types';

import * as S           from '../cancel-ar-modal/survey-modal/survey-modal.module.scss';
import { ModalWrapper } from '../modal-wrapper';
import { ForceClose }   from '../force-close';
import { Message }      from '../../common/message';
import { SimpTile }     from '../../pages/subscriptions/tile-simp/simp-tile';
import { TileQkn }      from '../../pages/subscriptions/tile-qkn/tile-qkn';
import { BmPaid }       from '../../pages/subscriptions/tile-bm/bm-paid';
import { useGlobalCtx } from '../../../context/ctx-hook';
import { getAlertInfo } from '../../../context/selectors/product-selectors';

const AlertModal = ({onClose}) => {
  const {state}                   = useGlobalCtx();
  const {product, title, message} = getAlertInfo(state) ?? {};

  if (!product) return <ForceClose onClose={onClose}/>;

  return (
    <ModalWrapper heading="Your subscription is expiring soon" onClose={onClose}>
      <div className={S.modalBody}>
        <Message type="INFO" title={title} messages={[message]}/>
        {product.includes('quicken') && <TileQkn isModal/>}
        {product === 'acme' && <SimpTile isModal product="acme"/>}
        {product === 'billmanager' && <BmPaid isModal hideCircle/>}
      </div>
    </ModalWrapper>
  );
};

AlertModal.displayName = 'AlertModal';

AlertModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.object.isRequired
};

export { AlertModal };
