import PropTypes from 'prop-types';
import React     from 'react';

import * as S                   from './switch-to-simp.module.scss';
import { ModalWrapper }         from '../../modal-wrapper';
import { PlayBtn }              from '../../../common/assets/play-btn';
import LOGO_SIMPLIFY            from '../../../../assets/imgs/brand/logo-simplifi-full.svg';
import WIRE_CUTTER              from '../../../../assets/imgs/brand/wirecutter.png';
import SAD_STAR                 from '../../../../assets/imgs/art/sad-star.svg';
import CIRCLE_CHECK             from '../../../../assets/imgs/icons/circle-check.svg';
import SIMP_SCREENSHOT          from '../../../../assets/imgs/simp-screenshot.png';
import { mergeMainStateObj }    from '../../../../context/ctx-actions';
import { useGlobalCtx }         from '../../../../context/ctx-hook';
import * as ProductSelectors    from '../../../../context/selectors/product-selectors';
import { MODALS }               from '../../../../helpers/constants/state-constants';
import { checkRebrandName }     from '../../../../helpers/pure-functions';
import { getFullDate, monthsLeft, oneMonthFromToday }     from '../../../../helpers/time-helpers';
import { callAddSubscription }  from '../../../../network/network-calls';
import { gtmLink } from '../../../../helpers/tealium';

const POINTS = [
  'Manage your finances in under 5 minutes per week',
  'Have your transactions  automatically categorized',
  'See your banking, credit card, loans & investments all in one place',
  'Set up savings goals for every milestone'
];

const renderTracking = async () => {
  gtmLink({
    event: 'trackEvent',
    eventCategory: 'My Account',
    eventAction: 'Viewed - Try Simplifi renewal offer',
    trySimplifiFree: 1
  });
};

const trackSwitchSubmit = async () => {
  gtmLink({
    'event': 'transaction',
    'eventCategory': 'ecommerce',
    'eventAction': 'transaction',
    'eventLabel': 'Try Simplifi for Free - Offer',
    'productQuantity': 1,
    'productSKU': '170415',

    //Keys we need to check if data exists for:
    'orderID': 'orderID',
    'productEdition': 'productName',
    'productYear': 'productYear',
    'productPlatform': 'platform',
    'purchasePrice': 'price',
  });
};


const SwitchToSimp = ({onClose, ctx: {product}}) => {
  const {state, globalDispatch} = useGlobalCtx();

  const {expireOn} = ProductSelectors.getSubscription(state, product);
  const NAME       = ProductSelectors.getProductName(state, product);
  const DATE       = getFullDate(expireOn);
  const LAST_MONTH_DATE       = oneMonthFromToday();
  const MONTHS_LEFT = monthsLeft(expireOn);
  const IS_LAST_MONTH = MONTHS_LEFT <= 1;
  // const SWITCH = (IS_LAST_MONTH) ? { 'sku' : '170311', 'term': 'P1M' } : { 'sku' : '170415', 'term': 'P1Y' };

  const [showVideo, setShowVideo] = React.useState(false);

  const MESSAGE = `Your renewal has been cancelled. You’ll be able to use ${checkRebrandName(NAME)} until ${DATE}.`;

  const handleSwitchDecline = () => {
    globalDispatch(mergeMainStateObj({
      modal: {
        type: MODALS.SURVEY,
        surveySrc: 'SWITCH_SIMP_CANCEL_SURVEY',
        ctx: { product },
        isLoading: true,
        hasError: false,
        isDisabled: false,
      }
    }));
  };

  const handleSubmit = React.useCallback(async () => {
    globalDispatch(mergeMainStateObj({
      isDisabled: true,
      modal:      {isLoading: true, isDisabled: true, hasError: false}
    }));

    try {
      const {data} = await callAddSubscription(
        {
          renewalFrequency:  'P1Y',
          sku:              '170525',
          spec:             {
            productLineUriName: 'acme',
            tierUriName:        'acme-basic',
            expirationDate:     expireOn,
            term:               'P1Y',
            isTrial:            true
          }
        });

      trackSwitchSubmit();

      globalDispatch(mergeMainStateObj({
        isDisabled:    false,
        subscriptions: ProductSelectors.mergeSubscription(state, 'acme', data.postState),

        modal: {
          type:       MODALS.SWITCH_SUCCESS,
          ctx:        {name: NAME, date: DATE},
          isLoading:  false,
          hasError:   false,
          isDisabled: false
        }
      }));

    } catch (err) {
      globalDispatch(mergeMainStateObj({
        isDisabled: false,
        modal:      {isLoading: false, isDisabled: false, hasError: true}
      }));
    }
  }, []);

  { renderTracking();}

  return (
    <ModalWrapper
      hideClose
      onClose={onClose}
      className={S.modalWrapper}
      heading="Renewal Cancellation Success"
    >
      <div className={S.modalArea}>
        <div className={S.upperMessage}>
          <img src={SAD_STAR} alt="Sad Star"/>
          <p>{MESSAGE}</p>
        </div>

        <div className={S.shrinkingAreaContainer}>
          <div className={S.shrinkingArea}>
            <div className={S.simpBranding}>
              <img src={LOGO_SIMPLIFY} alt="Simplifi by Quicken"/>
              <div className={S.brandText}>
                <h4>A free gift before you go!</h4> 
                <p>Enjoy our new personal finance app, Simplifi by Quicken,
                  { IS_LAST_MONTH && ' free for the next month'}
                  { !IS_LAST_MONTH && ' for the rest of your subscription.'}
                </p>
              </div>
            </div>
            <div className={S.middleRow}>
              <div className={S.videoArea}>
                {!showVideo && (
                  <>
                    {/* eslint-disable-next-line */}
                    <a 
                      onClick={() => setShowVideo(true)}
                    >
                      <img
                        src={SIMP_SCREENSHOT}
                        className={S.preview}
                        alt="Screenshot of Simplifi home screen"
                      />
                      <div className={S.svgCont}>
                        <PlayBtn/>
                      </div>
                    </a>
                  </>
                )}

                {showVideo && (
                  <iframe
                    className={S.iframe}
                    title="Meet Simplifi"
                    src="https://www.youtube.com/embed/np2__9N9PYY?enablejsapi=1&amp;autoplay=1&rel=0&t=6"
                    frameBorder="0"
                    allow="autoplay"
                  />
                )}

                <div className={S.wireCutter}>
                  <p>“the best budgeting apps & tools”</p>
                  <img src={WIRE_CUTTER} alt="Screenshot of Simplifi home screen" />
                </div>
              </div>
              <ul>
                {POINTS.map((point, i) => (
                  <li key={i}>
                    <img src={CIRCLE_CHECK} alt="CheckMark"/>
                    <p>{point}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className={S.bottomArea}>
          <p className={S.error}>{state.modal.hasError ? 'Server Error occurred please try again' : ''}</p>
          <div className={S.btnRow}>
            <button
              type="button"
              className={`${S.contentCTABtn} accept`}
              onClick={handleSubmit}
              disabled={state.modal.isDisabled}
            >try simplifi for free</button>

            {/* eslint-disable-next-line */}
            <a
              type="button"
              onClick={handleSwitchDecline}
              className={`${S.outlineCTABtn} decline`}
              disabled={state.modal.isDisabled}
            >No thanks, I don’t want this</a>

          </div>
        </div>

        <p className={S.switchLegal}>
          { IS_LAST_MONTH ?
            `*Cancel any time before ${LAST_MONTH_DATE} and pay nothing. If you like Simplifi, keep it instead of Quicken for just $3.99/month, billed annually.` :
            `*Cancel any time before ${DATE} and pay nothing. If you like Simplifi, keep it instead of Quicken for just $3.99/month, billed annually.`
          }

        </p>
      </div>
    </ModalWrapper>
  );
};

SwitchToSimp.displayName = 'SwitchToSimp';
SwitchToSimp.propTypes   = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.shape({
    product: PropTypes.string.isRequired
  })
};

export { SwitchToSimp };
