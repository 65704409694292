import React                 from 'react';
import ReactDOM              from 'react-dom';
import { BrowserRouter }     from 'react-router-dom';
import TagManager            from 'react-gtm-module';
import 'react-slidedown/lib/slidedown.css';
import './index.module.scss';

import './network/axios-settings';
import { GLOBAL_INIT_STATE } from './context/ctx-constants';
import { CtxProviders }      from './context/ctx-providers';
import { RoutingPaths }      from './components/routes/routing-paths';
import { initMixPanel }      from './helpers/mixpanel';
// import { initTealium }       from './helpers/tealium';
import { getGtmConfig }      from './helpers/gtm';
import * as serviceWorker    from './serviceWorker';

initMixPanel();
// initTealium();
const tagManagerArgs = getGtmConfig();

TagManager.initialize(tagManagerArgs);

console.log(`Environment: ${process.env.REACT_APP_ENV}\nTag:${process.env.REACT_APP_BUILD}`);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CtxProviders initState={GLOBAL_INIT_STATE} >
        <RoutingPaths/>
      </CtxProviders>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
