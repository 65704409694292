import { mergeSubscription } from './selectors/product-selectors';

export const reduceRootObj = (state, payload) => {
  if (!state || (payload === undefined)) {
    throw Error('Missing Arguments');
  }

  return Object.keys(payload).reduce((acm, cur) => {
    if (Array.isArray(payload[cur])) return {...acm, [cur]: payload[cur]};
    else if (typeof payload[cur] === 'object') return {...acm, [cur]: {...acm[cur], ...payload[cur]}};
    else return {...acm, [cur]: payload[cur]};
  }, state);
};


export const reduceUpdateField = (state, field, payload) => {
  if (!state || !field || (payload === undefined)) {
    throw Error('Missing Arguments');
  }

  return {
    ...state,
    [field]: payload
  };
};


export const reduceUpdateFields = (state, payload) => {
  if (!state || (payload === undefined)) {
    throw Error('Missing Arguments');
  }

  return {
    ...state,
    ...payload
  };
};


export const reduceUpdateNestedField = (state, field, payload) => {
  if (!field || !state || (payload === undefined)) {
    throw Error('Missing Arguments');
  }

  return {
    ...state,
    [field]: {
      ...state[field], ...payload
    }
  };
};


export const reduceMergeSubscriptionData = (state, product, payload) => {
  if (!state || !product || (payload === undefined)) {
    throw Error('Missing Arguments');
  }

  return {
    ...state,
    subscriptions: mergeSubscription(state, product, payload)
  };
};
