import { parse } from 'query-string';
import React     from 'react';

import { HELP_PHONE }  from '../helpers/constants/constants';
import { PAGE_STATUS } from '../helpers/constants/state-constants';

export const GLOBAL_CXT = React.createContext();

export const SET_MAIN_STATE            = 'SET_INIT_DATA';
export const SET_CTX_FIELD             = 'SET_CTX_FIELD';
export const SET_CTX_FIELDS            = 'SET_CTX_FIELDS';
export const SET_CTX_NESTED_OBJECT     = 'SET_CTX_NESTED_OBJECT';
export const MERGE_GLOBAL_SUBSCRIPTION = 'MERGE_GLOBAL_SUBSCRIPTION';
export const UPDATE_PAGE_FIELDS        = 'UPDATE_PAGE_FIELDS';

const QS_PARMS = parse(window.location.search, {
  parseNumbers: true
});

export const GLOBAL_INIT_STATE = {
  pageStatus:    PAGE_STATUS.LOADING,
  isLoggedIn:    false,
  isDisabled:    false,
  country:       QS_PARMS?.site ?? 'us',
  subscriptions: {},
  promotions:    null,
  skus:          {},
  history:       {},
  payment:       {ach: [ ], credit: [ ]},

  personalInfo: {
    firstName: '',
    lastName:  '',
    fullName:  '',
    line1:     '',
    line2:     '',
    city:      '',
    state:     '',
    country:   QS_PARMS?.site ?? 'us',
    zipCode:   ''
  },

  security: {
    quickenId:      '',
    phone:          '',
    mfa:            'EMAIL',
    mfaSentChannel: '',
    mfaSentTo:      ''
  },

  modal: {type: null, surveySrc: '', ctx: null, isLoading: true, isDisabled: false},
  alert: {type: null, messages: []}, // something that is fast transactional

  ui: {
    hasFooter:     false,
    navListOpen:   false, // also has css properties
    supportPhone:  HELP_PHONE,
    isMac:         navigator.platform.toUpperCase().indexOf('MAC') >= 0,
    embed:         QS_PARMS['embed'] ?? false,
    hideNav:       QS_PARMS['hide-nav'] ?? false,
    showDownloads: QS_PARMS['show-downloads'] !== undefined ? !!QS_PARMS['show-downloads'] : true
  },

  billManager: {
    plans: {},
    order: [],
    topUp: {
      autoTopUp: false,
      // price:        null,
      // checkLimit:   0,
      // quickLimit:   0,
      // date:         null
    }
  },

  debug: {
    isAvailable: process.env.REACT_APP_ENV !== 'production',
    isVisible: false,
  }
};
