import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import * as S           from './overlay.module.scss';
import { useGlobalCtx } from '../../../context/ctx-hook';


const Overlay = ({onClose, className, children}) => {
  const {state: {isDisabled}} = useGlobalCtx();

  const handleClose = React.useCallback(e => {
    if (!isDisabled && e.target.className === S.overlay) onClose();
  }, [onClose, isDisabled]);

  return (
    <div
      role="presentation"
      className={ClassNames(S.overlay, className)}
      onClick={handleClose}
    >{children}</div>
  );
};

Overlay.displayName = 'Overlay';

Overlay.propTypes = {
  className: PropTypes.string,
  onClose:   PropTypes.func.isRequired,
  children:  PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.array])
};

Overlay.defaultProps = {
  className: null
};

export { Overlay };
