import React from 'react';
import * as pjson from '../../../package.json';

function DebuggingArea() {
  const SEARCH = window.location.search.replace('?', '').split('&');
  
  return (
    <table>
      <tbody>
        <tr>
          <td>Environment:</td>
          <td>{process.env.REACT_APP_ENV}</td>
        </tr>
        <tr>
          <td>Tag:</td>
          <td>{process.env.REACT_APP_BUILD}</td>
        </tr>
        <tr>
          <td>url:</td>
          <td>{window.location.origin}{window.location.pathname}</td>
        </tr>
        <tr>
          <td>search:</td>
          <td>
            <ul>
              {SEARCH.map((parms, idx) => {
                const [key, value] = parms.split('=');
                return (
                  <li key={idx}>{key}: {value}</li>
                );
              })}
            </ul>
          </td>
        </tr>

        <tr>
          <td>Library versions: </td>
        </tr>
        <tr>
          <td>
            <ul>
              <pre>
                {JSON.stringify(pjson.default.dependencies, null, 2)}
              </pre>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

DebuggingArea.displayName = 'DebuggingArea';

export { DebuggingArea };
