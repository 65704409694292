import React     from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import * as S from './pagination.module.scss';

const Pagination = ({interval, total, selected, onSelection, className}) => {
  return (
    <div className={ClassNames(S.pagination, className)}>
      {Array.from({length: Math.ceil(total / interval)}).map((_, index) => (
        <button
          key={index}
          type="button"
          className={selected === index ? S.selected : null}
          onClick={() => onSelection(index)}
        >{index + 1}</button>
      ))}
    </div>
  );
};

Pagination.displayName = 'Pagination';
Pagination.propTypes   = {
  interval:    PropTypes.number.isRequired,
  total:       PropTypes.number.isRequired,
  selected:    PropTypes.number.isRequired,
  className:   PropTypes.string,
  onSelection: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  className: '',
};
export { Pagination };
