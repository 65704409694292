import PropTypes from 'prop-types';
import React     from 'react';

import * as S                         from './change-plan-acme.module.scss';
import { ModalWrapper }               from '../modal-wrapper';
import { Price }                      from '../../common/product/price';
import { ProductName }                from '../../common/product/prod-name';
import { TagLine }                    from '../../common/product/tag-line';
// import { getRelatedSkus }             from '../../../context/selectors/simp-selectors';
import { setCtxField }                from '../../../context/ctx-actions';
import { useGlobalCtx }               from '../../../context/ctx-hook';
import { getEStoreCartUrlAddProduct } from '../../../helpers/route-helper';
import { gtmLink }                from '../../../helpers/tealium';


const ReactivateSimpModal = ({onClose, ctx: {product}}) => {
  const {globalDispatch, state: {isDisabled, skus, subscriptions, country}} = useGlobalCtx();

  // const {renewalSkutierName}  = subscriptions[product];
  // const {monthlySku, annualSku} = getRelatedSkus(renewalSku);
  const { tierName }  = subscriptions[product];
  const annualSku = '170522';

  const handleSelection = async sku => {
    globalDispatch(setCtxField('isDisabled', true));

    // tealiumLink({
    //   event:           'Cart Add',
    //   eventAction:     'cart add',
    //   eventCategory:   'ecommerce',
    //   eventLabel:      sku,
    //   productEdition:  tierName,
    //   productPrice:    skus?.[sku]?.RenewalPrice ?? '',
    //   productQuantity: 1,
    //   productSKU:      sku,
    //   productYear:     '2020'
    // });

    gtmLink({
      event:           'Cart Add',
      eventAction:     'cart add',
      eventCategory:   'ecommerce',
      eventLabel:      sku,
      productEdition:  tierName,
      productPrice:    skus?.[sku]?.RenewalPrice ?? '',
      productQuantity: 1,
      productSKU:      sku,
      productYear:     '2020'
    });


    window.location.href = getEStoreCartUrlAddProduct(sku, country);
  };

  return (
    <ModalWrapper heading="Reactivate Simplifi" onClose={onClose} className={S.wrapper}>
      <div className={S.modalBody}>

        <article className={S.product}>
          <ProductName customName='Simplifi' product='simplifi' className={S.productName}/>
          <TagLine uriName='acme-basic' className={S.tagLine}/>

          <Price
            sku={annualSku}
            className={S.priceWrapper}
            renewalFrequency='P1Y'
          />

          <button
            className={S.contentCTABtn}
            disabled={isDisabled}
            onClick={() => handleSelection(annualSku)}
          >ANNUAL PLAN</button>
        </article>

        <div className={S.cpDash}/>

        {/* <article className={S.product}>
          <ProductName name="Simplifi" className={S.productName}/>
          <TagLine uriName="acme-basic" className={S.tagLine}/>

          <Price
            sku={monthlySku}
            className={S.priceWrapper}
            renewalFrequency="P1M"
          />

          <button
            className={S.contentCTABtn}
            disabled={isDisabled}
            onClick={() => handleSelection(monthlySku)}
          >MONTHLY PLAN</button>
        </article> */}
      </div>
    </ModalWrapper>
  );
};

ReactivateSimpModal.displayName = 'ReactivateSimpModal';
ReactivateSimpModal.propTypes   = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.object.isRequired
};

export { ReactivateSimpModal };
