import { parse } from 'query-string';
import React     from 'react';

import { SkeletonPage }                     from './skeleton-page';
import * as Actions                         from '../../context/ctx-actions';
import { useGlobalCtx }                     from '../../context/ctx-hook';
import { PAGE_STATUS }                      from '../../helpers/constants/state-constants';
import { HELP_PHONE_PREMIUM, HELP_PHONE }   from '../../helpers/constants/constants';
import * as PureFuncs                       from '../../helpers/pure-functions';
import * as NetworkCalls                    from '../../network/network-calls';
import Moment                               from 'moment';

const getPaymentMethods = methodList => {
  let paymentObj = {
    ach: [], 
    credit: []
  };

  methodList.forEach(method => {
    const IS_DEFAULT_METHOD = (method?.defaultMethod === true);
    const IS_USED_FOR_PRODUCT = (method?.defaultForProductLines?.length > 0);

    if (method?.paymentMethodType === 'ACH' && (IS_USED_FOR_PRODUCT || IS_DEFAULT_METHOD)) {
      paymentObj.ach.push(method);
    } else if (IS_USED_FOR_PRODUCT || IS_DEFAULT_METHOD) {
      paymentObj.credit.push(method);
    }
  });

  return paymentObj;
};

const getPageData = async () =>
  await Promise.all([
    NetworkCalls.callGetUserInfo(),
    NetworkCalls.callGetUserProfile(),
    NetworkCalls.callGetSubscriptions(),
    NetworkCalls.callGetPaymentMethods(),
    NetworkCalls.callGetBillPayTopUp(),
    NetworkCalls.callGetTierPrices(),
    NetworkCalls.callGetHistories(),
    NetworkCalls.callGetChatHours(),
    NetworkCalls.callGetFeatureFlags()
  ]);

const GetSiteData = () => {

  const {globalDispatch} = useGlobalCtx();

  const getSiteData = React.useCallback(async () => {
    try {
      const QS_PARMS = parse(window.location.search, {parseNumbers: true});

      let COUNTRY    = QS_PARMS?.site ?? 'us';
      const HIDE_NAV   = QS_PARMS['hide-nav'] ? QS_PARMS['hide-nav'] === 1 : false;
      const IS_EMBED   = QS_PARMS.embed ? QS_PARMS.embed === 1 : false;
      const HAS_FOOTER = !HIDE_NAV && !IS_EMBED;

      globalDispatch(Actions.setCtxField('pageStatus', PAGE_STATUS.LOADING));

      const [userInfo, profile, subscription, methods, topUps, tierPrices, histories, chatHours, featureFlags] = await getPageData();

      const HISTORY_MAP = histories.data.reduce((acm, {line, ...rest}) => {
        if (!acm[line]) acm[line] = [];
        return {...acm, [line]: [...acm?.[line], rest]};
      }, {});

      const CA_SUBS = PureFuncs.filterSimpSubs(PureFuncs.createSubscriptionMap(subscription.data, 'ca'));
      const HAS_CA_SUB = !(Object.keys(CA_SUBS).length === 0 && CA_SUBS.constructor === Object);
      const US_SUBS = PureFuncs.filterSimpSubs(PureFuncs.createSubscriptionMap(subscription.data, 'us'));
      const HAS_US_SUB = !(Object.keys(US_SUBS).length === 0 && US_SUBS.constructor === Object);
      const IS_ON_CA_SITE = COUNTRY === 'ca';

      if (HAS_CA_SUB && !HAS_US_SUB && !IS_ON_CA_SITE ) {
        COUNTRY = 'ca';
        let url = new URL(window.location.href);
        if(url.searchParams.has('site')) {
          url.searchParams.set('site', 'ca');
        } else {
          url.searchParams.append('site', 'ca');
        }
        window.location.replace(url);
      }

      const SUB_MAP = PureFuncs.filterSimpSubs(PureFuncs.createSubscriptionMap(subscription.data, COUNTRY));

      let skuData = await NetworkCalls.callGetSkuInfo();
      // Call transform sku info, whitelisting with the SKUS array as callGetSkuInfo returns all
      skuData = await NetworkCalls.transformSkuInfo(skuData);

      if (SUB_MAP.acme) {
        let SKU     = SUB_MAP.acme.renewalSku;
        let newSkus = [];

        if (SKU && skuData[SKU].RenewalSKU && skuData[SKU].RenewalSKU !== SKU) {
          newSkus = [skuData[SKU].RenewalSKU];
        }

        if (SKU && skuData[SKU].SwitchMonthlyAnnualSKU && skuData[SKU].SwitchMonthlyAnnualSKU !== SKU) {
          newSkus = [...newSkus, skuData[SKU].SwitchMonthlyAnnualSKU];
        }

        if (newSkus.length > 0) {
          const SWITCH_CALL = await NetworkCalls.callGetSkuInfo(newSkus.join(','));
          skuData           = {...skuData, ...SWITCH_CALL.data};
        }
      }

      // Set all user cookies Drupal used to on login:
      const curCookies = PureFuncs.parseCookies(document.cookie);
      if (!curCookies?.qkn_subscriber) {
        const qknSubCookie = {
          'is_customer': 1, //Has to login if they're here so we can hardcode this
          'is_subscriber': 0,
          'id': userInfo.data.username,
          'tier': '',
          'country': COUNTRY,
          'year': '',
          'exp_date': false,
          'subs': PureFuncs.getProductLineBits(subscription.data),
          'sees_discount': 1
        };

        // Setup cookie for inactive vs active sub users and don't run this for no sub users
        const activeSubs = subscription.data.filter((sub) => sub.active === true);
        const hasActiveSub = activeSubs.length >= 1;
        const expiredSubs = subscription.data;
        const hasExpiredSub = expiredSubs.length >= 1;
        
        if(hasActiveSub || hasExpiredSub) {
          const targetSub = hasActiveSub ? activeSubs[0] : expiredSubs[0];

          const subExpPlus90 = Moment(targetSub.expireAt).add(91, 'days');
          const todayBeforeExpPlus90 = Moment().isSameOrBefore(subExpPlus90, 'day');
          qknSubCookie.is_subscriber = todayBeforeExpPlus90 ? 1 : 0;
          qknSubCookie.sees_discount = todayBeforeExpPlus90 ? 0 : 1;
          qknSubCookie.tier = targetSub.tierUriName;
          qknSubCookie.exp_date = (Date.parse(targetSub.expireAt) / 1000);

          PureFuncs.setCookie('qkn_subscriber', encodeURIComponent(JSON.stringify(qknSubCookie)), false, subExpPlus90);
        } else {
          // No sub just set the default state cookie for 1 year, purchase flow will update it if needed
          PureFuncs.setCookie('qkn_subscriber', encodeURIComponent(JSON.stringify(qknSubCookie)), 8760);
        }

        if (profile.data?.primaryEmail?.address) {
          PureFuncs.setCookie('Drupal.visitor.user_id', profile.data?.primaryEmail?.address, 1);
        }
        if (profile?.data?.firstName) {
          PureFuncs.setCookie('Drupal.visitor.firstname', profile?.data?.firstName, 1);
        }
        if (profile.data?.lastName) {
          PureFuncs.setCookie('Drupal.visitor.lastname', profile.data?.lastName, 1);
        }
      } else {
        if (profile.data?.primaryEmail?.address) {
          PureFuncs.setCookie('Drupal.visitor.user_id', profile.data?.primaryEmail?.address, 1);
        }
        if (profile?.data?.firstName) {
          PureFuncs.setCookie('Drupal.visitor.firstname', profile?.data?.firstName, 1);
        }
        if (profile.data?.lastName) {
          PureFuncs.setCookie('Drupal.visitor.lastname', profile.data?.lastName, 1);
        }
      }

      globalDispatch(Actions.mergeMainStateObj({
        pageStatus:    PAGE_STATUS.READY,
        userId:        profile.data.id,
        emailId:       profile?.data?.primaryEmail?.id ?? null,
        subscriptions: SUB_MAP,
        skus:          skuData,
        tierPrices:    tierPrices.data[COUNTRY],
        country:       COUNTRY,
        payment:       getPaymentMethods(methods.data),
        footer:        HAS_FOOTER,

        personalInfo: {
          firstName: profile.data?.firstName ?? '',
          lastName:  profile.data?.lastName ?? '',
          fullName:  profile.data?.primaryAddress?.fullName ?? '',
          line1:     profile.data?.primaryAddress?.line1 ?? '',
          line2:     profile.data?.primaryAddress?.line2 ?? '',
          city:      profile.data?.primaryAddress?.city ?? '',
          state:     profile.data?.primaryAddress?.state?.trim() ?? '',
          country:   profile.data?.primaryAddress?.country ?? '',
          zipCode:   profile.data?.primaryAddress?.zipCode ?? ''
        },

        security: {
          quickenId: userInfo.data.username,
          phone:     userInfo.data.phone,
          mfa:       userInfo.data.defaultChannel,
          groups:    userInfo.data.groups
        },

        ui: {
          clientId:         QS_PARMS?.clientId ?? null,
          hasFooter:        HAS_FOOTER,
          hasDBOffer:       !!SUB_MAP[`quicken-${COUNTRY}`],
          hasSubscriptions: Object.keys(SUB_MAP).length > 0,
          supportPhone:     userInfo?.data?.entitlements?.split(',').includes('PREMIUM_SUPPORT') ? HELP_PHONE_PREMIUM : HELP_PHONE,

          embed:         QS_PARMS['embed'] ?? false,
          hideNav:       QS_PARMS['hide-nav'] ?? false,
          showDownloads: QS_PARMS['show-downloads'] !== undefined ? !!QS_PARMS['show-downloads'] : true,
          chatHours:     chatHours.data
        },
        billManager: {...topUps.data},

        history: HISTORY_MAP,
        featureFlags: featureFlags?.data || { }
      }));
    } catch (err) {
      console.log(err);
      globalDispatch(Actions.setCtxField('pageStatus', PAGE_STATUS.HAS_ERROR));
    }
  }, [globalDispatch]);

  React.useEffect(() => {
    getSiteData();
  }, [getSiteData]);

  return <SkeletonPage/>;
};

GetSiteData.displayName = 'GetSiteData';

export { GetSiteData };
