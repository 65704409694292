import React from 'react';
import PropTypes from 'prop-types';

import * as S from './switch-field.module.scss';

const ToggleSwitch = ({id, isActive, handleChange, isDark, isLight}) => {
  return (
    <label
      htmlFor={id}
      className={isLight ? S.lightToggle : (isDark ? S.darkToggle : S.toggleField)}
      data-testid={`${id}_LABEL`}
    >
      <input
        type="checkbox"
        id={id}
        checked={isActive}
        onChange={handleChange}
        data-testid={`${id}_INPUT`}
      />
      <span/>
    </label>
  );
};

ToggleSwitch.displayName = 'ToggleSwitch';
ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  isDark: PropTypes.bool,
  isLight: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

ToggleSwitch.defaultProps = {isDark: false};

export { ToggleSwitch };
