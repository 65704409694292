import React       from 'react';
import PropTypes   from 'prop-types';
import QueryString from 'query-string';

import { Spinner }       from '../../common/spinner/spinner';
import { setCtxField }   from '../../../context/ctx-actions';
import { useGlobalCtx }  from '../../../context/ctx-hook';
import { ROUTES }        from '../../../helpers/constants/constants';
import * as NetworkCalls from '../../../network/network-calls';
import { setCookie } from '../../../helpers/pure-functions';


const LoginPage = ({history: {push}, location: {search}}) => {
  const {globalDispatch} = useGlobalCtx();

  const getAuthToken = React.useCallback(async (code, params) => {
    try {
      const POST_LOGIN = localStorage.getItem('postLogin');
      const {data}     = await NetworkCalls.getUserToken(code);
      localStorage.setItem('bearer', data.accessToken);
      setCookie('qkn_bearer', data.accessToken, 1);

      globalDispatch(setCtxField('isLoggedIn', true));

      if (POST_LOGIN) {
        const {search, pathname} = JSON.parse(POST_LOGIN);
        const POST_QS            = QueryString.parse(search, {parseNumbers: true});
        localStorage.removeItem('postLogin');
        push({
          pathname,
          search: QueryString.stringify(POST_QS)
        });
      } else {
        push({
          pathname: ROUTES.MAIN,
          search:   QueryString.stringify(params)
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [globalDispatch, push]);

  React.useEffect(() => {
    const {code, ...params} = QueryString.parse(search);

    if (code) {
      getAuthToken(code, params);
    } else {
      const SEARCH = QueryString.stringify({
        client_id:     process.env.REACT_APP_CLIENT_ID,
        redirect_uri:  process.env.REACT_APP_REDIRECT_URL,
        response_type: 'code'
      });

      // send user to SSO
      window.location.href = `${process.env.REACT_APP_SIGN_IN_URL}/signin?${SEARCH}`;
    }
  }, [getAuthToken, push, search]);

  return <Spinner/>;
};

LoginPage.displayName = 'LoginPage';
LoginPage.propTypes   = {
  history:  PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export { LoginPage };
