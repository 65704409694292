import React     from 'react';
import PropTypes from 'prop-types';

import * as S              from './sub-payment-area.module.scss';
import BankIcon            from '../../../assets/imgs/icons/icon-bank2.svg';
import PAYPAL_LOGO         from '../../../assets/imgs/brand/paypal.png';
import { setCtxNestedObj } from '../../../context/ctx-actions';
import { useGlobalCtx }    from '../../../context/ctx-hook';
import * as UserSelectors  from '../../../context/selectors/user-selectors';
import * as ProductSelectors from '../../../context/selectors/product-selectors';
import { MODALS }          from '../../../helpers/constants/state-constants';
import { maskEmail }       from '../../../helpers/masks';
import { getCCLogo }       from '../../../helpers/payment-helpers';
//import { ProductName }     from '../../common/product/prod-name';


const SubPaymentMethod = ({ product, custClass}) => {
  const {globalDispatch, state} = useGlobalCtx();

  // Used for products that rely on others for payment method
  let paymentReferenceProduct;
  if(product === 'quicken-online-backup'){
    paymentReferenceProduct = `quicken-${state.country}`;
  } else{
    paymentReferenceProduct = product;
  }

  const canChangePayment = product === paymentReferenceProduct;
  const productPaymentObj = UserSelectors.getProductsPaymentMethod(paymentReferenceProduct, state);

  let src, alt, details, className;

  const handleUpdatePayment = React.useCallback(() => {
    globalDispatch(setCtxNestedObj('modal', {
      type:      MODALS.UPDATE_PAYMENT,
      isLoading: true,
      hasError:  false,
      isDisabled: false,
      ctx:       {
        product,
        paymentOnly: true
      }
    }));
  }, [globalDispatch, product]);

  
  
  // if (product === 'billmanager') {
  if (productPaymentObj.paymentMethodType === 'ACH') {
    // const METHOD = UserSelectors.getUserAch(state);
    const METHOD = UserSelectors.getProductsPaymentMethod(product, state);
    src          = BankIcon;
    alt          = 'Bank Icon';
    details      = `****${METHOD.achLast4}`;
    className    = S.ccPaymentArea;
  } else {
    const { 
      paymentMethodType,
      paypalEmail, 
      ccType, 
      ccLast4 
    } = UserSelectors.getProductsPaymentMethod(product, state);
    // } = UserSelectors.getUserCredit(state);

    if (paymentMethodType === 'PAYPAL') {
      src       = PAYPAL_LOGO;
      alt       = 'PayPal Logo';
      details   = maskEmail(paypalEmail);
      className = S.ppPaymentArea;
    } else {
      src       = getCCLogo(ccType);
      alt       = `${ccType} logo`;
      details   = ccLast4 ? `****${ccLast4}` : '';
      className = S.ccPaymentArea;
    }
  }

  return (
    <article className={`${className} ${S[custClass]}`}>
      <img src={src} alt={alt}/>
      <span>{details}</span>
      { canChangePayment && 
        <button
          type="button"
          className={S.linkBtn}
          onClick={handleUpdatePayment}
          disabled={state.isDisabled}
        >Update Payment</button>
      }
      { !canChangePayment &&
        <div className={S.tagLine}> 
          {ProductSelectors.getProductName(state, product) + ' '} 
           uses your 
          {' ' + ProductSelectors.getProductName(state, paymentReferenceProduct) + ' '}
           payment method.</div>
      }

      
    </article>
  );
};

SubPaymentMethod.displayName = 'SubPaymentMethod';
SubPaymentMethod.propTypes   = {
  product: PropTypes.string.isRequired,
  custClass: PropTypes.string
};

export { SubPaymentMethod };
