import { useCallback, useEffect } from 'react';

import { setCtxNestedObj } from '../../context/ctx-actions';
import { useGlobalCtx }    from '../../context/ctx-hook';
import { callGetSkuInfo, transformSkuInfo }  from '../../network/network-calls';

export const useGetSkuPrice = (sku) => {
  const {globalDispatch, state: {skus}} = useGlobalCtx();

  const getSkuData = useCallback(async sku => {
    const data = await callGetSkuInfo(sku);
    const transformedData = await transformSkuInfo(data);
    globalDispatch(setCtxNestedObj('skus', transformedData));
  }, [globalDispatch]);

  useEffect(() => {
    if (sku && !skus[sku]) getSkuData(sku);
    // eslint-disable-next-line
  }, [sku, getSkuData]);
};
