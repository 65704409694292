import PropTypes        from 'prop-types';
import React            from 'react';
import * as BMSelectors from '../../../../context/selectors/bill-selectors';

import * as S                from './current-plan-bm.module.scss';
import { Price }             from '../../../common/product/price';
import { useGlobalCtx }      from '../../../../context/ctx-hook';
import * as ProductSelectors from '../../../../context/selectors/product-selectors';
import { splitQuota }        from '../../../../helpers/pure-functions';


const CurrentPlanBm = ({handleChangePlan, sku, name, uriName, ctaText, quota, isPromo}) => {
  const {state}    = useGlobalCtx();
  const {tierName} = ProductSelectors.getSubscription(state, `quicken-${state.country}`);
  const PLANS = Object.values(BMSelectors.getBMPlan(state));
  
  let {billpay = '', checkpay = ''} = React.useMemo(
    () => splitQuota(quota),
    []
  );

  return (
    <div>
      <div className={S.label}>Current Plan</div>
      <article className={`${S.currentPlan} ${uriName}`} data-testid={`PLAN_${uriName.toUpperCase()}`}>
        <div className={S.leftSide}>
          <div className={S.name}>{name}</div>
          <p className={`${S.quota} ${S.stackedP}`}>Check Pay - {checkpay} per month </p>
          <p className={`${S.quota} ${S.stackedP}`}>Quick Pay - {billpay} per month </p>
          {!sku && !isPromo && <p className={S.benefit}>Included with Quicken {tierName}</p>}
        </div>

        {sku && (<Price
          className={S.currentPrice}
          renewalFrequency="P1M"
          sku={sku}
        />)}

        {sku ? (
          <button
            className={PLANS.includes(uriName) || state.isDisabled ? S.inactiveBtn : S.activeBtn}
            disabled={PLANS.includes(uriName) || state.isDisabled}
            onClick={handleChangePlan}
          >{ctaText}</button>
        ) : (
          <button className={S.noCTA} disabled={true}>Current Plan</button>
        )}

      </article>
    </div>
  );
};

CurrentPlanBm.displayName = 'CurrentPlanBm';
CurrentPlanBm.propTypes   = {
  sku:     PropTypes.string,
  name:    PropTypes.string.isRequired,
  uriName: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  quota:   PropTypes.string.isRequired,
  isPromo: PropTypes.bool,

  handleChangePlan: PropTypes.func.isRequired
};

export { CurrentPlanBm };
