import React     from 'react';
import { parse } from 'query-string';

import { NoAuthRoutes }     from './no-auth-routes';
import { LoggedInTemplate } from '../logged-in-template/logged-in-template';
import { useGlobalCtx }     from '../../context/ctx-hook';
import { setCtxField }      from '../../context/ctx-actions';
import { parseCookies }     from '../../helpers/pure-functions';
// import { nukeLoginCookies } from '../pages/authentication/logout';
// import { deleteCookie }     from '../../helpers/pure-functions';


function RoutingPaths() {
  const {state: {isLoggedIn, pageStatus}} = useGlobalCtx();
  const { globalDispatch } = useGlobalCtx();

  const COOKIE   = parseCookies(document.cookie);
  const QS_PARMS = parse(window.location.search, {parseNumbers: true});
  const SIGN_OUT = (COOKIE?.qkn_signout || QS_PARMS.signout) ?? null;
  const LS_BEARER = localStorage.getItem('bearer');

  // This is a hotfix for broken URL we are recieveing in embeded flows.
  // This will handle multiple bearer tokens in the URL and
  const searchParams   = new URLSearchParams(window.location.search);
  const tokens         = searchParams.getAll('bearer');
  const nonEmptyTokens = tokens.filter(e => e);
  const BEARER         = (COOKIE?.qkn_bearer || nonEmptyTokens[0]) ?? null;

  if(!BEARER && LS_BEARER ) {
    localStorage.removeItem('bearer');
    globalDispatch(setCtxField('isLoggedIn', false));
  }

  if (SIGN_OUT) {
    localStorage.setItem('signout', SIGN_OUT);
  }

  if (BEARER) {
    localStorage.setItem('bearer', BEARER);
  }

  return isLoggedIn || localStorage.getItem('bearer')
    ? <LoggedInTemplate status={pageStatus}/>
    : <NoAuthRoutes/>;
}

RoutingPaths.PageLayout = 'RoutingPaths';

export { RoutingPaths };
