export function getErrorMsgs(field) {
  switch (field) {
    case 'number':
      return 'Please enter a valid card number';
    case 'expirationDate':
      return 'Please enter a valid expiration date';
    case 'cvv':
      return 'Please enter valid security code';
    case 'cardholderName':
      return 'Please enter your name as shown on the credit card';
    case 'postalCode':
      return 'Please enter a valid zip code';
    case 'name':
      return 'Please enter your name';
    case 'zip':
      return 'Please enter a valid zip code';

    default:
      return '';
  }
}
