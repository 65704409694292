import * as GlobalConstants from './ctx-constants';

// UPDATE 1 Specific Field (Overwrites)
export const setCtxField = (field = '', payload = null) => ({
  type: GlobalConstants.SET_CTX_FIELD,
  field,
  payload
});

// UPDATE N Specific Fields (Overwrites)
export const setCtxFields = (payload = {}) => ({
  type: GlobalConstants.SET_CTX_FIELDS,
  payload
});

// UPDATE N Specific Fields (Only overwrites if present)
export const setCtxNestedObj = (field = '', payload = {}) => ({
  type: GlobalConstants.SET_CTX_NESTED_OBJECT,
  field,
  payload
});

// UPDATE existing fields & nested Objects
export const mergeMainStateObj = (payload = {}) => ({
  type: GlobalConstants.SET_MAIN_STATE,
  payload
});

// UPDATE existing subscription field
export const mergeSubscriptionData = (field = '', payload = {}) => ({
  type: GlobalConstants.MERGE_GLOBAL_SUBSCRIPTION,
  field,
  payload
});
