import PropTypes from 'prop-types';
import React     from 'react';

import { GLOBAL_CXT }  from './ctx-constants';
import { ctxReducers } from './ctx-reducers';

export const CtxProviders = ({initState, ...props}) => {
  const [state, globalDispatch] = React.useReducer(ctxReducers, initState);

  const value = React.useMemo(() => [state, globalDispatch], [state]);

  return (
    <GLOBAL_CXT.Provider value={value} {...props}>
      {props.children}
    </GLOBAL_CXT.Provider>
  );
};

CtxProviders.displayName = 'GlobalProvider';
CtxProviders.propTypes   = {
  children:  PropTypes.element,
  initState: PropTypes.object
};
