import PropTypes from 'prop-types';
import React     from 'react';

import * as S                from './change-plan-qkn.module.scss';
import { PlanBoxQkn }        from './plan-box-qkn';
import { ModalWrapper }      from '../../modal-wrapper';
import { ProductName }       from '../../../common/product/prod-name';
// import { ToggleSwitch }      from '../../../common/inputs/switch-field';
import { TagLine }           from '../../../common/product/tag-line';
import { Price }             from '../../../common/product/price';
import { useGlobalCtx }      from '../../../../context/ctx-hook';
import * as ProductSelectors from '../../../../context/selectors/product-selectors';
import * as UserSelectors    from '../../../../context/selectors/user-selectors';
import * as RouteHelpers     from '../../../../helpers/route-helper';
import * as TimeHelpers      from '../../../../helpers/time-helpers';
import { getQknPriceWarn }   from '../../../../helpers/skunotices-helpers';



const CA_QKN_PLANS = [
  {sku: '170195', uriName: 'hab', name: 'Business & Personal'},
  {sku: '170194', uriName: 'deluxe', name: 'Quicken Deluxe'},
  {sku: '170193', uriName: 'cash-manager', name: 'Quicken Starter'}
];

const US_QKN_PLANS = [
  {sku: '170166', uriName: 'hab-rpm', name: 'Business & Personal'},
  {sku: '170164', uriName: 'premier', name: 'Premier'},
  {sku: '170165', uriName: 'deluxe', name: 'Deluxe'},
  {sku: '170163', uriName: 'starter', name: 'Starter'}
];

function hasRecentUpgrade(orders = []) {
  if (orders.length === 0) return false;

  return orders
    .filter(({action}) => action === 'UPGRADE')
    .reduce((acm, {createdAt}) => acm ? acm : !TimeHelpers.is30DaysAfter(createdAt), false);
}

const ChangePlanQkn = ({onClose, ctx: {product}}) => {
  const {state} = useGlobalCtx();

  // const [showMac, setShowMac] = React.useState(state.ui.isMac);
  const HISTORY_LIST = UserSelectors.getUserSubHistory(state, product);
  const SUB = ProductSelectors.getSubscription(state, product);

  const {
    // autoRenew,
    expireOn,
    effectiveAt,
    // tierName,
    tierUriName
  } = SUB;

  let PLANS = product.includes('us') ? US_QKN_PLANS : CA_QKN_PLANS;

  const PLAN_INDEX          = PLANS.findIndex(({uriName}) => uriName === tierUriName);
  const IS_30_AFTER_RENEWAL = TimeHelpers.is30DaysAfter(effectiveAt, 30);
  const DISTANCE            = TimeHelpers.getDaysOut(expireOn);
  const HAS_RECENT_UPGRADE  = hasRecentUpgrade(HISTORY_LIST);
  const EXP_WITHIN_6_MONTHS = TimeHelpers.expWithin6MonthsOfToday(expireOn);

  const TIER_NOTICES = getQknPriceWarn(tierUriName, expireOn, state.country);
  let PRICE = '';

  if(state.country === 'us') {
    PRICE = (TIER_NOTICES?.HAS_PRICE_UPDATE)
      ? TIER_NOTICES.PRICE 
      : ProductSelectors.getQknPrice(state, tierUriName);
  }

  if(state.country === 'ca') {
    PRICE = (TIER_NOTICES?.HAS_PRICE_UPDATE)
      ? TIER_NOTICES.PRICE 
      : ProductSelectors.getQknPrice(state, tierUriName);
  }

  return (
    <ModalWrapper heading="Change Plan" onClose={onClose}>
      <div className={S.qknCPWrapper}>
        {/* <div className={S.toggle}>
          Windows
          <ToggleSwitch
            isDark
            id="showMac"
            isActive={showMac}
            handleChange={() => setShowMac(!showMac)}
          />
          Mac
        </div> */}

        <div>
          <div className={S.label}>Current Plan</div>
          <article className={`${S.currentPlan} ${tierUriName}`}>
            <div className={S.currentLeft}>
              {/* <ProductName name={tierName} className={S.name}/> */}
              <ProductName sub={SUB} className={S.name} product='quicken' />

              <TagLine uriName={tierUriName} className={S.tag}/>
            </div>

            <Price
              renewalFrequency="P1Y"
              price={PRICE}
              className={S.currentPrice}
            />

            <div className={S.currentRight}>
              <button
                className={S.inactiveBtn}
                disabled={!EXP_WITHIN_6_MONTHS}
                data-testid="BTN_CURRENT_PLAN"
              >{(EXP_WITHIN_6_MONTHS) ? 'RENEW NOW' : 'CURRENT PLAN'}</button>
              {(EXP_WITHIN_6_MONTHS) && (
                <a
                  className={S.extendPlan}
                  href={RouteHelpers.getEStoreRenewal(state.country)}
                  data-testid="LINK_EXTEND_MEMBERSHIP"
                >Extend Membership</a>
              )}
            </div>
          </article>
        </div>

        <div className={S.dash}/>

        <div>
          <div className={S.label}>Other Options</div>
          <div className={S.optionArea}>
            {PLANS.map(({uriName, sku, name}, i) => {
              if (uriName === tierUriName) return null;
              return (
                <PlanBoxQkn
                  key={sku}
                  name={name}
                  sku={sku}
                  country={state.country}
                  uriName={uriName}
                  action={PLAN_INDEX > i ? 'UPGRADE' : 'DOWNGRADE'}
                  hasRecentUpgrade={HAS_RECENT_UPGRADE}
                  is30After={IS_30_AFTER_RENEWAL}
                  daysOut={DISTANCE}
                />
              );
            })}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};


ChangePlanQkn.displayName = 'ChangePlanQkn';
ChangePlanQkn.propTypes   = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.shape({
    product: PropTypes.string.isRequired
  })
};

export { ChangePlanQkn };
