import React from 'react';
import * as S from './footer-rebrand.module.scss';

import LOGO_RED from '../../../assets/imgs/brand/rebrand-square-logo-red.svg';
import LOGO_BLURPLE from '../../../assets/imgs/brand/rebrand-square-logo-blurple.svg';
import LOGO_QKN from '../../../assets/imgs/brand/logo-quicken.svg';
import APPLE_APPSTORE from '../../../assets/imgs/third-party/apple-appstore-badge.svg';
import GOOGLE_PLAYSTORE from '../../../assets/imgs/third-party/googleplay-badge.svg';

const Footer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleFootnoesClick = React.useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const curYear = new Date().getFullYear();

  return (
    <footer>
      <div className={S.top}>
        <div className={S.left}>
          <img src={LOGO_QKN} alt="Quicken Logo Blurple" />
          {/* <img src="//images.ctfassets.net/hfxittkcm76w/3jtNXELUGPZxBbxILQQk4B/8b615d15dd65ea979ca19263fbe7b791/Quicken_Logo_Blue.svg" alt="Quicken Logo Blue" /> */}
          <a href="https://youtube.com/quicken">
            <span className={S.socialLink}>
              <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.4523 2.71615C18.4523 2.71615 18.2702 1.48217 17.7097 0.940341C16.9998 0.22722 16.2062 0.223724 15.8422 0.181776C13.2358 -1.00012e-07 9.3226 0 9.3226 0H9.31531C9.31531 0 5.40208 -1.00012e-07 2.79569 0.181776C2.43166 0.223724 1.6381 0.22722 0.928255 0.940341C0.367662 1.48217 0.189291 2.71615 0.189291 2.71615C0.189291 2.71615 0 4.16686 0 5.61408V6.97041C0 8.41762 0.185651 9.86833 0.185651 9.86833C0.185651 9.86833 0.367662 11.1023 0.924615 11.6441C1.63446 12.3573 2.56635 12.3328 2.98134 12.4097C4.47383 12.546 9.31895 12.588 9.31895 12.588C9.31895 12.588 13.2358 12.581 15.8422 12.4027C16.2062 12.3608 16.9998 12.3573 17.7097 11.6441C18.2702 11.1023 18.4523 9.86833 18.4523 9.86833C18.4523 9.86833 18.6379 8.42112 18.6379 6.97041V5.61408C18.6379 4.16686 18.4523 2.71615 18.4523 2.71615ZM7.39328 8.61688V3.58658L12.4277 6.11047L7.39328 8.61688Z" fill="#471CFF" className={S.socialSvg}></path>
              </svg>
            </span>
          </a>
          <a href="https://www.facebook.com/Quicken">
            <span className={S.socialLink}>
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.55 2.54403H5.85C5.35293 2.54403 4.95 2.91649 4.95 3.37593V5.87162H8.55C8.65233 5.86952 8.74944 5.91355 8.81028 5.98967C8.87112 6.06578 8.88822 6.16445 8.856 6.25429L8.19 8.08447C8.12862 8.25243 7.95879 8.36598 7.767 8.36731H4.95V14.6065C4.95 14.8362 4.74849 15.0225 4.5 15.0225H2.25C2.00147 15.0225 1.8 14.8362 1.8 14.6065V8.36731H0.45C0.201474 8.36731 0 8.18105 0 7.95136V6.28757C0 6.05788 0.201474 5.87162 0.45 5.87162H1.8V3.37593C1.8 1.53815 3.41181 0.0483398 5.4 0.0483398H8.55C8.79849 0.0483398 9 0.234568 9 0.464288V2.12808C9 2.3578 8.79849 2.54403 8.55 2.54403Z" fill="#471CFF" className={S.socialSvg}></path>
              </svg>
            </span>
          </a>
          <a href="https://twitter.com/quicken">
            <span className={S.socialLink}>
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.0397233 0.0625L6.33016 8.52376L0 15.4031H1.42466L6.96671 9.38013L11.4445 15.4031H16.2927L9.64835 6.46593L15.5404 0.0625H14.1157L9.0118 5.60955L4.88791 0.0625H0.0397233ZM2.1348 1.11819H4.36207L14.1973 14.3473H11.97L2.1348 1.11819Z" fill="#471CFF" className={S.socialSvg}></path>
                <path d="M2.1348 1.11819H4.36207L14.1973 14.3473H11.97L2.1348 1.11819Z" fill="#471CFF" className={S.socialSvg}></path>
              </svg>
            </span>
          </a>

          <a href="https://www.linkedin.com/company/quicken-inc.">
            <span className={S.socialLink}>
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.3353 15.4015H12.6698V11.2248C12.6698 10.2288 12.6495 8.947 11.2808 8.947C9.891 8.947 9.67875 10.0308 9.67875 11.1512V15.4015H7.01325V6.8125H9.57375V7.98325H9.60825C9.966 7.30825 10.836 6.59575 12.1357 6.59575C14.8365 6.59575 15.336 8.37325 15.336 10.687V15.4015H15.3353ZM4.00275 5.63725C3.14475 5.63725 2.4555 4.94275 2.4555 4.0885C2.4555 3.235 3.1455 2.54125 4.00275 2.54125C4.85775 2.54125 5.55075 3.235 5.55075 4.0885C5.55075 4.94275 4.857 5.63725 4.00275 5.63725ZM5.33925 15.4015H2.66625V6.8125H5.33925V15.4015ZM16.6688 0.0625H1.32825C0.594 0.0625 0 0.643 0 1.35925V16.7658C0 17.4827 0.594 18.0625 1.32825 18.0625H16.6665C17.4 18.0625 18 17.4827 18 16.7658V1.35925C18 0.643 17.4 0.0625 16.6665 0.0625H16.6688Z" fill="#471CFF" className={S.socialSvg}></path>
              </svg>
            </span>
          </a>
        </div>

        <div className={S.right}>
          <div className={S.footerMenu}>
            <div className={S.menu}>
              <span className={S.menuHeading}>About Quicken</span>
              <ul>
                <li><a href={`${process.env.REACT_APP_STORE_URL}/about-us/`}>About Us</a></li>
                <li><a href={`${process.env.REACT_APP_STORE_URL}/about-us/careers`}>Careers</a></li>
                <li><a href={`${process.env.REACT_APP_STORE_URL}/about-us/press`}>Press</a></li>
                <li><a href={`${process.env.REACT_APP_STORE_URL}/affiliate`}>Affiliate programs</a></li>
                <li><a href={`${process.env.REACT_APP_STORE_URL}/canada/?cc_bypass`}>Quicken Canada</a></li>
              </ul>
            </div>
            <div className={S.menu}>
              <span className={S.menuHeading}>Sign In</span>
              <ul>
                <li><a href={`${process.env.REACT_APP_SIMP_APP}login/`}>Quicken Simplifi</a></li>
                <li><a href="https://app.quicken.com/login/">Quicken Classic</a></li>
                <li><a href="https://investing.quicken.com/">Quicken Investing</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={S.middle}>
        <div className={S.left}>
          <span 
            role="link"
            className={S.footnotesToggle}
            onClick={handleFootnoesClick }
            onKeyDown={(e) => {
              if (e.key === 'Escape') { handleFootnoesClick();}
            }}
            tabIndex={0}
          >* Footnotes and disclaimers</span>
          {isOpen && (
            
            <div id={S.footnotesText} className={S.footnotesText}>
              <p><b>Monitoring alerts, data downloads, and feature updates are available through the end of your membership term.</b> Online services require internet access. Third-party terms and additional fees may apply. Phone support, online features, and other services vary and are subject to change. 14,500+ participating financial institutions as of October 1, 2018.</p>
              <p><b>Standard message and data rates may apply for sync, e-mail and text alerts.</b> Visit <a href={`${process.env.SIMP_APP}/go/apps`}>https://www.quicken.com/go/apps</a> for details. Quicken App is compatible with iPad, iPhone, iPod Touch, Android phones and tablets. Not all Quicken desktop features are available in the App. The App is a companion app and will work only with Quicken 2015 and above desktop products.</p>
              <ol>
                <li>
                  <p><b>Purchase entitles you to Quicken for the term of your membership (depending upon length of membership purchased), starting at purchase</b>. Full payment is charged to your card immediately. At the end of the membership period, membership will automatically renew every year and you will be charged the then-current price (prices subject to change). You may cancel before renewal date. For full details, consult the Quicken Membership Agreement. You can manage your subscription at your My Account page.</p>
                </li>
              </ol>
              <p><b>If you are redeeming the free Quicken Starter product </b>through the purchase of TurboTax, and you are currently in an active Quicken subscription and not within 6 months of your renewal date, you will have to return to this page within 6 months of renewal in order to redeem. Additionally, <b>note that if you are currently subscribed to Quicken Deluxe, Quicken Premier or Quicken Home &amp; Business</b>, by redeeming the free Quicken Starter product, you will lose some of the features that are associated with your current higher-tiered product.</p>
              <p><b>Quicken for Windows imports data from Quicken for Windows 2010 or newer, Microsoft Money 2008 and 2009 (for Deluxe and higher). Quicken for Mac imports data from Quicken for Windows 2010 or newer, Quicken for Mac 2015 or newer, Quicken for Mac 2007, Quicken Essentials for Mac, Banktivity.</b></p>
              <p><b>30-day money back guarantee: If you’re not satisfied, return this product to Quicken within 30 days of purchase with your dated receipt for a full refund of the purchase price less shipping and handling fees. See </b><a href="https://www.quicken.com/30dayguarantee"><b>https://www.quicken.com/30dayguarantee</b></a><b> for full details and instructions.</b></p>
              <p><b>Quicken for Mac software and the Quicken App are not designed to function outside the U.S.</b></p>
              <p><b>The VantageScore provided under the offer described here uses a proprietary credit scoring model designed by VantageScore Solutions, LLC. There are numerous other credit scores and models in the marketplace, including different VantageScores. Please keep in mind third parties may use a different credit score when evaluating your creditworthiness. Also, third parties will take into consideration items other than your credit score or information found in your credit file, such as your income.</b></p>
              <p><b>VantageScore®, Equifax®, Experian® and TransUnion® are registered trademarks of their respective owners.</b></p>
              <p><b>All 2018 and newer versions of Quicken entitle users to 5GB of free Dropbox storage while subscription is in effect.</b></p>
              <p><b>EQUIFAX is a registered trademark of Equifax Inc. The other Equifax marks used herein are trademarks of Equifax Inc. Other product and company names mentioned herein are property of their respective owners.</b></p>
              <p><b>Portfolio tracking included with Quicken Premier and Quicken Home &amp; Business on Windows.</b></p>
              <p><b>Payment links are only available for Quicken Home &amp; Business.</b></p>
              <p><b>Certain features not supported on mobile apps.</b></p>
              <p><b>Illustrations © Adam Simpson. All rights reserved.</b></p>
              <p><b>© {curYear} Quicken Inc. All rights reserved. Quicken is a registered trademark of Rocket Mortgage, LLC, used under license.</b></p>
            </div>
          )}


          <span className={S.dnsLink}>
            <a className={S.ctaLink} href={`${process.env.REACT_APP_STORE_URL}/privacy/us/ccpa/opt-out`}>Do not sell my information</a>
          </span>

        </div>
        <div className={S.right}>
          <div className={S.downloadTitle}>Download Quicken Simplifi app</div>
          <div className={S.downloadIcon}>
            {/* <img className={S.qIcon} src="//images.ctfassets.net/hfxittkcm76w/6GyaEkyPxl0Bh0RUGRlSZW/b780734768e1fa09f6b120a2ad7c534a/SimplifiIcon.svg" alt="Quicken Simplifi Icon" /> */}
            <img className={S.qIcon} src={LOGO_BLURPLE} alt="Quicken Simplifi Icon" />

            
            <a href="https://simplifiweb.onelink.me/iczF/ug74aptt">
              <img className={S.appstoreIcon} src="//images.ctfassets.net/hfxittkcm76w/35dlpmCAPHHRBi4s7CNWuE/52f26a123c737aeb1f21cb43e9101a60/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Quicken Download From Apple App Store Link" />
            </a>
            <a href="https://simplifiweb.onelink.me/iczF/qmx1f07k">
              <img className={S.appstoreIcon} src="//images.ctfassets.net/hfxittkcm76w/19MMaefqgoIDuSfY7rve9H/ecf281ae6c552c47af55e754b9c37921/googleplay-badge.svg" alt="Google Play Badge" />
            </a>
          </div>
          <div className={S.downloadTitle}>Download the Quicken Classic companion app</div>
          <div className={S.downloadIcon}>
            {/* <img className={S.qIcon} src="//images.ctfassets.net/hfxittkcm76w/2LBjgUMVKfb1wNppuVRrBD/afeb42f38f5d516028262baf37d17b3f/QuickenIcon.svg" alt="Quicken Icon" /> */}
            <img className={S.qIcon} src={LOGO_RED} alt="Quicken Icon" />
            <a href="https://quickenclassic.onelink.me/lfKk/f9nquyz5">
              {/* <img className={S.appstoreIcon} src="//images.ctfassets.net/hfxittkcm76w/35dlpmCAPHHRBi4s7CNWuE/52f26a123c737aeb1f21cb43e9101a60/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="Quicken Download From Apple App Store Link" /> */}
              <img className={S.appstoreIcon} src={APPLE_APPSTORE} alt="Quicken Download From Apple App Store Link" />
            </a>
            <a href="https://quickenclassic.onelink.me/lfKk/dtp1224g">
              {/* <img className={S.appstoreIcon} src="//images.ctfassets.net/hfxittkcm76w/19MMaefqgoIDuSfY7rve9H/ecf281ae6c552c47af55e754b9c37921/googleplay-badge.svg" alt="Google Play Badge" /> */}
              <img className={S.appstoreIcon} src={GOOGLE_PLAYSTORE} alt="Google Play Badge" />
            </a>
          </div>
        </div>
      </div>
      <div className={S.bottom}>
        <div className={S.left}>
          ©{curYear} Quicken. All rights reserved.
        </div>
        <div className={S.right}>
          <span className={S.legalLink}>
            <a className={S.ctaLink} href={`${process.env.REACT_APP_STORE_URL}/privacy`}>Privacy Policy</a>
          </span>
          <span className={S.legalLink}>
            <a className={S.ctaLink} href={`${process.env.REACT_APP_STORE_URL}/terms`}>Billing Terms &amp; Conditions</a>
          </span>
        </div>
      </div>
    </footer>
  );
};

Footer.displayName = 'Footer';

export { Footer };
