import PropTypes from 'prop-types';
import React     from 'react';

import * as S                  from './name-modal.module.scss';
import { ModalBtnRow }         from '../modal-btn-row';
import { ModalWrapper }        from '../modal-wrapper';
import { TextField }           from '../../common/inputs/text-field';
import { Message }             from '../../common/message';
import * as Actions            from '../../../context/ctx-actions';
import { useGlobalCtx }        from '../../../context/ctx-hook';
import * as MixPanel           from '../../../helpers/mixpanel';
import { checkForAnyErrors }   from '../../../helpers/pure-functions';
import * as Validations        from '../../../helpers/validations';
import { callUpdateUserNames } from '../../../network/quicken-id-calls';

export const FORM_NAME_VALIDATIONS = {
  first: Validations.validateFirstName,
  last:  Validations.validateLastName
};


const NameModal = ({onClose}) => {
  const {
    globalDispatch, state: {
      userId,
      isDisabled,
      personalInfo: {firstName, lastName}
    }
  } = useGlobalCtx();

  const [form, setForm] = React.useState({
    values: {first: firstName, last: lastName},
    errors: {first: '', last: '', form: ''}
  });

  const handleChange = React.useCallback(({type, target: {name, value}}) => {
    if (type === 'blur') value = value.trim();
    setForm(({values, errors}) => ({
      values: {...values, [name]: value},
      errors: {...errors, [name]: FORM_NAME_VALIDATIONS[name](value)}
    }));
  }, []);

  const clearFormError = React.useCallback(() => {
    setForm(({values, errors}) => ({
      values: {...values},
      errors: {...errors, form: ''}
    }));
  }, []);

  const handleSubmit = React.useCallback(async e => {
    e.preventDefault();
    const firstName = e.target.elements.first.value.trim();
    const lastName  = e.target.elements.last.value.trim();

    const ERROR_OBJ = {
      firstName: FORM_NAME_VALIDATIONS.first(firstName),
      lastName:  FORM_NAME_VALIDATIONS.last(lastName),
      form:      ''
    };

    setForm({
      values: {first: firstName, last: lastName},
      errors: ERROR_OBJ
    });

    if (!checkForAnyErrors(Object.values(ERROR_OBJ))) {
      try {
        globalDispatch(Actions.setCtxField('isDisabled', true));

        await callUpdateUserNames(userId, firstName, lastName);

        MixPanel.track(MixPanel.MIX_PANEL_IDS.NAME);
        globalDispatch(Actions.mergeMainStateObj({
          isDisabled:   false,
          personalInfo: {firstName, lastName},
          alert:        {
            type:     'SUCCESS',
            messages: ['Name has successfully changed']
          }
        }));

        onClose();
      } catch (err) {
        MixPanel.error(err, MixPanel.MIX_PANEL_IDS.NAME);
        setForm(({values}) => ({
          values: {...values},
          errors: {...ERROR_OBJ, form: 'GENERIC_ERROR'}
        }));

        globalDispatch(Actions.setCtxField('isDisabled', false));
      }
    }
  }, [globalDispatch, userId, onClose]);

  const HAS_ERROR = React.useMemo(
    () => Object.values(form.errors).some(v => v !== ''),
    [form.errors]
  );

  return (
    <ModalWrapper heading="Change Name" onClose={onClose}>
      <div className={S.modalBody}>
        {form.errors.form && <Message text={form.errors.form} type="ERROR"/>}

        <form onSubmit={handleSubmit}>
          <div className={S.nameForm}>

            <div className={S.fNameContent}>
              <h3 className={S.contentLabel}>Current First Name</h3>
              <p
                className={S.contentText}
                data-testid="NAME_FORM_FIRST_NAME"
              >{firstName === '' ? 'N/A' : firstName}</p>
            </div>


            <TextField
              label="First Name"
              name="first"
              id="NAME_FORM_FIRST_NAME"
              placeholder="Enter First Name"
              className={S.fNameInput}
              error={form.errors.first}
              value={form.values.first}
              disabled={isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              onFocus={clearFormError}
            />


            <div className={S.lNameContent}>
              <h3 className={S.contentLabel}>Current Last Name</h3>
              <p
                className={S.contentText}
                data-testid="NAME_FORM_LAST_NAME"
              >{lastName === '' ? 'N/A' : lastName}</p>
            </div>


            <TextField
              label="Last Name"
              name="last"
              id="NAME_FORM_LAST_NAME"
              placeholder="Enter last Name"
              className={S.lNameInput}
              error={form.errors.last}
              value={form.values.last}
              disabled={isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              onFocus={clearFormError}
            />
          </div>

          <ModalBtnRow
            form="NAME_FORM"
            isDisabled={isDisabled}
            hasError={HAS_ERROR}
            onCancel={onClose}
          />
        </form>
      </div>
    </ModalWrapper>
  );
};

NameModal.displayName = 'NameModal';
NameModal.propTypes   = {
  onClose: PropTypes.func.isRequired
};

export { NameModal };
