import React     from 'react';
import PropTypes from 'prop-types';
import * as S    from '../cancel-ar-modal/survey-modal/survey-modal.module.scss';

import { DropBoxOffer }      from './dropbox-offer';
import { OfferExpired }      from './dropbox-expired';
import { ModalWrapper }      from '../modal-wrapper';
import { Spinner }                            from '../../common/spinner/spinner';
import { mergeMainStateObj, setCtxNestedObj } from '../../../context/ctx-actions';
import { callGetPromotions }                  from '../../../network/network-calls';
import { useGlobalCtx }      from '../../../context/ctx-hook';

const getPromotionsData = async (globalDispatch) => {
  try {
    const NOW    = new Date().toISOString();
    const {data} = await callGetPromotions();

    const VALID_PROMOS = data.filter(promotion =>
      promotion?.coupon?.vendor.toLowerCase() === 'dropbox' && (NOW >= promotion?.notVisibleBefore))
      .map(({createdAt: createdAt, coupon: {promotionName: name, couponCode: code}}) => ({createdAt, name, code}));

    globalDispatch(mergeMainStateObj({
      promotions: VALID_PROMOS,
      modal:      {isLoading: false}
    }));
  } catch (err) {
    globalDispatch(mergeMainStateObj({
      promotions: [],
      modal:      {isLoading: false}
    }));
  }
};


const DropboxModal = ({onClose}) => {
  const {globalDispatch, state: {promotions, modal: {isLoading}}} = useGlobalCtx();

  React.useEffect(() => {
    if (promotions === null) getPromotionsData(globalDispatch);
    else globalDispatch(setCtxNestedObj('modal', {isLoading: false}));
  }, [globalDispatch, promotions]);

  if (isLoading) {
    return (
      <ModalWrapper heading="Dropbox Offer" onClose={onClose}>
        <div className={S.modalBody}>
          <Spinner/>
        </div>
      </ModalWrapper>
    );
  }

  return promotions?.length > 0
    ? <DropBoxOffer onClose={onClose} {...promotions[0]} />
    : <OfferExpired onClose={onClose}/>;
};

DropboxModal.displayName = 'DropboxModal';

DropboxModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.object.isRequired
};

export { DropboxModal };
