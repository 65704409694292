import React from 'react';

import { ctxReducers } from './ctx-reducers';
import { GLOBAL_CXT }  from './ctx-constants';


export const useGlobalCtx = () => {
  const context = React.useContext(GLOBAL_CXT);
  if (!context) {
    throw Error(`${useGlobalCtx.name} must be used within a ${ctxReducers.name}`);
  }


  const [state, globalDispatch] = context;
  return {state, globalDispatch};
};
