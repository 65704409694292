import PropTypes from 'prop-types';
import React     from 'react';

import { BmTopToggle }       from './info-bm/bm-top-up';
import * as S                from './sub-action-row.module.scss';
import * as Actions          from '../../../context/ctx-actions';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import * as ProductSelectors from '../../../context/selectors/product-selectors';
import { getProductsPaymentMethod } from '../../../context/selectors/user-selectors';
import { YEARLY_REGEX }      from '../../../helpers/constants/regex';
import { MODALS }            from '../../../helpers/constants/state-constants';
import { BM_ACTIONS }        from '../../../helpers/constants/constants';
import { useAutoRenew }      from '../../../helpers/hooks/use-auto-renew';
import * as Tealium          from '../../../helpers/tealium';
import { getShortDate }      from '../../../helpers/time-helpers';
import { checkRebrandName }  from '../../../helpers/pure-functions';
import * as NetworkCalls     from '../../../network/network-calls';

const SubActionRow = ({product, onClose}) => {
  const {globalDispatch, state} = useGlobalCtx();

  const COUNTRY     = state.country;
  const IS_DISABLED = state.modal.isDisabled;
  const IS_BM       = product === 'billmanager';

  const {
    active           = false,
    autoRenew        = false,
    tierUriName      = '',
    sku              = '',
    renewalFrequency = ''
  } = ProductSelectors.getSubscription(state, product) ?? {};

  const IS_MONTHLY    = !YEARLY_REGEX.test(renewalFrequency);
  const HAS_PAYMENT   = getProductsPaymentMethod(product, state);
  const QKN_SUB       = ProductSelectors.getSubscription(state, `quicken-${COUNTRY}`);
  const QKN_IS_ACTIVE = QKN_SUB?.active ?? false;

  const CAN_CHANGE_PLAN = React.useMemo(() => {
    if (IS_BM && QKN_IS_ACTIVE) return true;
    else if ((product === 'quicken-online-backup')) return false;
    else if ((product === 'quicken-estate-manager')) return false;
    else if ((product === 'acme' && IS_MONTHLY)) return true;
    else return product.includes('quicken');
  }, [product, IS_MONTHLY, IS_BM, QKN_IS_ACTIVE]);

  const handleRestart = async () => {
    if (IS_BM) {
      const PLAN = tierUriName;
      try {
        globalDispatch(Actions.setCtxField('isDisabled', true));

        if (HAS_PAYMENT) {
          const {data} = await NetworkCalls.callPostChangeTier(PLAN, sku, BM_ACTIONS.CREATE);
          // Tealium.tealiumLink(Tealium.BMTealiumEvent(state, data));
          Tealium.gtmLink(Tealium.BMTealiumEvent(state, data));

          globalDispatch(Actions.mergeMainStateObj({
            subscriptions: ProductSelectors.mergeSubscription(state, product, data.postState),
            alert:         {
              type:     'SUCCESS',
              messages: [
                'Your membership is now set up!',
                `Your ${checkRebrandName(data.postState.productName)} renewal date is ${getShortDate(data.postState.expireOn)}`
              ]
            }
          }));

          onClose();
        } else {
          globalDispatch(Actions.setCtxNestedObj('modal', {
            type:       MODALS.UPDATE_PAYMENT,
            isLoading:  true,
            hasError:   false,
            isDisabled: false,
            ctx:        {product, plan: PLAN, paymentOnly: false}
          }));
        }
      } catch (err) {
        console.log(err);
      }
    } else if (product === 'acme') {
      globalDispatch(Actions.setCtxNestedObj('modal', {
        type:       MODALS.REACTIVATE,
        isLoading:  true,
        hasError:   false,
        isDisabled: false,
        ctx:        {product}
      }));
    } else if (product.includes('quicken')) {
      globalDispatch(Actions.setCtxNestedObj('modal', {
        type:       MODALS.CHANGE_PLAN,
        isLoading:  true,
        hasError:   false,
        isDisabled: false,
        ctx:        {product}
      }));
    }
  };

  const handleAROn      = useAutoRenew(product, true);
  const handleOpenModal = React.useCallback(
    modal => globalDispatch(Actions.setCtxNestedObj('modal', {
      type:       modal,
      ctx:        {product},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch, product]
  );

  return (
    <div className={S.btnRow}>
      {!active && (
        <button
          type="button"
          data-testid="BTN_RESTART_SUB"
          className={S.linkBtn}
          onClick={handleRestart}
          disabled={IS_DISABLED}
        >Buy Now</button>
      )}

      {active && CAN_CHANGE_PLAN && (
        <button
          type="button"
          data-testid="BTN_CHANGE_PLAN"
          className={S.linkBtn}
          disabled={IS_DISABLED}
          onClick={() => handleOpenModal(MODALS.CHANGE_PLAN)}
        >Change Plan</button>
      )}

      {active && (
        <>
          {HAS_PAYMENT && autoRenew && (
            <button
              type="button"
              data-testid="BTN_CANCEL_SUB"
              className={S.linkBtn}
              disabled={IS_DISABLED}
              onClick={() => handleOpenModal(MODALS.CANCEL_AR)}
            >Cancel Renewal</button>
          )}

          {HAS_PAYMENT && !autoRenew && (
            <button
              data-testid="BTN_AR_ON_SUB"
              type="button"
              className={S.linkBtn}
              onClick={handleAROn}
              disabled={IS_DISABLED}
            >Resume Subscription</button>
          )}

          {!HAS_PAYMENT && (
            <button
              type="button"
              data-testid="BTN_ADD_BILLING"
              className={S.linkBtn}
              disabled={IS_DISABLED}
              onClick={() => handleOpenModal(MODALS.UPDATE_PAYMENT)}
            >{autoRenew ? 'Add Billing' : 'Resume Subscription'}</button>
          )}
        </>
      )}
      {product === 'billmanager' && <BmTopToggle/>}
    </div>
  );
};

SubActionRow.displayName = 'SubActionRow';
SubActionRow.propTypes   = {
  product: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export { SubActionRow };
