import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import * as S  from './spinner.module.scss';
import Loading from '../../../assets/imgs/loading.svg';

const Spinner = ({className}) => (
  <div className={ClassNames(S.loadingWrapper, className)}>
    <img
      src={Loading}
      alt="Loading Circle"
      className={S.loadingCircle}
    />
  </div>
);

Spinner.displayName = 'Spinner';
Spinner.propTypes = {
  className: PropTypes.string
};

export { Spinner };
