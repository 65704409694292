import PropTypes      from 'prop-types';
import React          from 'react';
import { useHistory } from 'react-router';

import * as S                   from './sign-out-modal.module.scss';
import { ModalBtnRow }          from '../modal-btn-row';
import { ModalWrapper }         from '../modal-wrapper';
import { Message }              from '../../common/message';
import * as Actions             from '../../../context/ctx-actions';
import { useGlobalCtx }         from '../../../context/ctx-hook';
import { ROUTES }               from '../../../helpers/constants/constants';
import * as MixPanel            from '../../../helpers/mixpanel';
import { callSignOutOfDevices } from '../../../network/quicken-id-calls';


const SignOutModal = ({onClose}) => {
  const {globalDispatch, state: {isDisabled}} = useGlobalCtx();
  const {push}                                = useHistory();

  const [hasError, setHasError] = React.useState(false);

  const handleSubmit = React.useCallback(async e => {
    e.preventDefault();
    try {
      globalDispatch(Actions.setCtxField('isDisabled', true));

      await callSignOutOfDevices();
      MixPanel.track(MixPanel.MIX_PANEL_IDS.SIGN_OUT);

      globalDispatch(Actions.mergeMainStateObj({
        isDisabled: false,
        alert: {
          type:     'SUCCESS',
          messages: [
            'Signing out of all devices.',
            'Redirecting to login page'
          ]
        }
      }));

      setTimeout(() => push(ROUTES.LOGOUT), 2000);

      onClose();
    } catch (err) {
      MixPanel.error(err, MixPanel.MIX_PANEL_IDS.SIGN_OUT);
      setHasError(true);

      globalDispatch(Actions.setCtxField('isDisabled', false));
    }
  }, [globalDispatch, onClose, push]);

  return (
    <ModalWrapper heading="Change Security Codes" onClose={onClose}>
      <div className={S.modalBody}>
        {hasError && <Message type="GENERIC_ERROR"/>}

        <form onSubmit={handleSubmit} className={S.signOutForm}>
          <p className={S.contentText}>Are you sure you want to sign out of Quicken on all devices?</p>
          <p className={S.bold}>You will be required to re-login upon completion</p>

          <ModalBtnRow
            form="SIGN_OUT_FORM"
            isDisabled={isDisabled}
            hasError={false}
            onCancel={onClose}
          />
        </form>
      </div>
    </ModalWrapper>
  );
};

SignOutModal.displayName = 'SignOutModal';
SignOutModal.propTypes   = {
  onClose: PropTypes.func.isRequired
};

export { SignOutModal };
