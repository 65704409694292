// example config: https://www.npmjs.com/package/react-gtm-module
export const getGtmConfig = () => {
  const IS_PROD = (`${process.env.REACT_APP_ENV}` === 'production' ? true : false);

  const devConfig = {
    gtmId: 'GTM-P7RRF6T2',
    // auth: 'EaCYwaQVPwWEnncX0okJGQ',
    // preview: 'env-171'
  };

  const prodConfig = {
    gtmId: 'GTM-P28GM6N',
  };

  return IS_PROD ? prodConfig : devConfig;
};