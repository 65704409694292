import { stringify, parse }        from 'query-string';
import React                       from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { MainPage }     from '../pages/main-page';
import { LogOut }       from '../pages/authentication/logout';
import { ROUTES }       from '../../helpers/constants/constants';
import { MODALS }       from '../../helpers/constants/state-constants';
import { useGlobalCtx } from '../../context/ctx-hook';

const AuthRoutes = () => {
  const {state: {country}} = useGlobalCtx();

  return (
    <Switch>
      <Route exact path={ROUTES.MAIN} component={MainPage}/>
      <Route exact path={ROUTES.SUBSCRIPTIONS} component={MainPage}/>
      <Route exact path={ROUTES.SECURITY} component={MainPage}/>
      <Route exact path={ROUTES.PERSONAL_INFO} component={MainPage}/>
      <Route exact path={ROUTES.LOGOUT} component={LogOut}/>
      <Route exact path={ROUTES.OLD_PAYMENT} component={({location}) => {
        const PARSED = parse(location.search);
        const QS     = stringify({
          ...PARSED,
          modal: MODALS.UPDATE_PAYMENT,
          ctx:   JSON.stringify({
            product:     `quicken-${country}`,
            paymentOnly: false
          }),
          isLoading: true,
          hasError: false,
          isDisabled: false,
        });
        return <Redirect to={`${ROUTES.MAIN}?${QS}`}/>;
      }}/>
      <Route path="/" component={() => <Redirect to={`${ROUTES.MAIN}${window.location.search}`}/>}/>
    </Switch>
  );
};

AuthRoutes.PageLayout = 'AuthRoutes';
export { AuthRoutes };
