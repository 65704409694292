import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import * as S         from './tag-line.module.scss';
import { getTagLine } from '../../../helpers/pure-functions';


const TagLine = ({className, uriName}) => {
  const TAG_LINE = React.useMemo(() => getTagLine(uriName), [uriName]);

  return (
    <div className={ClassNames(S.tagLine, className)}>{TAG_LINE}</div>
  );
};

TagLine.displayName = 'TagLine';
TagLine.propTypes   = {
  uriName:   PropTypes.string.isRequired,
  className: PropTypes.string
};

export { TagLine };
