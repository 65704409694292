import React       from 'react';
import { NavLink } from 'react-router-dom';

import * as S              from './nav-list.module.scss';
import USER_ICON           from '../../assets/imgs/nav/user-icon.svg';
import SUBSCRIPTION_ICON   from '../../assets/imgs/nav/subscriptions-icon.svg';
import OVERVIEW_ICON       from '../../assets/imgs/nav/overview-icon.svg';
import LOCK_ICON           from '../../assets/imgs/nav/lock-icon.svg';
import DROPBOX_ICON        from '../../assets/imgs/nav/dropbox.svg';
import ICON_BUG            from '../../assets/imgs/icons/icon-bug.svg';
import { useGlobalCtx }    from '../../context/ctx-hook';
import { setCtxNestedObj } from '../../context/ctx-actions';
import { MODALS }          from '../../helpers/constants/state-constants';
import { ROUTES }          from '../../helpers/constants/constants';


const NavList = () => {
  const {
    globalDispatch,
    state: {
      ui: {hasDBOffer, hasSubscriptions},
      debug: {isVisible, isAvailable}
    }
  } = useGlobalCtx();

  const SEARCH = window.location.search;

  const handleDropbox = React.useCallback(() =>
    globalDispatch(setCtxNestedObj('modal', {
      type: MODALS.DROPBOX,
      ctx: {},
      isLoading: true,
      hasError: false,
    })),
  [globalDispatch]
  );

  const handleCloseNav = React.useCallback(
    () => globalDispatch(setCtxNestedObj('ui', {navListOpen: false})),
    [globalDispatch]
  );

  const handleToggleDebug = React.useCallback(
    () => globalDispatch(setCtxNestedObj('debug', {isVisible: !isVisible})),
    [globalDispatch, isVisible]
  );

  return (
    <ul>
      <li>
        <NavLink to={ROUTES.MAIN + SEARCH} activeClassName={S.active} className={S.navTab} onClick={handleCloseNav}>
          <img src={OVERVIEW_ICON} role="presentation" alt="Grid"/>
          Overview
        </NavLink>
      </li>

      {hasSubscriptions && (
        <li>
          <NavLink to={ROUTES.SUBSCRIPTIONS + SEARCH} activeClassName={S.active} className={S.navTab} onClick={handleCloseNav}>
            <img src={SUBSCRIPTION_ICON} role="presentation" alt="Box"/>
            Subscriptions
          </NavLink>
        </li>
      )}

      <li>
        <NavLink to={ROUTES.SECURITY + SEARCH} activeClassName={S.active} className={S.navTab} onClick={handleCloseNav}>
          <img src={LOCK_ICON} role="presentation" alt="lock"/>
          Sign In & Security
        </NavLink>
      </li>

      <li>
        <NavLink to={ROUTES.PERSONAL_INFO + SEARCH} activeClassName={S.active} className={S.navTab} onClick={handleCloseNav}>
          <img src={USER_ICON} role="presentation" alt="user"/>
          Personal Info
        </NavLink>
      </li>

      {hasDBOffer && (
        <li>
          <button
            type="button"
            className={S.navTab}
            onClick={handleDropbox}
          >
            <img src={DROPBOX_ICON} alt="Dropbox logo"/>
            Dropbox Offer
          </button>
        </li>
      )}

      {isAvailable && (
        <li>
          <button
            type="button"
            className={S.navTab}
            onClick={handleToggleDebug}
          >
            <img src={ICON_BUG} alt="Bug Icons" />
            Non-prod Debug
          </button>
        </li>
      )}
    </ul>
  );
};

NavList.displayName = 'NavList';

export { NavList };
