import React               from 'react';
import PropTypes           from 'prop-types';
import * as S              from './dropbox-modal.module.scss';
import { ModalWrapper }    from '../modal-wrapper';
import { setCtxNestedObj } from '../../../context/ctx-actions';
import { useGlobalCtx }    from '../../../context/ctx-hook';
import { MODALS }          from '../../../helpers/constants/state-constants';

const DropboxTermsModal = () => {
  const { globalDispatch } = useGlobalCtx();

  const backToOffer = React.useCallback(() =>
    globalDispatch(setCtxNestedObj('modal', {
      type: MODALS.DROPBOX,
      ctx: {},
      isLoading: true,
      hasError: false,
    })),
  [globalDispatch]
  );

  return (
    <ModalWrapper heading="Offer Terms & Conditions" headerClass={S.dbTermsHeader} onClose={backToOffer}>
      <div className={`${S.modalBody} ${S.dbTermsModal}`}>
        <div className={S.termsContent}>
          <h3>Dropbox and Quicken Offer (100 GB) Supplemental Terms and Conditions</h3>
          <p>Congratulations on purchasing Quicken! As a special “Thank You!” Quicken has teamed up with Dropbox to give you 100 GB of extra Dropbox space (the “Promotional Space”) for twelve months free of charge (the “Offer”). Before claiming your Dropbox space, you should understand the terms and conditions governing this promotion (“Offer Terms”). The Offer Terms are in addition to Dropbox’s standard <a href="https://www.dropbox.com/terms">Terms & Conditions</a>, <a href="https://www.dropbox.com/acceptable_use">Acceptable Use Policy</a>, and <a href="https://www.dropbox.com/privacy">Privacy Policy</a> (our <b>“Standard Terms”</b>) governing your use of the Dropbox services. By claiming the Offer, you agree to these Offer Terms in addition to our Standard Terms. Capitalized terms used here but not defined have the meaning given to them in our Standard Terms.</p>

          <h3>Offer Eligibility</h3>
          <p>This Offer is open to both new and existing Dropbox users who buy Quicken products. This Offer can be redeemed only once per Quicken user. This offer cannot be combined with other promotional storage offers from Quicken for Dropbox. The Offer is personal to you and is non-transferable. Cuba, Iran, North Korea, the region of Crimea including Sevastopol, Russia, Belarus, Donetsk and Luhansk People’s Republics, and Syria, or any other countries where the Dropbox Service is not available, restricted by law, or subject to tax.</p>

          <h3>100 GB of extra Dropbox Space Grant</h3>
          <p>The Promotional Space is in addition to the 2 GB of basic quota given to every Dropbox user, as well as any other space you may have earned from Dropbox by referring friends, completing Getting Started or performing other specified actions (collectively, your ‘Permanent Quota’).  For the avoidance of doubt, your Permanent Quota does not include the Promotional Space. At the end of the twelve-month promotional term, your space allocation will revert back to your Permanent Quota.</p>

          <h3>After the Twelve Month Offer Expires</h3>
          <p>If, at the end of your promotional period, the data you have stored in Dropbox exceeds your Permanent Quota, you will no longer be able to add files to Dropbox and your devices will stop syncing. You can fix this anytime by either deleting files in your Dropbox online until your storage amount falls below your Permanent Quota, or by <a href="https://www.dropbox.com/upgrade">upgrading</a>. If you upgrade, you’ll increase your space allocation and get other advanced features.</p>
          <p>In the event that you choose not to upgrade,  Dropbox will email you at the email address associated with your Dropbox account and ask you to delete or move your files to bring your account within your Permanent Quota. If you fail to do so within a reasonable period of time after Dropbox sends this notice email (at least 60 days), Dropbox reserves the right to suspend or permanently delete your entire account together with all stored data, including data within your Permanent Quota.</p>

          <h3>Software Installation</h3>
          <p>By signing up for a new Dropbox account or signing into your existing Dropbox account, you agree to have the Dropbox client installed on your device including a shortcut on your desktop. The Dropbox installation will occur in the background and the amount of time needed can vary based on your internet connection speed. You are responsible for any internet usage fees associated with the download of the Dropbox client.</p>
        </div>

        
        <div className={S.termsStickyFt}>
          <button
            onClick={backToOffer}
            className={S.dbCloseBtn}
            type="button"
          >Close Window</button>
        </div>
      </div>

    </ModalWrapper>
  );
};

DropboxTermsModal.displayName = 'DropboxModal';

DropboxTermsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  ctx: PropTypes.object.isRequired
};

export { DropboxTermsModal };
