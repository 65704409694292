import React     from 'react';
import PropTypes from 'prop-types';

import * as S            from './simp-mobile.module.scss';
import { RemainingTime } from '../../../common/product/remaining-time';
import { Expiry }        from '../../../common/product/expiry';
import APPLE_APP_ICON    from '../../../../assets/imgs/brand/app-store-black.svg';
import GOOGLE_APP_ICON   from '../../../../assets/imgs/brand/google-play-store.svg';
import * as Constants    from '../../../../helpers/constants/constants';

const getAccountMsg = ({active, autoRenew}) => {
  if (!active) return 'Your Simplifi subscription is no longer active';
  else if (!autoRenew) return 'We’re sorry to see you go! You still have access to all benefits until the expiration date below.';
  else return '';
};

const SimpMobile = ({product, active, autoRenew, expireOn, isInTrialPeriod}) => {
  const IS_APPLE = product === 'acme-apple';
  const STORE    = IS_APPLE ? 'Apple App Store' : 'Google Play Store';
  const MESSAGE  = getAccountMsg({active, autoRenew});

  return (
    <>
      {MESSAGE && <div className={S.productAlert}>{MESSAGE}</div>}
      {isInTrialPeriod && (
        <>
          <h4 className={S.trialHeading}>Free trial</h4>
          <RemainingTime expireOn={expireOn} className={S.trialRemaining} />
        </>
      )}

      <Expiry
        product={product}
        className={S.expiry}
      />

      <span className={S.mobileDash}/>

      <a
        target="_parent"
        className={IS_APPLE ? S.appleIcon : S.googleIcon}
        href={IS_APPLE ? Constants.APP_SUB_PAGE.APPLE : Constants.APP_SUB_PAGE.GOOGLE}
      >
        <img src={IS_APPLE ? APPLE_APP_ICON : GOOGLE_APP_ICON} alt={STORE}/>
      </a>
      <p className={S.subText}>*Manage your subscription at {STORE}</p>
    </>
  );
};

SimpMobile.displayName = 'SimpMobile';
SimpMobile.propTypes   = {
  product:         PropTypes.string.isRequired,
  expireOn:        PropTypes.string.isRequired,
  active:          PropTypes.bool.isRequired,
  autoRenew:       PropTypes.bool.isRequired,
  isInTrialPeriod: PropTypes.bool.isRequired,
  isCancelled:     PropTypes.bool.isRequired
};

export { SimpMobile };
