import Moment from 'moment';

const formatDate = (time, format) =>
  Moment(time).format(format);

export const getFormattedDate = dateTime =>
  dateTime ? formatDate(dateTime, 'MMM DD, YYYY') : null;

export const getFullDate = dateTime =>
  dateTime ? formatDate(dateTime, 'MMMM DD, YYYY') : null;

export const getShortDate = (dateTime, isFullYear) =>
  !dateTime
    ? null
    : formatDate(dateTime, `MM/DD/YY${isFullYear ? 'YY' : ''}`);

export const is30DaysAfter = dateTime =>
  dateTime
    ? Moment().isAfter(Moment(dateTime).add(30, 'd'), 'd')
    : false;

export const isWithin15Days = dateTime =>
  dateTime
    ? Moment().isBetween(Moment(dateTime).subtract(16, 'd'), Moment(dateTime), 'd')
    : false;

export const isWithin45Days = dateTime =>
  dateTime
    ? Moment().isBetween(Moment(dateTime).subtract(46, 'd'), Moment(dateTime), 'd')
    : false;

export const isWithin42Days = dateTime =>
  dateTime
    ? Moment().isBetween(Moment(dateTime).subtract(43, 'd'), Moment(dateTime), 'd')
    : false;

export const isWithin3Days = dateTime =>
  dateTime
    ? Moment().isBetween(Moment(dateTime).subtract(4, 'd'), Moment(dateTime), 'd')
    : false;

export const isWithin30Days = dateTime =>
  dateTime
    ? Moment().isBetween(Moment(dateTime).subtract(30, 'd'), Moment(dateTime), 'd')
    : false;

export const is6MonthsBefore = dateTime =>
  dateTime
    ? Moment().isBefore(Moment(dateTime).subtract(6, 'months'))
    : false;

export const expWithin6MonthsOfToday = dateTime => {
  const today = Moment();
  const sixMonthsFromToday = today.clone().add(6, 'months');
  const isWithinRange = Moment(dateTime).isBetween(today, sixMonthsFromToday, null, '[]');
  return isWithinRange;
};

export const getDate = (dateTime) =>
  !dateTime
    ? null
    : formatDate(dateTime, 'Do');

export const dateIsInFuture = dateTime => Moment(dateTime).isAfter(Moment());

export const daysLeft = dateTime => Moment(dateTime).diff(Moment(), 'days') + 1;
export const monthsLeft = dateTime => Moment(dateTime).diff(Moment(), 'months') + 1;

export const daysSinceSubStart = dateTime => Moment().diff(Moment(dateTime), 'days') + 1;

export const isInBetween = (startDateTime, endDateTime, testDateTime) =>
  Moment(testDateTime).isBetween(Moment(startDateTime), Moment(endDateTime));

export const oneMonthFromToday = () =>
  Moment().add(1, 'M').format('MMM DD, YYYY');

export const getDaysOut = (expireOn) => {
  const IS_6_MO_OUT = is6MonthsBefore(expireOn);
  const IS_45D_OUT = isWithin45Days(expireOn);

  if (IS_6_MO_OUT) return 'FAR';
  else if (!IS_6_MO_OUT && !IS_45D_OUT) return 'MID';
  else if (IS_45D_OUT) return 'NEAR';
  else return 'OVER';
};

export const compareMoments = (a, b) =>{
  return Moment(a).isAfter(Moment(b)) ? -1 : 1;
};