import Axios from 'axios';
import { ROUTES } from '../helpers/constants/constants';
import { deleteCookie } from '../helpers/pure-functions';

export const getBearer = () => `Bearer ${localStorage.getItem('bearer')}`;


//is run on every response error
Axios.interceptors.response.use(
  response => response,
  error => {
    console.error(error);

    if (error.response) {
      const ERROR_LIST = error?.response?.data?.errors || [];
      if (ERROR_LIST.some(({code}) => ['QCS-0500', 'QCS-0401-2'].includes(code))) {
        document.cookie = deleteCookie('qkn_bearer');
        const retainPromo = localStorage.getItem('bmPromos');
        localStorage.clear();
        if(retainPromo) {
          localStorage.setItem('bmPromos', retainPromo);
        }
        
        window.location = ROUTES.LOGIN;
      }
    }

    return Promise.reject(error);
  });
