import React     from 'react';
import PropTypes from 'prop-types';

import * as S                from './info-quicken.module.scss';
import { SubActionRow }      from '../sub-action-row';
import { Expiry }            from '../../../common/product/expiry';
import { Price }             from '../../../common/product/price';
import { ProductName }       from '../../../common/product/prod-name';
import { TagLine }           from '../../../common/product/tag-line';
import { RemainingTime }     from '../../../common/product/remaining-time';
import { useGlobalCtx }      from '../../../../context/ctx-hook';
import { isInBetween }       from '../../../../helpers/time-helpers';
import { CancelledBox }      from '../../../common/product/cancelled-box';
import * as ProductSelectors from '../../../../context/selectors/product-selectors';
import * as UserSelectors    from '../../../../context/selectors/user-selectors';
import { getQknPriceWarn }    from '../../../../helpers/skunotices-helpers';


const InfoQuicken = ({product, onClose}) => {
  const {state} = useGlobalCtx();

  const SUB          = ProductSelectors.getSubscription(state, product);
  // const PRICE        = ProductSelectors.getQknPrice(state, SUB.tierUriName);
  const TIER_NOTICES = getQknPriceWarn(SUB.tierUriName, SUB.expireOn, state.country);
  let PRICE = '';

  if(state.country === 'us') {
    PRICE = (TIER_NOTICES?.HAS_PRICE_UPDATE) 
      ? TIER_NOTICES.PRICE 
      : ProductSelectors.getQknPrice(state, SUB.tierUriName);
  }

  if(state.country === 'ca') {
    PRICE = (TIER_NOTICES?.HAS_PRICE_UPDATE)
      ? TIER_NOTICES.PRICE 
      : ProductSelectors.getQknPrice(state, SUB.tierUriName);
  }

  // const NAME         = ProductSelectors.getProductName(state, product);
  const HAS_PAYMENT  = UserSelectors.getProductsPaymentMethod(product, state);
  const HISTORY_LIST = UserSelectors.getUserSubHistory(state, product);

  const IS_CANCELLED = React.useMemo(
    () =>
      !SUB.active || (!SUB.autoRenew && HISTORY_LIST?.filter(({action, createdAt}) =>
        (action === 'DISABLE_AUTORENEW' && isInBetween(SUB.effectiveAt, SUB.expireOn, createdAt))
      ).length > 0),
    [SUB.autoRenew, SUB.effectiveAt, SUB.expireOn, HISTORY_LIST]
  );


  return (
    <article className={`${S.card} ${SUB.tierUriName}`}>
      <div className={S.qknInfo}>
        <div className={S.leftSide}>
          <ProductName sub={SUB} className={S.prodName} product='quicken' />
          <TagLine uriName={SUB.tierUriName} className={S.tagLine}/>
          <Price
            price={PRICE}
            renewalFrequency={SUB.renewalFrequency}
            className={S.priceRow}
          />
        </div>

        <div className={S.rightSide}>
          {IS_CANCELLED && <CancelledBox className={S.cancelBox}/>}
          {SUB.active && (!SUB.autoRenew || !HAS_PAYMENT) && (
            <RemainingTime className={S.remainingTime} expireOn={SUB.expireOn}/>
          )}

          <Expiry product={product} className={S.infoExpiryRow}/>

        </div>
      </div>
      <SubActionRow product={product} onClose={onClose}/>
    </article>
  );
};

InfoQuicken.displayName = 'SubProductCard';
InfoQuicken.propTypes   = {
  product: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export { InfoQuicken };
