import React               from 'react';
import PropTypes           from 'prop-types';
import { Message }         from '../../common/message';
import { ModalWrapper }    from '../modal-wrapper';
import Moment              from 'moment';
import { setCtxNestedObj } from '../../../context/ctx-actions';
import { useGlobalCtx }    from '../../../context/ctx-hook';
import { MODALS }          from '../../../helpers/constants/state-constants';



import * as S from './dropbox-modal.module.scss';

const DropBoxOffer = ({onClose, name, code, createdAt}) => {  
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const handleTermsAccept = () => { setTermsAccepted(true); };
  const { globalDispatch } = useGlobalCtx();
  const showNewOffer = Moment(createdAt).isSameOrAfter('2023-09-14', 'day');

  const STORAGE_AMOUNT = React.useMemo(() => {
    const NAME = name.toLowerCase();
    if (NAME === 'dropbox' && !showNewOffer) return 5;
    else if (NAME === 'dropbox' && showNewOffer) return 100;
    else if (NAME === 'dropboxamazon') return 15;
    else return 0;
  }, [name]);

  const handleTermsClick = React.useCallback(() =>
    globalDispatch(setCtxNestedObj('modal', {
      type: MODALS.DROPBOX_TERMS,
      ctx: {},
      isLoading: true,
      hasError: false,
    })),
  [globalDispatch]
  );

  return (
    <ModalWrapper heading="Dropbox Offer" onClose={onClose}>
      <div className={S.modalBody}>

        { showNewOffer ? 
          <p className={S.description}>
            As a thank you for being a Quicken member, we&rsquo;re giving you {STORAGE_AMOUNT}GB of free Dropbox space.
            Dropbox keeps your photos and documents in one place so you can access your stuff anywhere – on the web, on your computer, or on the go.
          </p>
          : <p className={S.description}>
            As a thank you for being a Quicken member, we&rsquo;re giving you free Dropbox space.
            Dropbox keeps your photos and documents in one place so you can access your stuff anywhere – on the web, on your computer, or on the go.
          </p>
        }

        <p className={S.offer}>To Claim this offer</p>

        <ul className={S.list}>
          <li>
            Go to
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.dropbox.com/coupon"
            >www.dropbox.com/coupon</a>
          </li>
          <li>Use the dropbox code provided below</li>
          <li>Get your Free space!</li>
        </ul>

        {!showNewOffer && 
          <Message type="INFO" align="CENTER" messages={[
            `${STORAGE_AMOUNT}GB Dropbox Coupon Code`,
            code
          ]} />        
        }

        {(showNewOffer && termsAccepted) &&
          <div className={S.dbTermsContain}>
            <div>
              <p>{STORAGE_AMOUNT}GB Dropbox Coupon Code</p>
              <p>{code}</p>
            </div>
          </div>
        }

        {(showNewOffer && !termsAccepted) &&
          <div className={S.dbTermsContain}>
            <div className={S.dbTerms}>
              <p>To redeem this offer, you must accept the offer
                {/* <a href="/dbterms" className={S.dbTermsBtn}>Terms & Conditions.</a> */}
                <button
                  className={S.termsLink}
                  onClick={handleTermsClick}
                >Terms & Conditions.</button>
              </p>
              <button 
                className={S.acceptBtn}
                onClick={handleTermsAccept}
              >Accept and Reveal Code</button>
            </div>
          </div>
        }

        <button
          onClick={onClose}
          className={S.dbCloseBtn}
          type="button"
        >Close Window</button>
      </div>
    </ModalWrapper>
  );
};

DropBoxOffer.displayName = 'DropBoxOffer';
DropBoxOffer.propTypes   = {
  name:      PropTypes.string.isRequired,
  code:      PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  onClose:   PropTypes.func.isRequired
};

export { DropBoxOffer };
