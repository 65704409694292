import React     from 'react';
import PropTypes from 'prop-types';

import * as S                from './info-qob.module.scss';
import { SubActionRow }      from '../sub-action-row';
import { Expiry }            from '../../../common/product/expiry';
import { Price }             from '../../../common/product/price';
import { ProductName }       from '../../../common/product/prod-name';
import { TagLine }           from '../../../common/product/tag-line';
import { useGlobalCtx }      from '../../../../context/ctx-hook';
import * as ProductSelectors from '../../../../context/selectors/product-selectors';

const InfoQob = ({product, onClose}) => {
  const {state} = useGlobalCtx();

  const SUB          = ProductSelectors.getSubscription(state, product);
  
  const RENEWAL_NAME = SUB?.renewalTierName;
  const SAME_PLAN    = (SUB.renewalTierName === undefined) || (SUB.renewalTierName === SUB.tierName);
  const PRICE_SKU    = ProductSelectors.uriNameToSku(SAME_PLAN ? SUB.tierUriName : SUB.renewalTierUriName);

  return (
    <article className={`${S.card} ${SUB.tierUriName}`}>
      <div className={S.qobInfo}>
        <div>
          <ProductName sub={SUB} product='online backup' className={S.prodName}/>
          <TagLine uriName={SUB?.tierUriName} className={S.tagLine}/>
          <div className={S.priceArea}>
            <Price
              sku={PRICE_SKU}
              renewalFrequency={SUB.renewalFrequency}
              className={S.priceRow}
            />
            {RENEWAL_NAME && (
              <div className={S.renewalName}>Renews at {SUB.renewalTierName || SUB.tierName}</div>
            )}
          </div>
        </div>
        <Expiry product={product} className={S.infoExpiryRow}/>
      </div>
      <SubActionRow product={product} onClose={onClose}/>
    </article>
  );
};

InfoQob.displayName = 'InfoQob';
InfoQob.propTypes   = {
  product: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export { InfoQob };
