import React from 'react';

import * as S            from './personal-info.module.scss';
import { ViewWrapper }   from '../../common/view-wrapper';
import * as Actions      from '../../../context/ctx-actions';
import { useGlobalCtx }  from '../../../context/ctx-hook';
import { MODALS }          from '../../../helpers/constants/state-constants';
import { useOnPageChange } from '../../../helpers/hooks/use-on-page-change';


const PersonalView = () => {
  const {
    globalDispatch,
    state: {
      personalInfo: {
        firstName,
        lastName,
        line1,
        line2,
        city,
        state,
        zipCode,
        country
      }
    }
  } = useGlobalCtx();

  const handleClick = React.useCallback(
    type => globalDispatch(Actions.setCtxNestedObj('modal', {
      type,
      ctx: {},
      isLoading: true,
      hasError: false,
    })),
    [globalDispatch]
  );

  const HAS_NAME = firstName === '';

  const CURRENT_NAME = React.useMemo(
    () => HAS_NAME ? 'No Name on File' : `${firstName} ${lastName}`,
    [HAS_NAME, firstName, lastName]
  );

  useOnPageChange();

  return (
    <ViewWrapper testId="PERSONAL_AREA" title="Personal Info">
      <div className={S.personalSpace}>

        <div className={S.personalArea}>
          <h3 className={S.contentLabel}>Name</h3>
          <p className={S.contentText}>{CURRENT_NAME}</p>
          <button
            type="button"
            className={S.contentAction}
            onClick={() => handleClick(MODALS.NAME)}
            data-testid="BTN_CHANGE_NAME"
          >Change
          </button>
        </div>

        <div className={S.personalArea}>
          <h3 className={S.contentLabel}>Address</h3>
          {line1 === ''
            ? <p className={S.contentText}>Address not on file</p>
            : (
              <>
                <p className={S.contentText}>{line1}, {line2}</p>
                <p className={S.contentText}>{city} {state}, {zipCode}</p>
                <p className={S.contentText}>{['US', 'VI'].includes(country) ? 'United States' : 'Canada'}</p>
              </>
            )
          }
          <button
            type="button"
            className={S.contentAction}
            onClick={() => handleClick(MODALS.ADDRESS)}
            data-testid="BTN_CHANGE_ADDRESS"
          >Change
          </button>
        </div>

      </div>
    </ViewWrapper>
  );
};

PersonalView.displayName = 'PersonalView';
PersonalView.propTypes   = {};

export { PersonalView };
