import React      from 'react';
import { Link }   from 'react-router-dom';
import ClassNames from 'classnames';

import LOGO_RED        from '../../../assets/imgs/brand/rebrand-square-logo-red.svg';
import LOGO_BLURPLE    from '../../../assets/imgs/brand/rebrand-square-logo-blurple.svg';

import * as S               from './overview.module.scss';
import { ViewWrapper }      from '../../common/view-wrapper';
import APPLE_APP_ICON       from '../../../assets/imgs/brand/app-store-black.svg';
import GOOGLE_APP_ICON      from '../../../assets/imgs/brand/google-play-store.svg';
import CHROMEBOOK_ICON      from '../../../assets/imgs/brand/add-to-chromebook.svg';
import MAC_LINK             from '../../../assets/imgs/brand/mac.svg';
import WINDOW_LINK          from '../../../assets/imgs/brand/windows.svg';
import DOWNLOAD_ICON        from '../../../assets/imgs/nav/download-icon.svg';
import LOCK_ICON            from '../../../assets/imgs/nav/lock-icon.svg';
import USER_ICON            from '../../../assets/imgs/nav/user-icon.svg';
import SUB_ICON             from '../../../assets/imgs/nav/subscriptions-icon.svg';
import { useGlobalCtx }     from '../../../context/ctx-hook';
import { getProducts }      from '../../../context/selectors/product-selectors';
import * as Constants       from '../../../helpers/constants/constants';
import { useOnPageChange }  from '../../../helpers/hooks/use-on-page-change';

const OverViewArea = () => {
  const {state}             = useGlobalCtx();
  const {quicken, simplifi} = getProducts(state);
  const SEARCH = window.location.search;

  const {
    country,
    security: {quickenId},
    ui:       {showDownloads, hasSubscriptions}
  } = state;

  const EMAIL = React.useMemo(
    () => encodeURI(quickenId),
    [quickenId]
  );

  useOnPageChange();

  return (
    <ViewWrapper testId="OVERVIEW_PAGE" title="Overview" showSupport>
      <div className={S.cardWrapper}>
        {hasSubscriptions && (
          <Link
            to={Constants.ROUTES.SUBSCRIPTIONS + SEARCH}
            className={S.overViewCard}
            data-testid="LINK_MANAGE_SUBSCRIPTIONS"
          >
            <img src={SUB_ICON} alt="Subscriptions" className={S.svgIcon}/>
            <h2>Subscriptions</h2>
            <p>Manage your Quicken & Simplifi memberships, update payment info & more</p>
            <h3 className={S.linkCta}>Manage</h3>
          </Link>
        )}
        <Link
          to={Constants.ROUTES.SECURITY + SEARCH}
          className={S.overViewCard}
          data-testid="LINK_MANAGE_SECURITY"
        >
          <img src={LOCK_ICON} alt="Lock" className={S.svgIcon}/>
          <h2>Sign in & Security</h2>
          <p>Manage your Quicken ID, phone number, devices & security preferences</p>
          <h3 className={S.linkCta}>Manage</h3>
        </Link>

        <Link
          to={Constants.ROUTES.PERSONAL_INFO + SEARCH}
          className={S.overViewCard}
          data-testid="LINK_MANAGE_PERSONAL"
        >
          <img src={USER_ICON} alt="Person" className={S.svgIcon}/>
          <h2>Personal Info</h2>
          <p>Update your name and billing address</p>
          <h3 className={S.linkCta}>Manage</h3>
        </Link>

        {(quicken || simplifi) && showDownloads && (
          <article className={S.downloadCard}>
            <div className={S.dlTitle}>
              <img src={DOWNLOAD_ICON} alt="Download" className={ClassNames(S.svgIcon, S.downloadSvg)}/>
              <h2>Download Quicken</h2>
            </div>

            <div className={S.dlLinkContain}>
              <div className={S.qcDownloads}>
                <div className={S.dlLinkTitle}>
                  <img src={LOGO_RED} alt="Red Quicken square logo" className={S.dlLogo} />
                  <span><span className={S.qknRed}>Quicken</span> Classic</span>
                </div>

                <div className={S.links}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    data-testid="LINK_QKN_WIN"
                    className={S.appIcon}
                    href={`${Constants.URLS.DOWNLOAD}/windows/Quicken${country === 'ca' ? '-Canada' : ''}.exe?email=${EMAIL}`}
                  ><img src={WINDOW_LINK} alt="Quicken for Windows" /></a>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    data-testid="LINK_QKN_MAC"
                    className={S.appIcon}
                    href={`${Constants.URLS.DOWNLOAD}/mac/Quicken.dmg?email=${EMAIL}`}
                  ><img src={MAC_LINK} alt="Quicken for Mac" /></a>
                </div>
              </div>

              <div className={S.qsDownloads}>
                <div className={S.dlLinkTitle}>
                  <img src={LOGO_BLURPLE} alt="Purple-ish Quicken square logo" className={S.dlLogo} />
                  <span><span className={S.qknBlurple}>Quicken</span> Simplifi</span>
                </div>

                <div className={S.links}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    data-testid="LINK_QKN_APPLE_APP"
                    className={S.appIcon}
                    href={Constants.APP_STORES.APPLE}
                  ><img src={APPLE_APP_ICON} alt="Apple App Store" /></a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    data-testid="LINK_QKN_GOOGLE_APP"
                    className={S.appIcon}
                    href={Constants.APP_STORES.GOOGLE}
                  ><img src={GOOGLE_APP_ICON} alt="Google Play Store" /></a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    data-testid="LINK_QKN_CHROMEBOOK_APP"
                    className={S.appIcon}
                    href={Constants.APP_STORES.CHROMEBOOK}
                  ><img src={CHROMEBOOK_ICON} alt="Google Play Store" /></a>
                </div>
              </div>
            </div>
          </article>
        )}
      </div>
    </ViewWrapper>
  );
};

OverViewArea.displayName = 'OverViewArea';

export { OverViewArea };
