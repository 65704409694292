import ClassNames from 'classnames';
import React      from 'react';

import * as S                     from './bm-top-up.module.scss';
import { ToggleSwitch }           from '../../../common/inputs/switch-field';
import * as Actions               from '../../../../context/ctx-actions';
import { useGlobalCtx }           from '../../../../context/ctx-hook';
import { getProductsPaymentMethod }          from '../../../../context/selectors/user-selectors';
import { callUpdateBillPayTopUp } from '../../../../network/network-calls';
import { MODALS }                 from '../../../../helpers/constants/state-constants';
import INFO_ICON                  from '../../../../assets/imgs/icons/icon-info.svg';



const PRODUCT = 'billmanager';

const BmTopToggle = (className) => {
  // custClass tileToggle is passed in when this is shown on the tile instead of the sub page
  const { custClass } = className;
  const useLightToggle = (custClass == 'tileToggle') ? true : false;
  const useDarkToggle = (custClass != 'tileToggle') ? true : false;
  const {globalDispatch, state, state: {billManager: {autoTopUp}}} = useGlobalCtx();
  const [showPopUp, setShowPopUp]                            = React.useState(false);
  // const HAS_ACH_PAYMENT     = getUserHasAch(state);
  const PRODUCT_PAYMENT = getProductsPaymentMethod(PRODUCT, state);
  const HAS_ACH_PAYMENT = PRODUCT_PAYMENT ? PRODUCT_PAYMENT?.paymentMethodType === 'ACH' : false;

  const handleToggle = React.useCallback(async (isActive, hasAch) => {
    try {
      if (hasAch) {
        globalDispatch(Actions.setCtxField('isDisabled', true));

        await callUpdateBillPayTopUp(isActive);

        globalDispatch(Actions.mergeMainStateObj({
          isDisabled: false,
          billManager: {
            autoTopUp: isActive
          }
        }));
      } else {
        // NO ACH on file, force payment modal first
        const plan = 'topup';
        globalDispatch(Actions.mergeMainStateObj({
          isDisabled: false,
          modal: {
            type: MODALS.UPDATE_PAYMENT,
            ctx: { product: PRODUCT, plan, paymentOnly: false },
            isLoading: true,
            hasError: false,
            isDisabled: false
          }
        }));
      }
    } catch (err) {
      console.log(err);
      globalDispatch(Actions.setCtxField('isDisabled', false));
    }
  },
  [globalDispatch]
  );

  return (
    <div className={ClassNames(S.topUpWrapper, S[custClass])}>
      {showPopUp && (
        <div className={S.popUp}>
          { custClass == 'tileToggle' ?
            'Once enabled, after using all your available payments, Quicken will automatically add 3 Quick Pay and 3 Check Pay payments for $3.' :
            'Once you run out of your Check Pay & Quick Pay quota, we\'ll automatically purchase a one-time refill.'
          }
        </div>
      )}

      <span
        className={`${S.text} ${S.custClass}`}
        onMouseEnter={() => setShowPopUp(true)}
        onMouseLeave={() => setShowPopUp(false)}
      >
        { custClass == 'tileToggle' && (
          <div className={S.togTextCont}>
            <span>Automatic Add-On Payments</span>
            <img className={S.infoIcon} src={INFO_ICON} alt="Message Icon"/>
          </div>
        )}
        
        {(custClass != 'tileToggle') && 'Additional Payments*' }
      </span>

      <ToggleSwitch
        id="topUpInput"
        isActive={autoTopUp}
        handleChange={() => handleToggle(!autoTopUp, HAS_ACH_PAYMENT)}
        isDark={useDarkToggle}
        isLight={useLightToggle}
      />
    </div>
  );
};

BmTopToggle.displayName = 'BmTopToggle';

export { BmTopToggle };
