import PropTypes from 'prop-types';
import React     from 'react';

import * as S           from './switch-success.module.scss';
import { ModalWrapper } from '../../modal-wrapper';
import THUMBS_UP        from '../../../../assets/imgs/art/thumb-up.svg';
import { APP_STORES }   from '../../../../helpers/constants/constants';

const SwitchSuccess = ({onClose, ctx}) => {
  return (
    <ModalWrapper
      onClose={onClose}
      className={S.modalWrapper}
      heading="Simplifi Trial Success"
    >
      <div className={S.switchSuccessArea}>
        <div className={S.scalable}>
          <img className={S.thumb} src={THUMBS_UP} alt="Thumbs up"/>

          <h3 className={S.heading}>Your Simplifi by Quicken trial is ready to go!</h3>

          <p className={S.access}>You’ll be able to access both {ctx.name} and Simplifi until <span>{ctx.date}</span>.</p>

          <div className={S.howTo}>
            <h4>To use Simplifi</h4>

            <ul>
              <li>
                <span>1</span>
                Get started on the web app by clicking the Get Started button below, or download Simplifi
                for <a href={APP_STORES.APPLE}>iOS</a> or <a href={APP_STORES.GOOGLE}>Android</a>
              </li>
              <li>
                <span>2</span>
                Sign in with the same account you used for Quicken and connect your financial accounts to Quicken Simplifi
              </li>
            </ul>

            <h5>*Quicken and Simplifi are distinct products and do not share data</h5>
          </div>
        </div>
        <div className={S.btnRow}>
          <a href={process.env.REACT_APP_SIMP_APP} className={S.closeCtaBtn}>GET STARTED ON WEB</a>
        </div>
      </div>
    </ModalWrapper>
  );
};

SwitchSuccess.displayName = 'SwitchSuccess';
SwitchSuccess.propTypes   = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.shape({
    date: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })
};

export { SwitchSuccess };
