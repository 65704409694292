import React     from 'react';
import PropTypes from 'prop-types';

import * as S              from './simp-cancelled.module.scss';
import ROCKET              from '../../../../assets/imgs/art/rocket.svg';
import { setCtxNestedObj } from '../../../../context/ctx-actions';
import { useGlobalCtx }    from '../../../../context/ctx-hook';
import * as StateConstants from '../../../../helpers/constants/state-constants';

const SimpCancelled = () => {
  const {globalDispatch} = useGlobalCtx();

  const handleReactivate = React.useCallback(
    () => globalDispatch(setCtxNestedObj('modal', {
      type:       StateConstants.MODALS.REACTIVATE,
      ctx:        {product: 'acme', paymentOnly: false},
      isLoading:  true,
      hasError:   false,
      isDisabled: false
    })),
    [globalDispatch]
  );

  return (
    <>
      <h4 className={S.secondaryHeading}>Regain access to benefits:</h4>

      <ul className={S.benefitList}>
        <li>See finances in one place</li>
        <li>Transactions automatically categorized</li>
        <li>Track recurring bills and subscriptions</li>
        <li>View upcoming bills, incomes, & transfers</li>
      </ul>

      <span className={S.dash}/>

      <button
        type="button"
        onClick={handleReactivate}
        className={S.contentCTABtn}
      >Plans & Pricing</button>

      <img
        src={ROCKET}
        alt="Rocket"
        className={S.rocket}
      />
    </>
  );
};

SimpCancelled.displayName  = 'TrialCancelled';
SimpCancelled.propTypes    = {isModal: PropTypes.bool};
SimpCancelled.defaultProps = {isModal: false};

export { SimpCancelled };
