import React                       from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { LoginPage } from '../pages/authentication/login-page';
import { ROUTES }    from '../../helpers/constants/constants';

const NoAuthRoutes = () => (
  <Switch>
    <Route exact path={ROUTES.LOGIN} component={LoginPage}/>
    <Route path="/" component={({location: {pathname, search}}) => {
      localStorage.setItem('postLogin', JSON.stringify({pathname, search}));
      return <Redirect to={ROUTES.LOGIN}/>;
    }}/>
  </Switch>
);

NoAuthRoutes.displayName = 'NoAuthRoutes';

export { NoAuthRoutes };
