import React     from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import * as S from './prod-name.module.scss';

const isNonEmptyObject = (obj) => {
  return typeof obj === 'object' && obj !== null && Object.keys(obj).length > 0;
};

const nameContent = (sub, product, nameStyle, customName) => {

  if(!sub && !customName) {
    console.warn('Product name component missing sub or override name');
    console.warn('Sub: ' + sub);
    console.warn('customName: ' + customName);
    return (<> {sub.productLineName} {sub.tierName} </>);
  }
  const isSimplifi = sub?.productLineName?.toLowerCase() === 'simplifi' || product === 'simplifi';
  const isQem = sub?.productLineName?.toLowerCase() === 'quicken lifehub' || product === 'quicken lifehub';
  const isQuicken = ['quicken us', 'quicken ca'].includes(sub?.productLineName?.toLowerCase()) || product === 'quicken';
  const isQob = sub?.productLineName.toLowerCase() === 'quicken online backup' || product === 'online backup';
  const isBm = ['bill manager', 'billmanager', 'billmanager promotion', 'bill manager promotion'].includes(sub?.productLineName?.toLowerCase()) || product === 'bill manager';

  if (isQem) {
    return (<><span className={S.simpTheme}>Quicken</span> Lifehub</>);
  } else if (isSimplifi) {
    if (customName) {
      return (<><span className={S.simpTheme}>Quicken</span> {customName}</>);
    } else if (nameStyle === 'textOnly') {
      `Quicken ${sub.tierName}`;
    } else {
      return (<><span className={S.simpTheme}>Quicken</span> {sub.tierName}</>);
    }
  } else if (isQuicken) {
    if (customName) {
      return (<><span className={S.qknTheme}>Quicken</span> Classic {customName}</>);
    } else if (nameStyle === 'textOnly') {
      return `Quicken Classic ${sub.tierName}`;
    } else {
      return (<><span className={S.qknTheme}>Quicken</span> Classic {sub.tierName}</>);
    }
  } else if (isQob) {
    return (<><span className={S.qknTheme}>Quicken</span> Online Backup</>);
  } else if (isBm) {
    // return (<> {sub.tierTitle} </>);
    return (<> {sub.productLineName} {sub.tierName} </>);
  } else {
    return '';
  }
};

const ProductName = ({ sub, className, product, nameStyle, customName }) => {
  // Not everything passed in here is a full sub response so check and use a true fallback
  let arCheck = true;
  if (typeof sub === 'object') {
    arCheck = ('autoRenew' in sub) ? sub.autoRenew : true; 
  }

  if (!product && !isNonEmptyObject(sub) && !customName) { 
    return (<div className={ClassNames(S.prodName, className)}></div> );
  }

  if (nameStyle === 'inline'){
    return (<span className={ClassNames(S.prodName, className)}> {nameContent(sub, product, nameStyle, customName)} </span>);
  } else {
    return (<div className={ClassNames(S.prodName, className, { [S.hasArOff]: !arCheck })}> {nameContent(sub, product, nameStyle, customName)} </div>); 
  }
};

ProductName.displayName = 'CompanyBox';
ProductName.propTypes   = {
  sub: PropTypes.object, 
  className: PropTypes.string,
  customName: PropTypes.string,
  style: PropTypes.string
};
ProductName.defaultProps ={
  inline : false
};

export { ProductName };
