import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import * as S from './new-modal.module.scss';


const ModalBtnRow = ({
  form,
  onCancel,
  isDisabled,
  hasError,
  cancelText,
  submitText,
  className,
  privUser = false
}) => {
  return (
    <div className={ClassNames(S.btnRow, className)}>

      <button
        type="button"
        className={S.contentResetBtn}
        disabled={false}
        onClick={onCancel}
        data-testid={`${form}_CANCEL`}
      >{cancelText}</button>

      {!privUser &&
        <button
          type="submit"
          className={S.contentCTABtn}
          disabled={hasError || isDisabled}
          data-testid={`${form}_SUBMIT`}
        >{submitText}</button>
      }

    </div>
  );
};

ModalBtnRow.displayName = 'ModalBtnRow';
ModalBtnRow.propTypes   = {
  form:       PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  hasError:   PropTypes.bool.isRequired,
  className:  PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  onCancel:   PropTypes.func.isRequired,
  privUser:   PropTypes.bool
};

ModalBtnRow.defaultProps = {
  cancelText: 'Cancel',
  submitText: 'Submit',
  form: '',
};

export { ModalBtnRow };
