import React      from 'react';
import PropTypes  from 'prop-types';
import classNames from 'classnames';

import * as S from './checkbox-field.module.scss';

export const CheckboxField = ({isChecked, isDisabled, onChange, label, id, name, className}) => (
  <div className={classNames(S.checkBoxField, className)}>
    <input
      type="checkbox"
      id={id}
      name={name}
      checked={isChecked}
      disabled={isDisabled}
      onChange={onChange}
    />
    <label htmlFor={id}>{label}</label>
  </div>
);

CheckboxField.displayName = 'CheckboxField';

CheckboxField.propTypes = {
  id:         PropTypes.string.isRequired,
  name:       PropTypes.string.isRequired,
  label:      PropTypes.string.isRequired,
  className:  PropTypes.string,
  isChecked:  PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange:   PropTypes.func.isRequired
};
