import React     from 'react';
import PropTypes from 'prop-types';

import { ChangePlanAcme }      from './change-plan-acme';
import { ChangePlanBm }        from './change-bm/change-plan-bm';
import { ChangePlanQkn }       from './change-qkn/change-plan-qkn';
import { ReactivateSimpModal } from './reactivate-simp-modal';
import { ForceClose }          from '../force-close';
import { useGlobalCtx }        from '../../../context/ctx-hook';
// import * as ProductSelectors   from '../../../context/selectors/product-selectors';
// import * as UserSelectors      from '../../../context/selectors/user-selectors';
// import * as TimeHelpers        from '../../../helpers/time-helpers';


const ChangePlanModal = (props) => {
  const {state} = useGlobalCtx();
  const COUNTRY = state.country;
  const QKN_SUB = state.subscriptions[`quicken-${COUNTRY}`];
  

  if (props.ctx.product === 'acme' && state?.subscriptions?.acme) {
    const SUB = state?.subscriptions?.acme;
    if (SUB?.active) {
      return SUB.renewalFrequency === 'P1M'
        ? <ChangePlanAcme {...props} />
        : <ForceClose onClose={props.onClose}/>;
    } else {
      return <ReactivateSimpModal {...props} />;
    }
  } else if ((props.ctx.product === 'billmanager' || props.ctx.product === 'billmanager-promotion')  && QKN_SUB.active) {
    return <ChangePlanBm {...props} />;
  } else if (QKN_SUB && props.ctx.product.includes('quicken')) {
    // Check the same validation logic as we do on the homepage when opening this modal
    // If they are in a state where buttons would be disabled, close the modal to force them into the flow of turning AR back on
    // const {
    //   autoRenew,
    //   expireOn,
    // } = ProductSelectors.getSubscription(state, `quicken-${COUNTRY}`);
    // const PAYMENT = UserSelectors.getProductsPaymentMethod(`quicken-${COUNTRY}`, state);
    // const HAS_PAYMENT = (!PAYMENT.isDeclined && !PAYMENT.isHardDeclined);
    // const DISTANCE = TimeHelpers.getDaysOut(expireOn);
    // const DAYS_LEFT_OVER_45   = DISTANCE === 'MID';
    // const DAYS_LEFT_LESS_45   = DISTANCE === 'NEAR';
    // const RENEW_ELGIBLE = (DAYS_LEFT_OVER_45 || (DAYS_LEFT_LESS_45 && autoRenew && HAS_PAYMENT));

    // return RENEW_ELGIBLE
    // ? <ChangePlanQkn {...props} />
    // : <ForceClose onClose={props.onClose}/>;
    return <ChangePlanQkn {...props} />;
  } else {
    return <ForceClose onClose={props.onClose}/>;
  }
};

ChangePlanModal.displayName = 'ChangePlanModal';

ChangePlanModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.shape({
    product: PropTypes.string.isRequired
  })
};

export { ChangePlanModal };
