export const MODALS = {
  ADDRESS:        'ADDRESS',
  ALERT:          'ALERT',
  CANCEL_AR:      'CANCEL_AR',
  CHANGE_PLAN:    'CHANGE_PLAN',
  DROPBOX:        'DROPBOX',
  DROPBOX_TERMS:  'DROPBOX_TERMS',
  MFA:            'MFA',
  NAME:           'NAME',
  PASSWORD:       'PASSWORD',
  PHONE:          'PHONE',
  QUICKEN_ID:     'QUICKEN_ID',
  REACTIVATE:     'REACTIVATE',
  SIGN_OUT:       'SIGN_OUT',
  SUBSCRIPTION:   'SUBSCRIPTION',
  SURVEY:         'SURVEY',
  UPDATE_PAYMENT: 'UPDATE_PAYMENT',
  SWITCH_TO_SIMP: 'SWITCH_TO_SIMP',
  SWITCH_SUCCESS: 'SWITCH_SUCCESS'
};

export const PAGE_STATUS = {
  LOADING:   'LOADING',
  HAS_ERROR: 'HAS_ERROR',
  READY:     'READY'
};
