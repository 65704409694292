import PropTypes from 'prop-types';
import React     from 'react';

import { ModalBtnRow }           from '../modal-btn-row';
import { ModalWrapper }          from '../modal-wrapper';
import * as Actions              from '../../../context/ctx-actions';
import { useGlobalCtx }          from '../../../context/ctx-hook';
import * as MixPanel             from '../../../helpers/mixpanel';
import { checkForAnyErrors }     from '../../../helpers/pure-functions';
import * as Validations          from '../../../helpers/validations';
// import * as Options              from '../../../helpers/constants/options-constants';

import { callUpdateQuickenIdV3 } from '../../../network/quicken-id-calls';
import { TextField }             from '../../common/inputs/text-field';
// import { SelectField }           from '../../common/inputs/select-field';
import { Message }               from '../../common/message';


import * as S from './quicken-id-modal.module.scss';

export const QUICKEN_ID_VALIDATIONS = {
  quickenId: Validations.validateQuickenId,
  password:  Validations.validateUserPassword
};

export const FORM_MFA_VALIDATIONS = {
  mfa: Validations.validateMFA,
  mfaCode: Validations.validateMFACode
};

const errorChecker = (code, extData, errObj) => {
  switch (code.toUpperCase()) {
    case 'QCS-0400-8':
      if (extData?.parameterName === 'mfaCode') {
        return { ...errObj, mfaCode: 'Please enter a valid 6 digit code' };
      }
      break;
    default:
      return { ...errObj, form: 'GENERIC_ERROR' };
  }
};

const QuickenIdModal = ({onClose}) => {
  const { globalDispatch, state: { 
    isDisabled, 
    security: { 
      // phone,
      mfa,
      mfaCode,
      // mfaSentChannel,
      mfaSentTo,
      quickenId,
      groups
    }
  }} = useGlobalCtx();
  let resendInFlight = false;

  const pUsrGroups = [
    'qcs_care_admin',
    'qcs_care_t1',
    'qcs_care_t2',
    'qcs_care_t3',
    'qcs_deployer',
    'qcs_eng_admin',
    'qcs_eng_t1',
    'qcs_eng_t2',
    'qcs_eng_t3',
    'qcs_mcp_approver',
    'qcs_mcp_deployer',
    'qcs_mkt_admin',
    'qcs_mkt_t1',
    'qcs_mkt_t2',
    'qcs_mkt_t3',
  ];

  let groupIntersection = pUsrGroups.filter(x => groups.includes(x));
  const IS_PRIV_USER = groupIntersection.length >= 1;

  const [form, setForm] = React.useState({
    values: { quickenId, password: '', mfa, mfaCode},
    errors: { quickenId: '', password: '', mfa: '', mfaCode: '', form: ''}
  });

  const clearFormError = React.useCallback(() => {
    setForm(({values, errors}) => ({
      values: {...values},
      errors: {...errors, form: ''}
    }));
  }, []);

  const handleChange = React.useCallback(({type, target: {name, value}}) => {
    if (type === 'blur') value = value.trim();
    setForm(({values, errors}) => ({
      values: {...values, [name]: value},
      errors: {...errors, [name]: QUICKEN_ID_VALIDATIONS[name](value)}
    }));
  }, []);

  // const handleSelection = React.useCallback(({name, value}) =>
  //   setForm(({values, errors}) => ({
  //     values: {...values, [name]: value},
  //     errors: {...errors, [name]: FORM_MFA_VALIDATIONS.mfa(value)}
  //   })),
  // []
  // );

  const handleMfaCodeChange = React.useCallback(({type, target: {name, value}}) => {
    const IS_BLUR = type === 'blur';
    if (IS_BLUR) value = value.trim();

    // Had to add this as paste wasn't clearing form errors
    const submitBtn = document.querySelector('[data-testid="QKN_ID_FORM_SUBMIT"]');
    submitBtn.disabled = false;

    setForm(({values, errors}) => ({
      values: {...values, [name]: value},
      errors: {...errors, [name]: FORM_MFA_VALIDATIONS.mfaCode(value)}
    }));
  }, []);

  const handleResendCode = React.useCallback(async () => {
    if (resendInFlight) { return; }
    const formData         = document.querySelector('form');
    const quickenId       = formData.elements.quickenId.value.trim();
    const currentPassword = formData.elements.password.value.trim();
    // const mfaChannel      = 'EMAIL';
    const mfaCode         = '';

    const ERROR_OBJ = {
      quickenId: QUICKEN_ID_VALIDATIONS.quickenId(quickenId),
      password: QUICKEN_ID_VALIDATIONS.password(currentPassword),
      // mfaChannel:      'EMAIL',
      mfaCode:         FORM_MFA_VALIDATIONS.mfaCode(mfaCode),
      form:      ''
    };

    try {
      globalDispatch(Actions.setCtxField('isDisabled', true));
      resendInFlight = true;
      await callUpdateQuickenIdV3(quickenId, currentPassword, 'EMAIL', mfaCode);
      resendInFlight = false;
      globalDispatch(Actions.setCtxField('isDisabled', false));
    } catch (err) {
      MixPanel.error(err, MixPanel.MIX_PANEL_IDS.PASSWORD);
      setForm(({ values }) => ({
        values: { ...values },
        errors: { ...ERROR_OBJ, form: 'GENERIC_ERROR' }
      }));
      resendInFlight = false;
      globalDispatch(Actions.setCtxField('isDisabled', false));
    }
  });

  // const OPTIONS = React.useMemo(() => phone ? Options.MFA_OPTIONS : Options.MFA_DISABLED, [phone]);

  const setMfaFields = (action) => {
    const mfaCodeLabel = document.querySelector('[data-testid="MFA_CODE_LABEL"]');
    const mfaCodeField = document.querySelector('[data-testid="MFA_CODE_INPUT"]');
    const mfaResendMsg = document.querySelector('[data-testid="MFA_RESEND_MSG"]');
    const submitBtn = document.querySelector('[data-testid="QKN_ID_FORM_SUBMIT"]');

    if (action === 'enable') {
      mfaCodeLabel.style.display = 'initial';
      mfaCodeField.style.display = 'initial';
      mfaResendMsg.style.display = 'block';
      globalDispatch(Actions.setCtxField('isDisabled', false));
      submitBtn.innerHTML = 'Submit';
      submitBtn.disabled = true;
    }

    if (action === 'disable') {
      mfaCodeLabel.style.display = 'hidden';
      mfaCodeField.style.display = 'hidden';
      mfaResendMsg.style.display = 'hidden';
      globalDispatch(Actions.setCtxField('isDisabled', false));
      submitBtn.innerHTML = 'Continue';
      submitBtn.disabled = false;
    }
  };

  const handleSubmit = React.useCallback(async e => {
    e.preventDefault();
    const quickenId    = e.target.elements.quickenId.value.trim();
    const password     = e.target.elements.password.value.trim();
    // const mfaChannel   = e.target.elements.mfa.value.trim();
    const mfaCode      = e.target.elements.mfaCode.value.trim();

    const ERROR_OBJ = {
      quickenId:   QUICKEN_ID_VALIDATIONS.quickenId(quickenId),
      password:    QUICKEN_ID_VALIDATIONS.password(password),
      // mfaChannel:  FORM_MFA_VALIDATIONS.mfa(mfaChannel),
      mfaCode:     FORM_MFA_VALIDATIONS.mfaCode(mfaCode),
      form:      ''
    };

    setForm({
      values: {quickenId, password, mfa, mfaCode},
      errors: ERROR_OBJ
    });

    if (!checkForAnyErrors(Object.values(ERROR_OBJ))) {
      try {
        globalDispatch(Actions.setCtxField('isDisabled', true));

        // const apiResp = await callUpdateQuickenIdV3(quickenId, password, 'EMAIL', mfaCode);
        const apiResp = await callUpdateQuickenIdV3(quickenId, password, 'EMAIL', mfaCode);
        const data = apiResp.data;

        if (apiResp.status == '202') {
          setMfaFields('enable');
          if (data.mfaChannel === 'EMAIL') {
            globalDispatch(Actions.mergeMainStateObj({
              security: {
                mfaSentChannel: data.mfaChannel,
                mfaSentTo: data.email
              }
            }));
          } else if (data.mfaChannel === 'SMS' || data.mfaChannel === 'VOICE') {
            globalDispatch(Actions.mergeMainStateObj({
              security: {
                mfaSentChannel: data.mfaChannel,
                mfaSentTo: data.phone
              }
            }));
          }
        } else {
          MixPanel.track(MixPanel.MIX_PANEL_IDS.QUICKEN_ID);
          globalDispatch(Actions.mergeMainStateObj({
            isDisabled: false,
            security: { 
              quickenId,
              mfaSentChannel: '',
              mfaSentTo: ''
            },
            alert: {
              type: 'SUCCESS',
              messages: ['Your Quicken ID was changed successfully.']
            }
          }));

          onClose();
        }
      } catch (err) {
        MixPanel.error(err, MixPanel.MIX_PANEL_IDS.QUICKEN_ID);
        const CODE = err?.response?.data?.errors?.[0]?.code ?? '';
        const extData = err?.response?.data?.errors?.[0]?.extData ?? false;


        setForm(({values}) => ({
          values: {...values},
          // errors: {...ERROR_OBJ, form: 'GENERIC_ERROR'}
          errors: errorChecker(CODE.toUpperCase(), extData, ERROR_OBJ)
        }));

        setMfaFields('disable');
        
        globalDispatch(Actions.setCtxField('isDisabled', false));
      }
    }
  }, [globalDispatch, onClose]);

  const HAS_ERROR = React.useMemo(
    () => Object.values(form.errors).some(v => v !== ''),
    [form.errors]
  );

  const IS_GENERIC_ERROR = form.errors.form === 'GENERIC_ERROR';

  return (
    <ModalWrapper heading="Change Quicken ID" onClose={onClose}>
      <div className={S.modalBody}>
        {form.errors.form && (
          <Message
            messages={IS_GENERIC_ERROR ? [] : [form.errors.form]}
            type={IS_GENERIC_ERROR ? 'GENERIC_ERROR' : 'ERROR'}
          />
        )}
        <form onSubmit={handleSubmit}>
          <div className={S.qknIdForm}>

            <div className={S.quickenIdLabel}>
              <h3 className={S.contentLabel}>Current Quicken ID</h3>
              <p
                data-testid="QKN_ID_FORM_CURRENT"
                className={S.contentText}
              >{quickenId}</p>

              {IS_PRIV_USER &&
                <p className={S.mfaCodeMsg}>Privileged users may not change their username.</p>
              }

              {!IS_PRIV_USER &&
                <p className={S.mfaSecMsg}>To help keep your account secure, we ask for your current password when you make any changes to your account.</p>
              }
            </div>

            <TextField
              label="New Quicken ID"
              name="quickenId"
              id="QKN_ID_FORM_EMAIL"
              placeholder="Enter Email Address"
              className={S.qknIdInput}
              error={form.errors.quickenId}
              value={form.values.quickenId}
              disabled={IS_PRIV_USER ? true : isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              onFocus={clearFormError}
            />


            <TextField
              label="Current Password"
              name="password"
              type="password"
              id="QKN_ID_FORM_PASSWORD"
              placeholder="Enter Current Password"
              className={S.passwordInput}
              error={form.errors.password}
              value={form.values.password}
              disabled={IS_PRIV_USER ? true : isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              onFocus={clearFormError}
            />
          </div>

          <div className={S.emailMfaContainer}>
            <p className={S.mfaCodeMsg}>To help keep your account secure, we need to verify your identity with a two-factor authentication code. The code will be sent to the new email address.</p>

            <p data-testid="MFA_RESEND_MSG" className={S.warning}>We sent a code to
              {' '+mfaSentTo+' '}
              <span className={S.resendText}>Didn’t receive it?
                <span
                  className={S.resendLink}
                  onClick={handleResendCode}
                  onKeyUp={handleResendCode}
                  role="button"
                  disabled={IS_PRIV_USER ? true : isDisabled}
                  tabIndex={0}
                > Resend code </span>
              </span>
            </p>


            <div className={S.mfaInputContain}>
              {/* <SelectField
                label="How would you like to recieve your code?"
                name="mfa"
                id="MFA_FORM_SELECTION"
                className={S.mfaMethodSelect}
                error={form.errors.mfa}
                value={mfaSentChannel ? 'Email' : form.values.mfa}
                disabled={true}
                onChange={handleSelection}
                optionList={OPTIONS}
                onFocus={clearFormError}
              /> */}

              <TextField
                label="Enter the 6-digit code we sent you"
                name="mfaCode"
                type="number"
                maxLength="6"
                id="MFA_CODE"
                className={S.mfaCodeInputHidden}
                error={form.errors.mfaCode}
                value={form.values.mfaCode}
                disabled={isDisabled}
                onChange={handleMfaCodeChange}
                onInput={handleMfaCodeChange}
                onBlur={handleMfaCodeChange}
                onFocus={clearFormError}
              />
            </div>
          </div>

          <ModalBtnRow
            form="QKN_ID_FORM"
            isDisabled={isDisabled}
            hasError={HAS_ERROR}
            onCancel={onClose}
            privUser={IS_PRIV_USER}
          />
        </form>
      </div>
    </ModalWrapper>
  );
};

QuickenIdModal.displayName = 'QuickenIdModal';
QuickenIdModal.propTypes   = {
  onClose: PropTypes.func.isRequired
};

export { QuickenIdModal };
