import PropTypes             from 'prop-types';
import React                 from 'react';
import * as Actions          from '../../../context/ctx-actions';
import { useGlobalCtx }      from '../../../context/ctx-hook';
import * as Options          from '../../../helpers/constants/options-constants';
import * as MixPanel         from '../../../helpers/mixpanel';
import { checkForAnyErrors } from '../../../helpers/pure-functions';
import * as Validations      from '../../../helpers/validations';
import { callUpdateMfa }     from '../../../network/quicken-id-calls';
import { SelectField }       from '../../common/inputs/select-field';
import { TextField }         from '../../common/inputs/text-field';
import { Message }           from '../../common/message';
import { ModalBtnRow }       from '../modal-btn-row';
import { ModalWrapper }      from '../modal-wrapper';

import * as S from './mfa-modal.module.scss';


export const FORM_MFA_VALIDATIONS = {
  mfa:      Validations.validateMFA,
  password: Validations.validateUserPassword
};

const MFAModal = ({onClose}) => {
  const {globalDispatch, state: {isDisabled, security: {phone, mfa}}} = useGlobalCtx();

  const [form, setForm] = React.useState({
    values: {mfa, password: ''},
    errors: {mfa: '', password: '', form: ''}
  });

  const handleChange = React.useCallback(({type, target: {name, value}}) => {
    if (type === 'blur') value = value.trim();
    setForm(({values, errors}) => ({
      values: {...values, [name]: value},
      errors: {...errors, [name]: FORM_MFA_VALIDATIONS.password(value)}
    }));
  }, []);

  const handleSelection = React.useCallback(({name, value}) =>
    setForm(({values, errors}) => ({
      values: {...values, [name]: value},
      errors: {...errors, [name]: FORM_MFA_VALIDATIONS.mfa(value)}
    })),
  []
  );

  const clearFormError = React.useCallback(() => {
    setForm(({values, errors}) => ({
      values: {...values},
      errors: {...errors, form: ''}
    }));
  }, []);


  const OPTIONS = React.useMemo(() => phone ? Options.MFA_OPTIONS : Options.MFA_DISABLED, [phone]);

  const SELECTED_METHOD = Options.MFA_OPTIONS.find(({value}) => value === mfa).label;

  const handleSubmit = React.useCallback(async e => {
    e.preventDefault();
    const mfa      = e.target.elements.mfa.value.trim();
    const password = e.target.elements.password.value.trim();

    const ERROR_OBJ = {
      mfa:      FORM_MFA_VALIDATIONS.mfa(mfa),
      password: FORM_MFA_VALIDATIONS.password(password),
      form:     ''
    };

    setForm({
      values: {mfa, password},
      errors: ERROR_OBJ
    });

    if (!checkForAnyErrors(Object.values(ERROR_OBJ))) {
      try {
        globalDispatch(Actions.setCtxField('isDisabled', true));

        await callUpdateMfa(mfa, password);
        MixPanel.track(MixPanel.MIX_PANEL_IDS.MFA);
        globalDispatch(Actions.mergeMainStateObj({
          security: {mfa},
          alert: {
            type:     'SUCCESS',
            messages: ['Your security method has changed successfully.']
          }
        }));

        onClose();
      } catch (err) {
        MixPanel.error(err, MixPanel.MIX_PANEL_IDS.MFA);
        const CODE      = err?.response?.data?.errors?.[0]?.code ?? '';
        let newErrorObj = {...ERROR_OBJ, form: 'GENERIC_ERROR'};

        switch (CODE.toUpperCase()) {
          case 'QCS-0422':
            newErrorObj = {
              ...newErrorObj,
              mfa: 'Please select a different way of receiving your security codes than your current one'
            };
            break;
          case 'QCS-0401-7':
            newErrorObj = {...newErrorObj, password: 'Your password is invalid'};
            break;
          default:
        }

        setForm(({values}) => ({
          values: {...values},
          errors: newErrorObj
        }));
      } finally {
        globalDispatch(Actions.setCtxField('isDisabled', false));
      }
    }
  }, [globalDispatch, onClose]);

  const HAS_ERROR = React.useMemo(
    () => Object.values(form.errors).some(v => v !== ''),
    [form.errors]
  );

  const IS_GENERIC_ERROR = form.errors.form === 'GENERIC_ERROR';

  return (
    <ModalWrapper heading="Change Security Codes" onClose={onClose}>
      <div className={S.modalBody}>
        {form.errors.form && (
          <Message
            messages={IS_GENERIC_ERROR ? [] : [form.errors.form]}
            type={IS_GENERIC_ERROR ? 'GENERIC_ERROR' : 'ERROR'}
          />
        )}

        <form onSubmit={handleSubmit}>
          <div className={S.mfaForm}>

            <div className={S.mfaLabel}>
              <h3 className={S.contentLabel}>Current Security Method</h3>
              <p
                data-testid="MFA_FORM_CURRENT"
                className={S.contentText}>{SELECTED_METHOD}</p>
            </div>

            <SelectField
              label="Security Codes Received By"
              name="mfa"
              id="MFA_FORM_SELECTION"
              className={S.mfaInput}
              error={form.errors.mfa}
              value={form.values.mfa}
              disabled={isDisabled}
              onChange={handleSelection}
              optionList={OPTIONS}
              onFocus={clearFormError}
            />

            <TextField
              label="Current Password"
              name="password"
              type="password"
              id="MFA_FORM_PASSWORD"
              placeholder="Enter Current Password"
              className={S.passwordInput}
              error={form.errors.password}
              value={form.values.password}
              disabled={isDisabled}
              onChange={handleChange}
              onBlur={handleChange}
              onFocus={clearFormError}
            />
          </div>

          <ModalBtnRow
            form="MFA_FORM"
            isDisabled={isDisabled}
            hasError={HAS_ERROR}
            onCancel={onClose}
          />
        </form>
      </div>
    </ModalWrapper>
  );
};

MFAModal.displayName = 'MFAModal';
MFAModal.propTypes   = {
  onClose: PropTypes.func.isRequired
};

export { MFAModal };
