import {
  EMAIL_REGEX,
  NUMBERS_ONLY_REGEX,
  ACCOUNT_NUMBER_REGEX,
  ROUTING_NUMBER_REGEX,
  US_POSTAL_REGEX,
  CA_POSTAL_REGEX,
  PHONE_REGEX,
  HAS_UPPER_CASE_CHAR,
  HAS_LOWER_CASE_CHAR,
  HAS_A_NUMBER,
  HAS_NO_SPACES
} from './constants/regex';

// INTERNAL FUNCTIONS ONLY EXPORTED FOR TEST FILES
export const validateNumbersOnly = text => NUMBERS_ONLY_REGEX.test(text);
export const genericRequired = (text = '', errMsg) => text !== '' ? '' : errMsg;

export const validateName = (text = '', field) => {
  if (text === '') return `Please enter a ${field} name`;
  else if (text.length > 255) return 'please use less then 255 characters';
  else return '';
};

export const validateFirstName    = (text = '') => validateName(text, 'first');
export const validateLastName     = (text = '') => validateName(text, 'last');
export const validateBusinessName = (text = '') => validateName(text, 'business');

export const validateQuickenId = (text = '') => {
  if (text === '') return 'Please enter your Quicken ID/email';
  else if (!EMAIL_REGEX.test(text)) return 'Please enter a valid email address';
  else return '';
};

const pwRegexValidation = (str) => {
  const upCase   = HAS_UPPER_CASE_CHAR.test(str);
  const loCase   = HAS_LOWER_CASE_CHAR.test(str);
  const hasNum   = HAS_A_NUMBER.test(str);
  const noSpaces = HAS_NO_SPACES.test(str);
  return (upCase && loCase && hasNum && noSpaces);
};

export const validateNewPassword = (text = '', oldPass = '') => {
  if (text === '') return 'Please enter your new password';
  else if (text.length < 8) return 'Your password must contain at least 8 characters';
  else if (!pwRegexValidation(text)) return 'A password must include upper and lower case characters, at least one number, and no spaces';
  else if (text === oldPass) return 'Please choose a different password than your current password';
  else return '';
};

export const validateMFACode = (text = '') => {
  const isNumber = !isNaN(text);
  const isValidLength = text.length === 6 || text === '';
  if (!isNumber || !isValidLength) return 'Please enter a valid 6 digit code';
  else return '';
};

export const validateUserPassword = (text = '') => genericRequired(text, 'Please enter your password');
export const validateMFA          = (text = '') => genericRequired(text, 'Please select a method');
export const validateCountry      = (text = '') => genericRequired(text, 'Please select your country');
export const validateFullName     = (text = '') => genericRequired(text, 'Please enter your Full Name');
export const validateCity         = (text = '') => genericRequired(text, 'Please enter you city');
export const validateZipCode      = (text = '') => genericRequired(text, 'Please enter a valid zip code');
export const validateState        = (text = '') => genericRequired(text, 'Please select your state');

export const validatePhone = (text = '') => {
  const NUMS_ONLY = text.replace(/\D/g, '');

  if (text === '') return 'Please enter your phone number';
  else if (!PHONE_REGEX.test(NUMS_ONLY)) return 'Phone Number is invalid';
  else return '';
};


export const validateUSPostal = text => {
  if (text === '') return 'Please enter a valid zip code';
  else if (!US_POSTAL_REGEX.test(text)) return 'Invalid Postal format';
  else return '';
};

export const validateCAPostal = text => {
  if (text === '') return 'Please enter a valid zip code';
  else if (!CA_POSTAL_REGEX.test(text)) return 'Invalid Postal format';
  else return '';
};


export const validateAddressLine1 = (text = '') => {
  if (text === '') return 'Please enter your Address';
  else if (text.length > 255) return 'Address Line is too long';
  return '';
};

export const validateAddressLine2 = (text = '') => text.length > 255 ? 'Address field is too long' : '';

export const validateSelection = (col = [], selection = '') => {
  if (!col || col?.length === 0) return 'Collection not given';
  if (selection === '') return 'Please select an option';
  else if (!col.includes(selection)) return 'Invalid Selection';
  return '';
};

export const validateAchName = (text = '') => {
  if (text === '') return 'Field is required';
  else if (text.length > 40) return 'Field is too long';
  else return '';
};

export const validateUsPostal = (text = '') => {
  if (text === '') return 'Zip code is required';
  else if (!validateNumbersOnly(text)) return 'Numbers only';
  else if (text.length > 9) return 'US postal codes are 5 or 9 digits long';
  // else if (!US_POSTAL_REGEX.test(text)) return
  else return '';
};

export const validateAchAccount = (text= '', type = 'blur') => {
  if (text === '') return 'Account number is required';
  else if (!validateNumbersOnly(text)) return 'Numbers only';
  else if (type === 'blur' && !ACCOUNT_NUMBER_REGEX.test(text)) return 'Invalid account number';
  else return '';
};

export const validateAchRouting = (text= '', type = 'blur') => {
  if (text === '') return 'Routing number is required';
  else if (!validateNumbersOnly(text)) return 'Numbers only';
  else if (type === 'blur' && !ROUTING_NUMBER_REGEX.test(text)) return 'Invalid routing number';
  else return '';
};
