import React     from 'react';
import PropTypes from 'prop-types';

import * as S            from './plan-box-qkn.module.scss';
import { Price }         from '../../../common/product/price';
import { ProductName }   from '../../../common/product/prod-name';
import { TagLine }       from '../../../common/product/tag-line';
import * as RouteHelpers from '../../../../helpers/route-helper';

const PlanBoxQkn = ({
  sku,
  name,
  action,
  country,
  uriName,
  daysOut,
  is30After,
  hasRecentUpgrade
}) => {
  const DAYS_LEFT_6_MO = daysOut === 'FAR';
  const TEST_TAG       = uriName.toUpperCase();

  let ctaObj = {isHidden: false, offerExtend: false};

  if (action === 'DOWNGRADE') {
    ctaObj = {
      ...ctaObj,
      isDisabled: DAYS_LEFT_6_MO,
      isHidden:   DAYS_LEFT_6_MO && is30After,
      text:       'Downgrade'
    };
  } else if (action === 'UPGRADE') {
    ctaObj = {
      ...ctaObj,
      isDisabled: hasRecentUpgrade,
      text:       'Upgrade'
    };
  }
  
  return (
    <article className={`${S.option} ${uriName}`} data-testid={`PLAN_${TEST_TAG}`}>
      <ProductName customName={name} product='quicken' className={S.name} />
      <TagLine uriName={uriName} className={S.tag}/>

      <Price
        sku={sku}
        className={S.price}
        renewalFrequency="P1Y"
      />

      {!ctaObj.isHidden && (
        <div className={S.btnRow}>
          {!ctaObj.isDisabled && ( //enabled Link
            <a
              href={RouteHelpers.getEStoreCartUrlAddProduct(sku, country)}
              className={S.activeBtn}
              data-testid={`LINK_ESTORE_${TEST_TAG}`}
            >{ctaObj.text}</a>
          )}

          {ctaObj.isDisabled && ( //Disabled button
            <button
              className={S.inactiveBtn}
              disabled={true}
              data-testid={`BTN_DISABLED_${TEST_TAG}`}
            >{ctaObj.text}</button>
          )}
        </div>
      )}
    </article>
  );
};

PlanBoxQkn.displayName = 'PlanBoxQkn';
PlanBoxQkn.propTypes   = {
  name:             PropTypes.string.isRequired,
  is30After:        PropTypes.bool.isRequired,
  hasRecentUpgrade: PropTypes.bool.isRequired,
  uriName:          PropTypes.string.isRequired,
  sku:              PropTypes.string.isRequired,
  daysOut:          PropTypes.oneOf(['FAR', 'MID', 'NEAR']).isRequired,
  action:           PropTypes.oneOf(['CURRENT', 'UPGRADE', 'DOWNGRADE']).isRequired,
  country:          PropTypes.oneOf(['us', 'ca']).isRequired
};

export { PlanBoxQkn };
