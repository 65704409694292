import React      from 'react';
import PropTypes  from 'prop-types';
import classNames from 'classnames';

import * as S from './text-field.module.scss';

function TextField({
  id,
  label,
  error,
  className,
  ...rest
}) {

  return (
    <div className={classNames(S.newTextField, className)}>
      <label
        data-testid={`${id}_LABEL`}
        htmlFor={id}
        className={error ? S.hasError : null}
      >{label}</label>

      <input
        data-testid={`${id}_INPUT`}
        className={error ? S.hasError : null}
        id={id}
        {...rest}
      />

      <p data-testid={`${id}_ERROR`} className={S.textFieldError}>{error}</p>
    </div>
  );
}

TextField.displayName = 'TextField';
TextField.propTypes   = {
  error:       PropTypes.string,
  value:       PropTypes.string,
  placeholder: PropTypes.string,
  label:       PropTypes.string,
  className:   PropTypes.string,
  tabIndex:    PropTypes.number,

  name:         PropTypes.string.isRequired,
  id:           PropTypes.string.isRequired,
  disabled:     PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
  onBlur:   PropTypes.func.isRequired,

  type: PropTypes.oneOf(['text', 'email', 'password', 'number'])
};

TextField.defaultProps = {
  value:     '',
  type:      'text',
  error:     null,
  className: null,
  onBlur:    null,
};

export { TextField };
