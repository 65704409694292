import React        from 'react';
import ClassNames   from 'classnames';
import PropTypes    from 'prop-types';

import * as S           from './remaining-time.module.scss';
import * as TimeHelpers from '../../../helpers/time-helpers';


const RemainingTime = ({expireOn, className}) => {

  const TIME_LEFT = React.useMemo(
    () => {
      if (TimeHelpers.dateIsInFuture(expireOn)) {
        const MONTHS = TimeHelpers.monthsLeft(expireOn);
        const DAYS = TimeHelpers.daysLeft(expireOn);

        return DAYS > 31
          ? `${MONTHS} Month${MONTHS === 1 ? '' : 's'}`
          : `${DAYS} Day${DAYS !== 1 ? 's' : ''}`;
      } else return '0 Days';
    },
    [expireOn]
  );

  return (
    <div className={ClassNames(S.timeRemaining, className)}>
      {TIME_LEFT} Remaining
    </div>
  );
};

RemainingTime.displayName = 'RemainingTime';
RemainingTime.propTypes = {
  expireOn: PropTypes.string.isRequired,
  className: PropTypes.string,
  isCancelled: PropTypes.bool,
};

export { RemainingTime };
