import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import * as S from './cancelled-box.module.scss';

const CancelledBox = ({className}) => {
  return (
    <p className={ClassNames(S.cancelledBox, className)}>RENEWAL CANCELLED</p>
  );
};

CancelledBox.displayName  = 'CancelledBox';
CancelledBox.propTypes = {
  className: PropTypes.string,
};

export { CancelledBox };
