const PROMO_LOCALSTORE_KEY = 'bmPromos'; 
const Q_STRINGS = ['one', 'two', 'three'];
export const PROMO_SKUS = [
  '170433', 
  '170434', 
  '170435',
];

const BM_PROMOS = {
  one : {
    sku: '170433',
    tierName: 'lite',
    promoWeight: 1
  },
  two: {
    sku: '170434',
    tierName: 'basic',
    promoWeight: 2
  },
  three: {
    sku: '170435',
    tierName: 'plus',
    promoWeight: 3
  }
};

export const setBmPromoValues = (promoString) => {
  //Exit if not an acceptable querystring
  if (!Q_STRINGS.includes(promoString)) return;

  if(localStorage.getItem(PROMO_LOCALSTORE_KEY) === null) {
    localStorage.setItem(PROMO_LOCALSTORE_KEY, JSON.stringify([BM_PROMOS[promoString]]));
  } else {
    const mergedPromos = JSON.parse(localStorage.getItem(PROMO_LOCALSTORE_KEY));
    
    // Merge in the promo if it doesn't already exist in the localstorage key
    if (! (mergedPromos.some(e => e.tierName === BM_PROMOS[promoString].tierName)) ) {
      mergedPromos.push(BM_PROMOS[promoString]);
      localStorage.setItem(PROMO_LOCALSTORE_KEY, JSON.stringify(mergedPromos));
    }
  }
};

// Currently we only show a single promo at a time so this returns all promo skus if they've got none
// in localStorage if there's multiple find the best promo and remove it from the list of skus to ignore
export const getSkusToHide = () => {
  // If they have nothing in localstore they aren't 'eldgible' from 
  if (localStorage.getItem(PROMO_LOCALSTORE_KEY) === null) return PROMO_SKUS;

  const promosAvailable = JSON.parse(localStorage.getItem(PROMO_LOCALSTORE_KEY));
  let bestPromotion = null;

  promosAvailable.forEach(promo => {
    if (!bestPromotion) {
      bestPromotion = promo;
    } else if (bestPromotion) {
      if (bestPromotion.promoWeight < promo.promoWeight) {
        bestPromotion = promo;
      }
    }
  });

  return PROMO_SKUS.filter(sku => sku != bestPromotion.sku);
};


export const priceSort =  (a, b) => {
  const aPrice = parseFloat(a.Price.substring(1));
  const bPrice = parseFloat(b.Price.substring(1));

  if (aPrice < bPrice) {
    return -1;
  }
  if (aPrice > bPrice) {
    return 1;
  }
  return 0;
};


// We've switched from an existing API and need to massage some data to get things to function as they were
export const filterBMApiResponse = (apiData) => {
  //The api response is huge but we want to filter out just bm tier products for this purpose and remove all undefined's from the .filter call
  const bmData = Object.keys(apiData).map(sku => {
    if (apiData[sku]?.ProductLine === 'billmanager' && apiData[sku]['Tier'] !== undefined) return apiData[sku];
  }).filter(sku => sku !== undefined);

  // Get the list of promotional 'hidden' sku's and remove one's user is not opted in for
  const hideBmSkus = getSkusToHide();
  const removedSkus = bmData.filter(skuObj => !hideBmSkus.includes(skuObj?.SKU));

  // Sort the api response by price for display on the bill manager modal
  const orderedSkus = removedSkus.sort( priceSort );

  // Check duplicate Tier's and pick promotional SKU's if there
  const promoFiltered = {};
  orderedSkus.forEach((product, index) => {
    if (Object.keys(promoFiltered).includes(product.Tier)) {
      promoFiltered[product.Tier].push(index);
    } else {
      promoFiltered[product.Tier] = [index];
    }
  });

  Object.keys(promoFiltered).forEach( tierArr => {
    // Duplicate SKU's for tier, find the one that is a trial and remove the others
    if (promoFiltered[tierArr].length > 1) {
      for (let i = 0; i < promoFiltered[tierArr].length; i++) {
        const origArrIndex = promoFiltered[tierArr][i];
        const sku = orderedSkus[origArrIndex];
        if (sku.IsTrial != 1) { orderedSkus.splice(origArrIndex, 1); }        
      }
    }
  });

  // Check to make sure that the BM sku's returned from the API are SKU's that we handle for in MyAcct where some sku's are hardcode
  return orderedSkus;
};