import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

import * as S             from './quota.module.scss';
import { useGlobalCtx }   from '../../../context/ctx-hook';
import { useGetSkuPrice } from '../../../helpers/hooks/use-get-price';
import { splitQuota }     from '../../../helpers/pure-functions';

const Quota = ({sku, className}) => {
  const {state} = useGlobalCtx();
  const QUOTA = state.skus[sku].Quota;
  const {billpay = '', checkpay = ''} = React.useMemo(() => splitQuota(QUOTA), []);

  useGetSkuPrice(sku);

  return (
    <div className={ClassNames(S.quotaArea, className)}>
      <p>Check Pay - {checkpay} per month</p>
      <p>Quick Pay - {billpay} per month</p>
    </div>
  );
};

Quota.displayName = 'Quota';
Quota.propTypes = {
  sku: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { Quota };
