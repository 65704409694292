import classNames    from 'classnames';
import React         from 'react';

import * as S           from './skeleton-page.module.scss';
import { MainHeader }   from '../pages/main-header';
import { useGlobalCtx } from '../../context/ctx-hook';


const SkeletonPage = () => {
  const {state: {ui}} = useGlobalCtx();
  const navHidden = (ui.hideNav == 1 || ui.embed == 1);


  return (
    <main className={S.skelPageLayout}>
      {!navHidden && (
        <MainHeader setView={null} view={null} />
      )}

      <div className={S.skelGreetWrapper}>
        <div className={S.skelGreetLeft} />
        <div className={S.skelGreetRight} />
      </div>

      <div className={S.skelMainArea}>
        <aside className={classNames(S.leftSide, S.skelNavWrapper)}>
          <div className={S.skelHeadingText} />
          <div className={S.skelNavList}>
            <div className={S.skelNavItem} />
            <div className={S.skelNavItem} />
            <div className={S.skelNavItem} />
          </div>
        </aside>
        <div className={S.rightSide}>
          <div className={S.skelHeadingText} />
          <div className={S.skelBoxArea} >
            <div className={S.skelBox} />
            <div className={S.skelBox} />
          </div>
        </div>
      </div>
    </main>
  );
};

SkeletonPage.displayName = 'SkeletonPage';

export { SkeletonPage };
