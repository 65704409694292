import React      from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';

import * as S from '../pages/subscriptions/tile-bm/bm-paid.module.scss';

const Legal = ({className}) => {
  return (
    <p className={ClassNames(S.legal, className)}>* This will set your renewal to happen automatically.</p>
  );
};

Legal.displayName = 'Legal';
Legal.propTypes = {
  className: PropTypes.string,
};

export { Legal };
