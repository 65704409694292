import QueryString from 'query-string';

const addCanada = (country = '') => country.toLowerCase() === 'ca' ? '/canada' : '';

const eStoreUrl = (link = '', params) => {
  let {domain, embed, ...QS} = QueryString.parse(window.location.search);
  domain = domain ? domain : process.env.REACT_APP_STORE_URL;
  domain = domain.replace('/canada', '');

  const PARAMS = {
    embed: embed || QS['hide-nav'] ? 1 : null, //This appears to be stored when going to page
    bearer: process.env.REACT_APP_ENV !== 'production' ? localStorage.getItem('bearer') : null,
    ...params,
  };

  return `${domain}${link}?${QueryString.stringify(PARAMS, {
    skipNull: true,
    skipEmptyString: true,
  })}`;
};

export const getEStoreRenewal = (country='') =>
  eStoreUrl(
    `/shoppingcart${addCanada(country)}`,
    {renew: 1}
  );

export const getEStoreCartUrlAddProduct = (sku = '', country = '') =>
  eStoreUrl(
    `/shoppingcart${addCanada(country)}`,
    {addproduct: sku}
  );
