import React       from 'react';
import PropTypes   from 'prop-types';
import ClassNames  from 'classnames';

import { GetSiteData }    from './get-site-data';
import * as S             from './logged-in-template.module.scss';
import { Message }        from '../common/message';
import { Modal }          from '../modals/modal';
import { AuthRoutes }     from '../routes/auth-routes';
import { useGlobalCtx }   from '../../context/ctx-hook';
import { PAGE_STATUS }    from '../../helpers/constants/state-constants';

const LoggedInTemplate = ({status}) => {
  const {state} = useGlobalCtx();

  if (status === PAGE_STATUS.LOADING) {
    return <GetSiteData />;
  } else if (status === PAGE_STATUS.HAS_ERROR) {
    return (
      <div className={S.errorWrapper}>
        <Message type="ERROR" messages={['Server error please try again later']} align="CENTER"/>
      </div>
    );
  }


  return (
    <>
      <div className={
        ClassNames(S.site, {
          [S.navIsOpen]: state.ui.navListOpen,
          [S.hasModal]: state.modal.type,
        })}>
        <AuthRoutes/>
      </div>
      {state.modal.type && <Modal {...state.modal} />}
    </>
  );
};

LoggedInTemplate.displayName = 'LoggedInTemplate';
LoggedInTemplate.propTypes  = {
  status: PropTypes.oneOf(Object.values(PAGE_STATUS))
};
export { LoggedInTemplate };
