import PropTypes from 'prop-types';
import React     from 'react';

import * as S                         from './change-plan-acme.module.scss';
import { ModalWrapper }               from '../modal-wrapper';
import { Message }                    from '../../common/message';
import { ProductName }                from '../../common/product/prod-name';
import { TagLine }                    from '../../common/product/tag-line';
import { Price }                      from '../../common/product/price';
import * as Actions                   from '../../../context/ctx-actions';
import { useGlobalCtx }               from '../../../context/ctx-hook';
import { mergeSubscription }          from '../../../context/selectors/product-selectors';
import { getProductsPaymentMethod }           from '../../../context/selectors/user-selectors';
import { MODALS }                     from '../../../helpers/constants/state-constants';
import { callUpdateRenewalFrequency } from '../../../network/network-calls';

const ChangePlanAcme = ({onClose, ctx: {product}}) => {
  const {globalDispatch, state} = useGlobalCtx();

  const {isDisabled, subscriptions} = state;

  // const HAS_PAYMENT = getUserHasCredit(state);
  const HAS_PAYMENT = getProductsPaymentMethod(product, state);
  const MONTHLY_SKU = subscriptions[product].renewalSku;
  const ANNUAL_SKU = '170522';
  
  const [hasError, setHasError] = React.useState(false);

  const handleYearlySwitch = async () => {
    if (HAS_PAYMENT) {
      try {
        globalDispatch(Actions.setCtxField('isDisabled', true));
        const {data} = await callUpdateRenewalFrequency(product, 'P1Y');

        globalDispatch(Actions.mergeMainStateObj({
          isDisabled:    false,
          subscriptions: mergeSubscription(state, product, data.postState),
          alert:         {
            type:     'SUCCESS',
            messages: ['Quicken Simplifi plan has successfully been updated']
          }
        }));

        onClose();
      } catch {
        setHasError(true);
        globalDispatch(Actions.setCtxField('isDisabled', false));
      }
    } else {
      globalDispatch(Actions.setCtxNestedObj('modal', {
        type:       MODALS.UPDATE_PAYMENT,
        isLoading:  true,
        hasError:   false,
        isDisabled: false,
        ctx:        {
          product,
          paymentOnly: false,
          acmeAction:  {action: 'CHANGE_PLAN'}
        }
      }));
    }
  };

  return (
    <ModalWrapper heading="Change Plan" onClose={onClose} className={S.wrapper}>
      <div className={S.modalBody}>
        {hasError && <Message type="GENERIC_ERROR"/>}

        <article className={S.product}>
          <ProductName name="Simplifi" className={S.productName}/>
          <TagLine uriName="acme-basic" className={S.tagLine}/>

          <Price
            sku={ANNUAL_SKU}
            className={S.priceWrapper}
            renewalFrequency="P1Y"
          />

          <button
            className={S.contentCTABtn}
            disabled={isDisabled}
            onClick={handleYearlySwitch}
          >SWITCH TO ANNUAL
          </button>
        </article>

        <div className={S.cpDash}/>

        <article className={S.product}>
          <ProductName name="Simplifi" className={S.productName}/>
          <TagLine uriName="acme-basic" className={S.tagLine}/>

          <Price
            sku={MONTHLY_SKU}
            className={S.priceWrapper}
            renewalFrequency="P1M"
          />

          <button
            className={S.contentCTABtn}
            disabled={true}
            onClick={handleYearlySwitch}
          >CURRENT PLAN</button>
        </article>
      </div>
    </ModalWrapper>
  );
};


ChangePlanAcme.displayName = 'ChangePlanAcme';
ChangePlanAcme.propTypes   = {
  onClose: PropTypes.func.isRequired,
  ctx:     PropTypes.shape({
    product: PropTypes.string.isRequired
  })
};

export { ChangePlanAcme };
