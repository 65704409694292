import React from 'react';

export const PlayBtn = () => (
  // <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
  //   <defs/>
  //   <path d="M1.5 9a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zm6.6-2.92a.37.37 0 00-.6.3v5.25c0 .3.35.48.6.3l3.5-2.63c.2-.15.2-.45 0-.6L8.1 6.08z"/>
  // </svg>

  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs/>
    <g opacity="0.8">
      <path d="M50 0C77.6142 0 100 22.3858 100 50C100 77.6142 77.6142 100 50 100C22.3858 100 0 77.6142 0 50C0 22.3858 22.3858 0 50 0Z" fill="#979797" />
    </g>
    <path d="M71.107 47.4599C73.5134 48.8556 73.5134 51.1417 71.107 52.5401L37.5936 71.8824C35.1738 73.2808 33.1952 72.1497 33.1952 69.3423V30.6551C33.1952 27.861 35.1738 26.7166 37.5936 28.115L71.107 47.4599Z" fill="white" />
  </svg>
  
);

PlayBtn.displayName = 'PlayBtn';
