import React from 'react';
import { compareMoments } from './time-helpers';

import { BmAdvert } from '../components/pages/subscriptions/tile-bm/bm-advert';
import { BmFree } from '../components/pages/subscriptions/tile-bm/bm-free';
import { BmPaid } from '../components/pages/subscriptions/tile-bm/bm-paid';

// import { QobAdvert } from '../components/pages/subscriptions/tile-qob/qob-advert';
import { QobPaid } from '../components/pages/subscriptions/tile-qob/qob-paid';

// ** SUBTILE RENDERING

// Constants for subtiles (rendering logic)

// BillManager:
export const BM_TILE = (BM_TYPE, isModal, active) =>{ 
  if(BM_TYPE === 'NONE' && active && !isModal) {return <BmAdvert/>;}
  if(BM_TYPE === 'FREE') {return <BmFree isModal = {isModal} qknIsActive={active}/>;}
  if(BM_TYPE === 'PAID') {return <BmPaid isModal={isModal} qknIsActive={active}/>;}
  return false;
};

// Online Backup
export const QOB_TILE = (HAS_QOB, isModal, active, autoRenew) =>{
  // if(!HAS_QOB) {return <QobAdvert/>;}
  if (HAS_QOB) { return <QobPaid isModal={isModal} qknIsActive={active} qknHasArOn={autoRenew}/>;}
  return false;
};

// If a subtile is too large for a normal sized line drawn from lower elements to reach, add it here with key= the element name
const LARGE_SUBTILES = [<BmPaid key={'BmPaid'}/>, <QobPaid key={'QobPaid'}/>];

const ExtraRopeRequired = (component) =>{
  return (LARGE_SUBTILES.filter((tile) => tile.type === component.type).length > 0);
};

export const RenderedSubtiles = (tileArray) => {
  // Subtile order: have most recent activation closest to primary tile
  // Filtered to remove any tiles with missing dependencies
  const filteredTiles = tileArray.filter( (tile) => tile.display_dependency_flagged == false && tile.component );
  const sortedTiles = filteredTiles.sort( (a,b) => compareMoments(a.purchase_date || 0, b.purchase_date || 0) );
  if(sortedTiles.length <= 0) {
    return <></>;
  }
  // If the first element is in LARGE_SUBTILES, it should force the second subtile to use extra length
  const lengthAdditionalOffset = ExtraRopeRequired(sortedTiles[0].component) * 1;
  // Add the offset only if the element is not the first !(0th index):  + ( lengthAdditionalOffset * index )
  return sortedTiles.map((tile, index) => React.cloneElement(tile.component, {listPosition: index + (lengthAdditionalOffset * index), key: tile.product}));
};

// ** END SUBTILE RENDERING