import React      from 'react';
import PropTypes  from 'prop-types';
import ClassNames from 'classnames';

import * as S from './message.module.scss';

const Message = ({type = '', align, messages = [], title = '', className}) => (
  <div className={ClassNames(S.contentMessage, className, {
    [S.success]: type === 'SUCCESS',
    [S.info]:    type === 'INFO',
    [S.error]:   type?.includes('ERROR'),
    [S.left]:    align === 'LEFT',
    [S.right]:   align === 'RIGHT',
    [S.center]:  align === 'CENTER',
  })}>
    {title && (
      <div className={S.title}>{title}</div>
    )}
    {type === 'GENERIC_ERROR' && (
      <p>
        Please try again, and if it still does not work,
        <a
          href="https://www.quicken.com/support"
          rel="noopener noreferrer"
          target="_blank"
        >let us know.</a>
      </p>
    )}
    {messages.map((msg, i) => (
      <p key={i}>{msg}</p>
    ))}
  </div>
);

Message.displayName = 'Message';
Message.propTypes   = {
  className: PropTypes.string,
  align: PropTypes.oneOf(['LEFT', 'RIGHT', 'CENTER']),
  type: PropTypes.oneOf(['SUCCESS', 'INFO', 'ERROR', 'GENERIC_ERROR']),
  title: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
};

export { Message };
