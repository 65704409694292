// Network calls for my-account quicken-id component
import Axios from 'axios';

import { getBearer } from './axios-settings';

const BASE_URL = process.env.REACT_APP_SERVICES_URL;

export const callUpdateUserNames = (userId, firstName = '', lastName = '') =>
  Axios.put(
    `${BASE_URL}/userprofiles/${userId}`,
    {firstName, lastName},
    {headers: {Authorization: getBearer()}}
  );

export const callUpdateQuickenId = (username = '', password = '') =>
  Axios.put(
    `${BASE_URL}/users/username`,
    {username, password},
    {headers: {Authorization: getBearer()}}
  );

export const callUpdateQuickenIdV2 = (username = '', password = '', mfaChannel = '', mfaCode = '') =>
  Axios.put(
    `${BASE_URL}/users/v2/username`,
    {username, password, mfaChannel, mfaCode},
    {headers: {Authorization: getBearer()}}
  );

export const callUpdateQuickenIdV3 = (username = '', password = '', mfaChannel = 'Email', mfaCode = '') =>
  Axios.put(
    `${BASE_URL}/users/v3/username`,
    {username, password, mfaChannel, mfaCode},
    {headers: {Authorization: getBearer()}}
  );

// export const callUpdatePassword = (oldPassword = '', password = '') =>
//   Axios.put(
//     `${BASE_URL}/users/password`,
//     {oldPassword, password},
//     {headers: {Authorization: getBearer()}}
//   );

// export const callUpdatePasswordV2 = (oldPassword = '', password = '', mfaChannel = '', mfaCode = '') =>
//   Axios.put(
//     `${BASE_URL}/users/v2/password`,
//     { oldPassword, password, mfaChannel, mfaCode },
//     {headers: {Authorization: getBearer()}}
//   );

export const callUpdatePasswordV3 = (oldPassword = '', password = '', mfaChannel = '', mfaCode = '') =>
  Axios.put(
    `${BASE_URL}/users/v3/password`,
    { oldPassword, password, mfaChannel, mfaCode },
    {headers: {Authorization: getBearer()}}
  );

// export const callUpdatePhone = (phone = '', password = '') =>
//   Axios.put(
//     `${BASE_URL}/users/phone`,
//     {phone, password},
//     {headers: {Authorization: getBearer()}}
//   );

// export const callUpdatePhoneV2 = (phone = '', password = '', mfaChannel = '', mfaCode = '') =>
//   Axios.put(
//     `${BASE_URL}/users/v2/phone`,
//     { phone, password, mfaChannel, mfaCode },
//     {headers: {Authorization: getBearer()}}
//   );

export const callUpdatePhoneV3 = (phone = '', password = '', mfaChannel = 'Phone', mfaCode = '') =>
  Axios.put(
    `${BASE_URL}/users/v3/phone`,
    { phone, password, mfaChannel, mfaCode },
    { headers: { Authorization: getBearer() } }
  );

export const callUpdateMfa = (mfaChannelDefault = '', password = '') =>
  Axios.put(
    `${BASE_URL}/users/channel`,
    {mfaChannelDefault, password},
    {headers: {Authorization: getBearer()}}
  );

export const callSignOutOfDevices = () =>
  Axios.delete(`${BASE_URL}/oauth/token/me`, {
    data:    {},
    headers: {Authorization: getBearer()}
  });

export const callUpdateAddress = (userId = '', {
  fullName = '',
  line1 = '',
  line2 = '',
  city = '',
  zipCode = '',
  state = '',
  country = '',
} = {}) =>
  Axios.post(
    `${BASE_URL}/userprofiles/${userId}/addresses`,
    {
      fullName,
      line1,
      line2:   line2 || null,
      city, country,
      state,
      zipCode,
      primary: true
    },
    {headers: {Authorization: getBearer()}}
  );
